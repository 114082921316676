import React from 'react';
import Switch from "react-switch";
import {config} from "../util/constants";
import axios from "axios";
import {Link} from "react-router-dom";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Button from "../Button";
import Select2 from "react-select2-wrapper";
import {saveDict} from "../util/dicts";

class NewPlaceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            name: '',
            description: '',
            telephone: '',
            commissionPlan: 0, //Dictionary from /dictionary/commissionPlanDict
            commissionPlanDict: [],
            city: 1, //Dictionary from /dictionary/cityDict
            cityDict: [],
            area: '',
            email: '',
            terminal: null, //Dictionary from /dictionary/terminalDict
            terminalDict: [],
            incassator: null, //Dictionary from /dictionary/incassatorDict
            incassatorDict: [],
            permission: 0, //Dictionary from /dictionary/placePermissionDict
            permissionDict: [],
            validTill: '',
            fakesPossible: false,
            latitude: 25.25,
            longitude: 51.51,

            idLink: null,
            disabled: false,
            redirect: false,

            bearer: localStorage.getItem("authToken"),
            response: [],
            error: null,
            isLoaded: false,

        };
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangeTelephone = this.handleChangeTelephone.bind(this);
        this.handleChangeCommissionPlan = this.handleChangeCommissionPlan.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleChangeTerminal = this.handleChangeTerminal.bind(this);
        this.handleChangeIncassator = this.handleChangeIncassator.bind(this);
        // this.handleChangePermission = this.handleChangePermission.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeArea = this.handleChangeArea.bind(this);
        this.handleChangeValidTill = this.handleChangeValidTill.bind(this);
        this.handleChangeFakesPossible = this.handleChangeFakesPossible.bind(this);
        this.handleChangeLatitude = this.handleChangeLatitude.bind(this);
        this.handleChangeLongitude = this.handleChangeLongitude.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = 'New agent - mitra processing';

        axios.get(config.url.API_URL + '/dictionary/placeCommissionPlanDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    commissionPlanDict: result
                });
            },
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

        axios.get(config.url.API_URL + '/dictionary/cityDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    cityDict: result
                });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });

            }
        });

        axios.get(config.url.API_URL + '/dictionary/freeTerminalDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    terminalDict: result
                });
                this.state.terminalDict.push({'id': 10, 'text': "N/A"})

            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });

            }
        });

        axios.get(config.url.API_URL + '/dictionary/incassatorDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    incassatorDict: result
                });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });

            }
        });

        // axios.get(config.url.API_URL + '/dictionary/placePermissionDict', {
        //     headers: {
        //         'Authorization': 'Bearer ' + this.state.bearer
        //     }
        // }).then(res => {
        //         const result = res.data;
        //         this.setState({
        //             permissionDict: result
        //         });
        //     }
        // ).catch(e => {
        //     if (e.request.status === 401) {
        //         localStorage.setItem("auth", this.props.setAuth);
        //         this.props.setAuth(false);
        //     } else {
        //
        //         let response = e.response;
        //
        //         let statusText = response.data.status;
        //         let errorMessage = response.data.message;
        //         console.log(errorMessage);
        //         let errorStatus = response.status;
        //         this.setState({
        //             modalShow: true,
        //             errorStatus,
        //             statusText,
        //             errorMessage,
        //         });
        //
        //     }
        // });
    }

    sendPlaceRequest() {

        const data = {
            id: parseInt(this.state.id),
            name: this.state.name,
            description: this.state.description,
            area: this.state.area,
            email: this.state.email,
            telephone: this.state.telephone,
            commissionPlan: parseInt(this.state.commissionPlan),
            city: parseInt(this.state.city),
            terminal: parseInt(this.state.terminal) === 10 ? null : parseInt(this.state.terminal),
            incassator: parseInt(this.state.incassator),
            permission: parseInt(this.state.permission),
            validTill: this.state.validTill,
            fakesPossible: this.state.fakesPossible,
            latitude: parseFloat(this.state.latitude),
            longitude: parseFloat(this.state.longitude),
        };
        console.log('date', data);
        axios.put(config.url.API_URL + "/place", data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        })
            .then(res => {
                    const result = res.data;
                saveDict();
                this.setState({
                        idLink: result.id,
                        id: result.id,
                        statusText: "OK",
                        errorMessage: 'Agent id: ' + result.id + ' save',
                        modalShow: true,
                        redirect: true,
                    });
                }
            ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    disabled: false,
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    }

    handleChangeName = (e) => {
        const value = e.target.value;
        this.setState({
            name: value,
        })
    };

    handleChangeDescription = (e) => {
        const value = e.target.value;
        this.setState({
            description: value,
        })
    };

    handleChangeTelephone = (e) => {
        const value = e.target.value;
        this.setState({
            telephone: value,
        })
    };

    handleChangeEmail = (e) => {
        const value = e.target.value;
        this.setState({
            email: value,
        })
    };

    handleChangeCommissionPlan = (e) => {
        const value = e.target.value;
        this.setState({
            commissionPlan: value,
        })
    };

    handleChangeCity = (e) => {
        const value = e.target.value;
        this.setState({
            city: value,
        })
    };

    handleChangeArea = (e) => {
        const value = e.target.value;
        this.setState({
            area: value,
        })
    };

    handleChangeTerminal = (e) => {
        const value = e.target.value;
        this.setState({
            terminal: value,
        })
    };

    handleChangeIncassator = (e) => {
        const value = e.target.value;
        this.setState({
            incassator: value,
        })
    };

    // handleChangePermission = (e) => {
    //     const value = e.target.value;
    //     this.setState({
    //         permission: value,
    //     })
    // };

    handleChangeValidTill = (e) => {
        const value = e.target.value;
        this.setState({
            validTill: value,
        })
    };

    handleChangeFakesPossible(checked) {
        this.setState({
            fakesPossible: checked
        });

    };

    handleChangeLatitude = (e) => {

        const value = e.target.value;
        this.setState({
            latitude: value,
        })

    };

    handleChangeLongitude = (e) => {

        const value = e.target.value;
        this.setState({
            longitude: value,
        })

    };

    handleClear = () => {
        this.setState({
            name: '',
            description: '',
            area: '',
            email: '',
            telephone: '',
            commissionPlan: 0, //Dictionary from /dictionary/commissionPlanDict
            city: 1, //Dictionary from /dictionary/cityDict
            terminal: null, //Dictionary from /dictionary/terminalDict
            incassator: null, //Dictionary from /dictionary/incassatorDict
            permission: 0, //Dictionary from /dictionary/placePermissionDict
            validTill: '',
            fakesPossible: false,
            latitude: 25.25,
            longitude: 51.51,

        })

    };

    handleSubmit(event) {
        event.preventDefault();
        this.setState({disabled: true})
        this.sendPlaceRequest();
    }


    render() {
        let modalClose = () => this.setState({modalShow: false});
        return (
            <div className="kt-portlet kt-portlet--mobile">
                <MyVerticallyCenteredModal
                    redirect={this.state.redirect}
                    id={this.state.id}
                    path={"/place/"}
                    heder={this.state.errorStatus}
                    h4={this.state.statusText}
                    text={this.state.errorMessage}
                    show={this.state.modalShow}
                    onHide={modalClose}
                />
                <form className="mitra-form">
                    {this.state.idLink > 0 ?
                        <div className="dima_heder">New Agent: <Link style={{color: "#646c9a"}}
                                                                     to={"/place/" + this.state.idLink}> {this.state.idLink}</Link>
                        </div> :
                        <div className="dima_heder">New Agent</div>
                    }
                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Name</label>
                        </div>

                        <div className="input_form_single">
                            <input type="text"
                                   value={this.state.name}
                                   className="form-control dima_form_control"
                                   placeholder={''}
                                   id="Name"
                                   onChange={this.handleChangeName}
                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Description</label>
                        </div>

                        <div className="input_form_single">
                            <input type="text"
                                   value={this.state.description}
                                   className="form-control dima_form_control"
                                   placeholder={''}
                                   id="Phone"
                                   onChange={this.handleChangeDescription}
                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">City</label>
                        </div>
                        <div className="input_form_single">
                            <Select2
                                id={'city'}
                                value={this.state.city}
                                data={this.state.cityDict}
                                className="mitra-select"
                                onChange={this.handleChangeCity}

                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Area</label>
                        </div>

                        <div className="input_form_single">
                            <input type="text"
                                   value={this.state.area}
                                   className="form-control dima_form_control"
                                   placeholder={''}
                                   id="Area"
                                   onChange={this.handleChangeArea}
                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Telephone</label>
                        </div>

                        <div className="input_form_single">
                            <input type="text"
                                   value={this.state.telephone}
                                   className="form-control dima_form_control"
                                   placeholder={''}
                                   id="Phone"
                                   onChange={this.handleChangeTelephone}
                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Email</label>
                        </div>

                        <div className="input_form_single">
                            <input type="email"
                                   value={this.state.email}
                                   className="form-control dima_form_control"
                                   placeholder={''}
                                   id="Email"
                                   onChange={this.handleChangeEmail}
                            />
                        </div>
                    </div>


                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Terminal</label>
                        </div>
                        <div className="input_form_single">
                            <Select2
                                id={'terminal'}
                                value={this.state.terminal}
                                data={this.state.terminalDict}
                                className="mitra-select"
                                onChange={this.handleChangeTerminal}

                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Team</label>
                        </div>
                        <div className="input_form_single">
                            <Select2
                                id={'incassator'}
                                value={this.state.incassator}
                                data={this.state.incassatorDict}
                                className="mitra-select"
                                onChange={this.handleChangeIncassator}

                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Commission</label>
                        </div>
                        <div className="input_form_single">
                            <Select2
                                id={'commissionPlan'}
                                value={this.state.commissionPlan}
                                data={this.state.commissionPlanDict}
                                className="mitra-select"
                                onChange={this.handleChangeCommissionPlan}

                            />
                        </div>
                    </div>

                    {/*<div className="dima_form_single">*/}
                    {/*    <div className="label_inline">*/}
                    {/*        <label className="label_form">Permission</label>*/}
                    {/*    </div>*/}
                    {/*    <div className="input_form_single">*/}
                    {/*        <Select*/}
                    {/*            multiple={'false'}*/}
                    {/*            id={'permission'}*/}
                    {/*            placeholder={''}*/}
                    {/*            dict={this.state.permissionDict}*/}
                    {/*            value={this.state.permission}*/}
                    {/*            onChange={this.handleChangePermission}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="dima_form_single">*/}
                    {/*    <div className="label_inline">*/}
                    {/*        <label className="label_form">Valid Till</label>*/}
                    {/*    </div>*/}

                    {/*    <div className="input_form_single">*/}
                    {/*        <input type="text"*/}
                    {/*               value={this.state.validTill}*/}
                    {/*               className="form-control dima_form_control"*/}
                    {/*               placeholder={''}*/}
                    {/*               id="validTill"*/}
                    {/*               onChange={this.handleChangeValidTill}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    {/*<div className="dima_form_single">*/}
                    {/*    <div className="label_inline">*/}
                    {/*        <label className="label_form">Latitude</label>*/}
                    {/*    </div>*/}

                    {/*    <div className="input_form_single">*/}
                    {/*        <input type="number"*/}
                    {/*               value={this.state.latitude}*/}
                    {/*               className="form-control dima_form_control"*/}
                    {/*               placeholder={''}*/}
                    {/*               id="latitude"*/}
                    {/*               onChange={this.handleChangeLatitude}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="dima_form_single">*/}
                    {/*    <div className="label_inline">*/}
                    {/*        <label className="label_form">Longitude</label>*/}
                    {/*    </div>*/}

                    {/*    <div className="input_form_single">*/}
                    {/*        <input type="number"*/}
                    {/*               value={this.state.longitude}*/}
                    {/*               className="form-control dima_form_control"*/}
                    {/*               placeholder={''}*/}
                    {/*               id="longitude"*/}
                    {/*               onChange={this.handleChangeLongitude}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="dima_form_single_switch" style={{marginTop: "3em"}}>
                        <div className="switch_block_dima">

                            <Switch
                                checked={this.state.fakesPossible}
                                onChange={this.handleChangeFakesPossible}
                                onColor="#acb3ee"
                                onHandleColor="#5867dd"
                                handleDiameter={30}
                                uncheckedIcon={true}
                                checkedIcon={true}
                                boxShadow="true"
                                activeBoxShadow="true"
                                offHandleColor="#e1e2e4"
                                height={20}
                                width={48}
                                className="kt-switch"
                                id="kt-switch"
                            />
                            <div className="label_inline_switch">
                                <label className="label_form">Fakes Possible</label>
                            </div>
                        </div>
                    </div>
                    <div className="btn_form-dima">
                        <Button
                            disabled={this.state.disabled}
                            id="submit"
                            type="submit"
                            className="mitra-button-submit"
                            onClick={this.handleSubmit}
                            name="Submit"
                        />
                        <Button
                            id="submit"
                            type="clear"
                            onClick={this.handleClear}
                            name="Clear"
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default NewPlaceForm;
