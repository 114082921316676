import React from 'react'
import '../paymentForm/Form.css'
import LoadingPage from "../LoadingPage";
import Switch from "react-switch";
import {config} from "../util/constants";
import axios from "axios";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../Button";
import Input from "../Input";
import Select2 from "react-select2-wrapper";


class PlaceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            modalShow: false,
            edit: false,
            disabled: true,
            bearer: localStorage.getItem("authToken"),

            id: null,
            name: '',
            description: '',
            telephone: '',
            email: '',
            commissionPlan: 0, //Dictionary from /dictionary/commissionPlanDict
            commissionPlanDict: [],
            city: 1, //Dictionary from /dictionary/cityDict
            cityDict: [],
            area:'',
            terminal: null, //Dictionary from /dictionary/terminalDict
            terminalDict: [],
            freeTerminalDict: [],
            incassator: 5, //Dictionary from /dictionary/incassatorDict
            incassatorDict: [],
            permission: null, //Dictionary from /dictionary/placePermissionDict
            permissionDict: [],
            validTill: '',
            hiddenPercent: 0,
            fakesPossible: false,
            hide: false,
            latitude: 25.25,
            longitude: 51.51,
            averageTurnover: {},

            send: true,
            response: '',
            errorStatus: '',
            statusText: '',
            errorMessage: '',

            isLoaded: 0,

        };
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangeTelephone = this.handleChangeTelephone.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeArea = this.handleChangeArea.bind(this);
        this.handleChangeCommissionPlan = this.handleChangeCommissionPlan.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleChangeTerminal = this.handleChangeTerminal.bind(this);
        this.handleChangeIncassator = this.handleChangeIncassator.bind(this);
        // this.handleChangePermission = this.handleChangePermission.bind(this);
        // this.handleChangeValidTill = this.handleChangeValidTill.bind(this);
        this.handleChangeFakesPossible = this.handleChangeFakesPossible.bind(this);
        this.handleChangeLatitude = this.handleChangeLatitude.bind(this);
        this.handleChangeLongitude = this.handleChangeLongitude.bind(this);
        // this.handleChangeHiddenPercent = this.handleChangeHiddenPercent.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeHide = this.handleChangeHide.bind(this);
        this.handleFakesPossible = this.handleFakesPossible.bind(this);
    };

    componentDidMount() {

        let request = config.url.API_URL + window.location.pathname;

        if (this.state.send === true) {
            axios.get(request, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.bearer
                }
            }).then(res => {
                const result = res.data;
                this.setState({
                    id: result.id ? result.id : 'N/A',
                    name: result.name ? result.name : 'N/A',
                    description: result.description ? result.description : 'N/A',
                    telephone: result.telephone ? result.telephone : 'N/A',
                    email: result.email ? result.email : 'N/A',
                    area: result.area ? result.area : 'N/A',
                    commissionPlan: result.commissionPlan,
                    city: result.city,
                    terminal: result.terminal,
                    incassator: result.incassator,
                    permission: result.permission,
                    validTill: result.validTill,
                    hiddenPercent: result.hiddenPercent,
                    fakesPossible: result.fakesPossible,
                    hide: result.hide,
                    latitude: result.latitude,
                    longitude: result.longitude,

                    isLoaded: this.state.isLoaded + 1,
                });
                document.title ='Agent: ' + window.location.pathname + ' - mitra processing';
                this.freeTerminal();
            })
                .catch(e => {
                    if (e.request.status === 401) {
                        localStorage.setItem("auth", this.props.setAuth);
                        this.props.setAuth(false);
                    } else {

                        let response = e.response;

                        let statusText = response.data.status;
                        let errorMessage = response.data.message;
                        console.log(errorMessage);
                        let errorStatus = response.status;
                        this.setState({
                            modalShow: true,
                            errorStatus,
                            statusText,
                            errorMessage,
                        });
                    }
                });
        }

        axios.get(request + '/averageTurnover', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            console.log(result)
            this.setState({
                averageTurnover: result,
                isLoaded: this.state.isLoaded + 1,
            });
            console.log(result)
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

        axios.get(config.url.API_URL + '/dictionary/placeCommissionPlanDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                commissionPlanDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

        axios.get(config.url.API_URL + '/dictionary/cityDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                cityDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {
                    let response = e.response;
                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

        axios.get(config.url.API_URL + '/dictionary/terminalDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                terminalDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });


        axios.get(config.url.API_URL + '/dictionary/incassatorDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                incassatorDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

        axios.get(config.url.API_URL + '/dictionary/placePermissionDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                permissionDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });
    };

    freeTerminal (){
        axios.get(config.url.API_URL + '/dictionary/freeTerminalDict?id=' +this.state.id, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                freeTerminalDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
            this.state.freeTerminalDict.push({'id':10, 'text': "N/A"})
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });
    }

    valueForDict(array, index) {
        if ((array === null || array === undefined) && (index === null || index === undefined)) {
            return 'N/A'
        } else {
            let value = array.find(item => item.id === index);
            if (value === undefined) {
                return 'N/A'
            } else {
                return value.text;
            }
        }
    };

    handleChangeName = (e) => {
        const value = e.target.value;
        this.setState({
            name: value,
        })
    };

    handleChangeDescription = (e) => {
        const value = e.target.value;
        this.setState({
            description: value,
        })
    };

    handleChangeArea = (e) => {
        const value = e.target.value;
        this.setState({
            area: value,
        })
    };

    handleChangeTelephone = (e) => {
        const value = e.target.value;
        this.setState({
            telephone: value,
        })
    };

    handleChangeEmail = (e) => {
        const value = e.target.value;
        this.setState({
            email: value,
        })
    };

    handleChangeCommissionPlan = (e) => {
        const value = e.target.value;
        this.setState({
            commissionPlan: value,
        })
    };

    handleChangeCity = (e) => {
        const value = e.target.value;
        this.setState({
            city: value,
        })
    };

    handleChangeTerminal = (e) => {
        const value = e.target.value;
        if(e.target.value === 10){
            this.setState({
                terminal: null
            })
        } else {
            this.setState({
                terminal: value,
            })
        }
    };

    handleChangeIncassator = (e) => {
        const value = e.target.value;
        this.setState({
            incassator: value,
        })
    };

    // handleChangePermission = (e) => {
    //     const value = e.target.value;
    //     this.setState({
    //         terminal: value,
    //     })
    // };

    // handleChangeValidTill = (e) => {
    //     let validTill = e;
    //     console.log(moment(validTill));
    //     this.setState({
    //         validTill: validTill,
    //     })
    // };

    handleChangeFakesPossible(checked) {
        this.setState({
            fakesPossible: checked
        });

    };

    handleChangeLatitude = (e) => {

        const value = e.target.value;
        this.setState({
            latitude: value,
        })

    };

    handleChangeLongitude = (e) => {

        const value = e.target.value;
        this.setState({
            longitude: value,
        })

    };

    handleChangeHide(checked) {
        this.setState({
            hide: checked
        });
    };

    handleFakesPossible(checked) {
        this.setState({
            fakesPossible: checked
        });
    };

    // handleChangeHiddenPercent = (event) => {
    //     let hiddenPercent = event.target.value;
    //     if (hiddenPercent < 0) {
    //         hiddenPercent = 0
    //     } else if (hiddenPercent > 100) {
    //         hiddenPercent = 100
    //     }
    //     this.setState({
    //         hiddenPercent
    //     })
    // };

    handleClear() {
        this.setState({
            name: '',
            description: '',
            telephone: '',
            email: '',
            area: '',
            commissionPlan: 0,
            city: 1,
            terminal: 10,
            incassator: null,
            permission: 5,
            hiddenPercent: 0,
            fakesPossible: false,
            hide: false,
            latitude: 25.25,
            longitude: 51.51,
        })
    };

    handleCancel() {
        this.componentDidMount();
        this.setState({
            edit: false,
            disabled: true
        })
    };

    handleEdit() {
        if (this.state.edit === false) {
            this.setState({
                edit: true,
                disabled: false

            })
        } else {
            this.setState({
                edit: false,
                disabled: true
            })
        }
    };

    handleSubmit() {
        this.sendPlaceRequest();
    };

    // handleExtended() {
    //     const extendedDefault = this.state.extendedForm === false ? true : false;
    //     // editDefault === false ? true : false;
    //     this.setState({
    //         extendedForm: extendedDefault,
    //     })
    // };

    sendPlaceRequest() {
        const data = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            telephone: this.state.telephone,
            area: this.state.area,
            email: this.state.email,
            commissionPlan: parseInt(this.state.commissionPlan),
            city: parseInt(this.state.city),
            terminal: parseInt(this.state.terminal) === 10 ? null : parseInt(this.state.terminal),
            incassator: parseInt(this.state.incassator),
            permission: parseInt(this.state.permission),
            validTill: this.state.validTill,
            hiddenPercent: parseFloat(this.state.hiddenPercent),
            fakesPossible: this.state.fakesPossible,
            hide: this.state.hide,
            latitude: parseFloat(this.state.latitude),
            longitude: parseFloat(this.state.longitude),
        };

        axios.post(config.url.API_URL + "/place/", data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        })
            .then(res => {

                const result = res.data;
                this.setState({
                    id: result.id ? result.id : 'N/A',
                    name: result.name ? result.name : 'N/A',
                    area: result.area ? result.area : 'N/A',
                    description: result.description ? result.description : 'N/A',
                    telephone: result.telephone ? result.telephone : 'N/A',
                    email: result.email ? result.email : 'N/A',
                    commissionPlan: result.commissionPlan,
                    city: result.city,
                    terminal: result.terminal,
                    incassator: result.incassator,
                    permission: result.permission,
                    validTill: result.validTill,
                    hiddenPercent: result.hiddenPercent,
                    fakesPossible: result.fakesPossible,
                    hide: result.hide,
                    latitude: result.latitude,
                    longitude: result.longitude,

                    edit: false,
                    disabled: true
                });
            })
            .catch(e => {
                console.log(e);
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });
    };


    render() {
        // this.state.freeTerminalDict.push({"id":0, 'text':"null"})
        let modalClose = () => this.setState({modalShow: false});
        return (
            <div>
                {this.state.isLoaded < 8 ? <LoadingPage/> :
                    <div className="kt-portlet kt-portlet--mobile">
                        <MyVerticallyCenteredModal
                            heder={this.state.errorStatus}
                            h4={this.state.statusText}
                            text={this.state.errorMessage}
                            show={this.state.modalShow}
                            onHide={modalClose}
                        />

                        <form className="mitra-form">
                            <div className="dima_heder">Agent: {this.state.name}</div>
                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">ID</label>
                                </div>
                                <div className="input_form_single">
                                    <Input
                                        disabled={true}
                                        type="text"
                                        value={this.state.id}
                                        id="id"
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Name</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.disabled}
                                        type="text"
                                        value={this.state.name}
                                        id="description"
                                        onChange={this.handleChangeName}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Description</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.disabled}
                                        type="text"
                                        value={this.state.description}
                                        id="description"
                                        onChange={this.handleChangeDescription}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">City</label>
                                </div>

                                <div className="input_form_single">
                                    <Select2
                                        disabled={this.state.disabled}
                                        className="mitra-select"
                                        id="city"
                                        data={this.state.cityDict}
                                        value={this.state.city}
                                        onChange={this.handleChangeCity}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Area</label>
                                </div>
                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.disabled}
                                        type="text"
                                        value={this.state.area}
                                        onChange={this.handleChangeArea}
                                        id="Area"
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Location</label>
                                </div>
                                <div className="input_form_single">
                                    <Input
                                        disabled={true}
                                        type="text"
                                        value="N/A"
                                        id="location"
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Telephone</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.disabled}
                                        type="text"
                                        value={this.state.telephone}
                                        id="telephone"
                                        onChange={this.handleChangeTelephone}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Email</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.disabled}
                                        type="email"
                                        value={this.state.email}
                                        id="email"
                                        onChange={this.handleChangeEmail}
                                    />
                                </div>
                            </div>


                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Wifi profile</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={true}
                                        id="wifiProfile"
                                        type="text"
                                        value="N/A"
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Terminal</label>
                                </div>

                                <div className="input_form_single">
                                    <Select2
                                        id="terminal"
                                        disabled={this.state.disabled}
                                        className="mitra-select"
                                        // data={this.state.disabled ?  this.state.terminalDict : this.state.freeTerminalDict}
                                        data={this.state.freeTerminalDict}
                                        value={this.state.terminal}
                                        onChange={this.handleChangeTerminal}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Team</label>
                                </div>
                                <div className="input_form_single">
                                    <Select2
                                        id="incassator"
                                        disabled={this.state.disabled}
                                        className="mitra-select"
                                        data={this.state.incassatorDict}
                                        value={this.state.incassator}
                                        onChange={this.handleChangeIncassator}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Commission</label>
                                </div>

                                <div className="input_form_single">
                                    <Select2
                                        id="commission"
                                        disabled={this.state.disabled}
                                        className="mitra-select"
                                        data={this.state.commissionPlanDict}
                                        value={this.state.commissionPlan}
                                        onChange={this.handleChangeCommissionPlan}
                                    />
                                </div>
                            </div>

                            <div className="average_block">
                                <p className="average_p">Average turnover, QAR</p>
                                <div className="dima_form_single">
                                    <div className="label_inline">
                                        <label className="label_form">Yesterday</label>
                                    </div>
                                    <div className="input_form_single">
                                        <Input
                                            disabled={true}
                                            type="text"
                                            id="yesterday"
                                            value={this.state.averageTurnover.yesterday.averageSum + '\u00A0\u00A0\u00A0QAR'}
                                        />
                                        {console.log(this.state.averageTurnover)}
                                    </div>
                                </div>
                            </div>

                            <div className="average_block">
                                <div className="dima_form_single">
                                    <div className="label_inline">
                                        <label className="label_form">1 week</label>
                                    </div>

                                    <div className="input_form_single">
                                        <Input
                                            disabled={true}
                                            type="text"
                                            id="1weeks"
                                            value={this.state.averageTurnover.week1.averageSum + '\u00A0\u00A0\u00A0QAR'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="average_block">
                                <div className="dima_form_single">
                                    <div className="label_inline">
                                        <label className="label_form">2 weeks</label>
                                    </div>

                                    <div className="input_form_single">
                                        <Input
                                            disabled={true}
                                            type="text"
                                            id="2weeks"
                                            value={this.state.averageTurnover.week2.averageSum + '\u00A0\u00A0\u00A0QAR'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="average_block">
                                <div className="dima_form_single">
                                    <div className="label_inline">
                                        <label className="label_form">1 month</label>
                                    </div>

                                    <div className="input_form_single">
                                        <Input
                                            disabled={true}
                                            type="text"
                                            id="1month"
                                            value={this.state.averageTurnover.month1.averageSum + '\u00A0\u00A0\u00A0QAR'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="average_block">
                                <div className="dima_form_single">
                                    <div className="label_inline">
                                        <label className="label_form">3 months</label>
                                    </div>

                                    <div className="input_form_single">
                                        <Input
                                            disabled={true}
                                            type="text"
                                            id="3monts"
                                            value={this.state.averageTurnover.month3.averageSum + '\u00A0\u00A0\u00A0QAR'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="average_block">
                                <div className="dima_form_single">
                                    <div className="label_inline">
                                        <label className="label_form">6 months</label>
                                    </div>

                                    <div className="input_form_single">
                                        <Input
                                            disabled={true}
                                            type="text"
                                            id="6months"
                                            value={this.state.averageTurnover.month6.averageSum + '\u00A0\u00A0\u00A0QAR'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="average_block">
                                <div className="dima_form_single">
                                    <div className="label_inline">
                                        <label className="label_form">1 year</label>
                                    </div>
                                    <div className="input_form_single">
                                        <Input
                                            disabled={true}
                                            type="text"
                                            id="1year"
                                            value={this.state.averageTurnover.year1.averageSum + '\u00A0\u00A0\u00A0QAR'}
                                        />
                                    </div>
                                </div>
                            </div>


                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">Permission</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.disabled === false ?*/}
                            {/*            <p className="dima_form_p">{this.valueForDict(this.state.permissionDict ,this.state.permission)}</p> :*/}
                            {/*            < Select*/}
                            {/*                className={'input_form_single_select'}*/}
                            {/*                multiple={'false'}*/}
                            {/*                id={'permission'}*/}
                            {/*                placeholder={''}*/}
                            {/*                dict={this.state.permissionDict}*/}
                            {/*                value={this.state.permission}*/}
                            {/*                onChange={this.handleChangePermission}*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">Valid till</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*            <p className="dima_form_p">{this.state.validTill === null || this.state.validTill === undefined ? 'N/A' : moment(this.state.validTill).format("DD.MM.YYYY")}</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*{this.state.extendedForm === false ? "" :*/}
                            {/*    <div className="extended_form_dima">*/}
                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">Hidden percent</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.disabled === false ?*/}
                            {/*            (<p className="dima_form_p">{this.state.hiddenPercent}</p>) :*/}

                            {/*            (<div className="input_form_single ">*/}
                            {/*                <input type="number"*/}
                            {/*                       min={"0"}*/}
                            {/*                       max={"100"}*/}
                            {/*                       value={this.state.hiddenPercent}*/}
                            {/*                       className="form-control"*/}
                            {/*                       placeholder={''}*/}
                            {/*                       id="hiddenPercent"*/}
                            {/*                       onChange={this.handleChangeHiddenPercent}*/}
                            {/*                />*/}
                            {/*            </div>)*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="dima_form_single_switch" style={{marginTop : "3em"}}>
                                <div className="switch_block_dima">
                                    <Switch
                                        disabled={this.state.disabled}
                                        checked={this.state.hide}
                                        onChange={this.handleChangeHide}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        className="kt-switch"
                                        id="kt-switch"
                                    />
                                    <div className="label_inline_switch">
                                        <label className="label_form">Hide</label>
                                    </div>
                                </div>
                            </div>
                            <div className="dima_form_single_switch">
                                <div className="switch_block_dima">
                                    <Switch
                                        disabled={this.state.disabled}
                                        checked={this.state.fakesPossible}
                                        onChange={this.handleFakesPossible}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        className="kt-switch"
                                        id="kt-switch"
                                    />
                                    <div className="label_inline_switch">
                                        <label className="label_form">Fakes possible</label>
                                    </div>
                                </div>
                            </div>
                            <div className="btn_form-dima">
                                <Button
                                    type="button"
                                    id="report"
                                    disabled={this.state.edit}
                                    name="Report"
                                />
                                <Button
                                    onClick={this.handleEdit}
                                    type="button"
                                    id="edit"
                                    disabled={this.state.edit}
                                    name="Edit"
                                />
                                <Button
                                    onClick={this.handleClear}
                                    type="button"
                                    id="clear"
                                    disabled={this.state.disabled}
                                    name="Clear"
                                />
                                <Button
                                    onClick={this.handleCancel}
                                    className="mitra-button-cancel"
                                    type="button"
                                    id="cancel"
                                    disabled={this.state.disabled}
                                    name="Cancel"
                                />
                                <Button
                                    onClick={this.handleSubmit}
                                    className="mitra-button-submit"
                                    type="button"
                                    id="save"
                                    disabled={this.state.disabled}
                                    name="Save"
                                />
                            </div>
                        </form>

                    </div>
                }
            </div>
        );
    }
}

export default PlaceForm;
