import React, {useState} from 'react';
import AsideMini from "./AsideMini";
import AsideMax from "./AsideMax";

export default function Aside() {
    const [isToggleOn, setIsToggleOn] = useState(localStorage.getItem("menuAside") === "mini" ? false : true);
    function handleButton() {
        if (Boolean(localStorage.getItem('menuAside')) === true) {
            document.body.classList.remove("kt-aside--minimize")
            setIsToggleOn(!isToggleOn)
            localStorage.setItem('menuAside', '')
        } else {
            document.body.classList.add("kt-aside--minimize")
            setIsToggleOn(!isToggleOn)
            localStorage.setItem('menuAside', "mini")
        }
    }


    return (
        <>
            {isToggleOn ? <AsideMax
                handleButton={handleButton}
            /> : <AsideMini
                handleButton={handleButton}
            />}
        </>
    );
}

