import React from "react";
import './Table.css'

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table className="kt-datatable__table">

            <thead className="kt-datatable__head" style={{borderBottom: "solid"}}>
            <tr className="kt-datatable__row">

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'id')}
                >
                    <span style={{width: "40px"}}>
                       ID
                        {props.field === 'id' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'number')}
                >
                    <span style={{width: "90px"}}>
                       Account
                        {props.field === 'number' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'operator')}
                >
                    <span style={{width: "90px"}}>
                       Operator
                        {props.field === 'operator' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'terminal')}
                >
                    <span style={{width: "90px"}}>
                       Terminal
                        {props.field === 'terminal' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'autoRefill')}
                >
                    <span style={{width: "115px"}}>
                       Auto recharge
                        {props.field === 'autoRefill' ? (props.sort === "asc") ?
                            <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                {/* <th className="kt-datatable__cell kt-datatable__cell--sort"*/}
                {/*                    onClick={props.onSort.bind(null, 'moreData')}*/}
                {/*                >*/}
                {/*                    <span style={{width: "110px"}}>*/}
                {/*                       More data*/}
                {/*                        {props.field === 'moreData' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :*/}
                {/*                        <i className="flaticon2-arrow-down"></i> : null}*/}
                {/*                    </span>*/}
                {/*                </th>*/}
                {/* <th className="kt-datatable__cell kt-datatable__cell--sort"*/}
                {/*                    onClick={props.onSort.bind(null, 'moreDataDays')}*/}
                {/*                >*/}
                {/*                    <span style={{width: "125px"}}>*/}
                {/*                       More data days*/}
                {/*                        {props.field === 'moreDataDays' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :*/}
                {/*                        <i className="flaticon2-arrow-down"></i> : null}*/}
                {/*                    </span>*/}
                {/*                </th>*/}
                {/*<th className="kt-datatable__cell kt-datatable__cell--sort"*/}
                {/*                    onClick={props.onSort.bind(null, 'moreTime')}*/}
                {/*                >*/}
                {/*                    <span style={{width: "90px"}}>*/}
                {/*                       More time*/}
                {/*                        {props.field === 'moreTime' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :*/}
                {/*                        <i className="flaticon2-arrow-down"></i> : null}*/}
                {/*                    </span>*/}
                {/*                </th>*/}

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'lastRefill')}
                >
                    <span style={{width: "120px"}}>
                       Last recharge
                        {props.field === 'lastRefill' ? (props.sort === "asc") ?
                            <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'signal')}
                >
                    <span style={{width: "90px"}}>
                       Signal
                        {props.field === 'signal' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>

            </tr>
            </thead>
        </table>
    </div>
)

