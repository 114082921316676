import {sendDictGET} from "./sendRequest";

export function saveDict() {
    sendDictGET("/dictionary/operatorDict", null).then(x => {
        localStorage.setItem("operatorDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/terminalDict", null).then(x => {
        localStorage.setItem("terminalDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/freeTerminalDict", null).then(x => {
        localStorage.setItem("freeTerminalDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/terminalStatusDict", null).then(x => {
        localStorage.setItem("terminalStatusDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/terminalTypeDict", null).then(x => {
        localStorage.setItem("terminalTypeDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/commandTypeDict", null).then(x => {
        localStorage.setItem("commandTypeDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/placeDict", null).then(x => {
        localStorage.setItem("placeDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/placeCommissionPlanDict", null).then(x => {
        localStorage.setItem("placeCommissionPlanDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/placePermissionDict", null).then(x => {
        localStorage.setItem("placePermissionDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/statusDict", null).then(x => {
        localStorage.setItem("statusDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/serviceDict", null).then(x => {
        localStorage.setItem("serviceDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/serviceFlatDict", null).then(x => {
        localStorage.setItem("serviceFlatDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/cityDict", null).then(x => {
        localStorage.setItem("cityDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/badPaymentStatus", null).then(x => {
        localStorage.setItem("badPaymentStatus", JSON.stringify(x))
    });
    sendDictGET("/dictionary/chanelDict", null).then(x => {
        localStorage.setItem("chanelDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/countryDict", null).then(x => {
        localStorage.setItem("countryDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/badTerminalStatus", null).then(x => {
        localStorage.setItem("badTerminalStatus", JSON.stringify(x))
    });
    sendDictGET("/dictionary/currencyDict", null).then(x => {
        localStorage.setItem("currencyDict", JSON.stringify(x))
    });
    sendDictGET("/dictionary/incassatorDict", null).then(x => {
        localStorage.setItem("incassatorDict", JSON.stringify(x))
    });
    sendDictGET("/report/reportType", null).then(x => {
        localStorage.setItem("reportType", JSON.stringify(x))
    });
    sendDictGET("/dictionary/listTypeDict", null).then(x => {
        localStorage.setItem("listTypeDict", JSON.stringify(x));
        localStorage.setItem("loadingDict", 'true');
    });
}