import React from 'react';
import {config} from '../util/constants'
import axios from "axios";
import LoadingPage from "../LoadingPage";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            balance: [],
            current: [],
            paymentYesterday: [],
            paymentToday: [],
            averageTurnover: {},
            bearer: localStorage.getItem("authToken"),
            paymentsQueue: '',
            modalShow: false,
            errorStatus: '',
            statusText: '',
            errorMessage: '',
            isLoaded: null,
        };

    }


    componentDidMount() {
        document.title = 'Dashboard - mitra processing';
        axios.get(config.url.API_URL + "/dashboard/balance/", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const balance = res.data;
            this.setState({
                balance,
                isLoaded: this.state.isLoaded + 1
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;
                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    let errorStatus = response.status;

                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

        axios.get(config.url.API_URL + "/dashboard/currentStatistic/", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const current = res.data;
            this.setState({
                current,
                isLoaded: this.state.isLoaded + 1
            });
        })

            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;
                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    let errorStatus = response.status;

                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });
        axios.get(config.url.API_URL + "/dashboard/paymentStatistic/", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                paymentsQueue: result.paymentsQueue,
                paymentToday: result.today,
                paymentYesterday: result.yesterday,
                isLoaded: this.state.isLoaded + 1
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;
                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    let errorStatus = response.status;

                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

        axios.get(config.url.API_URL + "/dashboard/averageTurnover/", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                averageTurnover: result,
                isLoaded: this.state.isLoaded + 1
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;
                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    let errorStatus = response.status;

                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });
    }

    render() {
        let modalClose = () => this.setState({modalShow: false});
        const {current, balance, paymentYesterday, paymentToday, averageTurnover, isLoaded,paymentsQueue} = this.state;
        return (
            <div>
                <MyVerticallyCenteredModal
                    heder={this.state.errorStatus}
                    h4={this.state.statusText}
                    text={this.state.errorMessage}
                    show={this.state.modalShow}
                    onHide={modalClose}
                />
                {localStorage.getItem("loadingDict") && isLoaded > 3 ?
                    <div className="row row_dashboard">
                        <div className="col-xl-4 balances">

                            <div className="kt-section">
                                <div className="kt-section__content">
                                    <table className="table table_dashboard_balance">
                                        <thead className="dima_dashboard_thead">
                                        <tr className="dashboard_tr dashboard_tr_thead">
                                            <td className="dashboard_td_left dashboard_td_thead"><span
                                                className="dashboard_thead_span">Balances</span></td>
                                        </tr>
                                        </thead>
                                        {balance.map(item => (
                                            <tbody
                                            >


                                            <tr className="dashboard_tr">
                                                <td className="dashboard_td_left">{item.name}</td>
                                                <td className="dashboard_td_right">{item.balance.toFixed(2) + "\u00A0\u00A0\u00A0" + item.currency}</td>
                                            </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 current">
                            <div className="kt-section">
                                <div className="kt-section__content">
                                    <table className="table table_dashboard_current">
                                        <thead className="dima_dashboard_thead">
                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left"><span
                                                className="dashboard_thead_span">Terminals</span></td>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">Online</td>
                                            <td className="dashboard_td_right">{current.online}</td>
                                        </tr>
                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">Offline</td>
                                            <td className="dashboard_td_right">{current.offline}</td>
                                        </tr>
                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">Failed</td>
                                            <td className="dashboard_td_right">{current.failed}</td>
                                        </tr>
                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">Incassed</td>
                                            <td className="dashboard_td_right">{current.incassed}</td>
                                        </tr>

                                        {/*<tr className="dashboard_tr">*/}
                                        {/*    <td className="dashboard_td_left">Payments queue</td>*/}
                                        {/*    <td className="dashboard_td_right">{current.paymentsQueue}</td>*/}
                                        {/*</tr>*/}

                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">Cash inside</td>
                                            <td className="dashboard_td_right">{current.cashInTerminals + '\u00A0\u00A0\u00A0QAR'}</td>
                                        </tr>

                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">Today incass</td>
                                            <td className="dashboard_td_right">{current.todayIncass + '\u00A0\u00A0\u00A0QAR'}</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 payments">
                            <div className="kt-section">
                                <div className="kt-section__content">
                                    <table
                                        className="table table_dashboard table_dima payment_table table_dashboard_payment">
                                        <thead className="dima_dashboard_thead">
                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left_payment"><span
                                                className="dashboard_thead_span"> Payments </span></td>
                                            <td className="dashboard_td_center"><span
                                                className="dashboard_thead_span"> Today</span></td>
                                            <td className="dashboard_td_right_thead_payment "><span
                                                className="dashboard_thead_span"> Yesterday</span></td>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr className="dashboard_tr"
                                        >
                                            <td className="dashboard_td_left_payment">Got</td>
                                            <td className="dashboard_td_center">{paymentToday.sold + '\u00A0\u00A0\u00A0QAR'}</td>
                                            <td className="dashboard_td_right_payment">{paymentYesterday.sold + '\u00A0\u00A0\u00A0QAR'}</td>
                                        </tr>

                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left_payment">OK</td>
                                            <td className="dashboard_td_center">{paymentToday.ok}</td>
                                            <td className="dashboard_td_right_payment">{paymentYesterday.ok}</td>
                                        </tr>

                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left_payment">Incomplete</td>
                                            <td className="dashboard_td_center">{paymentToday.incomplete}</td>
                                            <td className="dashboard_td_right_payment">{paymentYesterday.incomplete}</td>
                                        </tr>

                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left_payment">Error</td>
                                            <td className="dashboard_td_center">{paymentToday.error}</td>
                                            <td className="dashboard_td_right_payment">{paymentYesterday.error}</td>
                                        </tr>

                                        {/*<tr className="dashboard_tr">*/}
                                        {/*    <td className="dashboard_td_left_payment">Out of range</td>*/}
                                        {/*    <td className="dashboard_td_center">{paymentToday.outOfRange}</td>*/}
                                        {/*    <td className="dashboard_td_right_payment">{paymentYesterday.outOfRange}</td>*/}
                                        {/*</tr>*/}
                                        {/*TODO  Макса попросить сделать в API данные Queue*/}
                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left_payment">Queue</td>
                                            <td className="dashboard_td_center">{paymentsQueue}</td>
                                            <td className="dashboard_td_right_payment">{0}</td>
                                        </tr>

                                        {/*<tr className="dashboard_tr">*/}
                                        {/*    <td className="dashboard_td_left_payment">Hold</td>*/}
                                        {/*    <td className="dashboard_td_center">{paymentToday.hold}</td>*/}
                                        {/*    <td className="dashboard_td_right_payment">{paymentYesterday.hold}</td>*/}
                                        {/*</tr>*/}

                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left_payment">Expenses</td>
                                            <td className="dashboard_td_center">{paymentToday.expenses + '\u00A0\u00A0\u00A0QAR'}</td>
                                            <td className="dashboard_td_right_payment">{paymentYesterday.expenses + '\u00A0\u00A0\u00A0QAR'}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 current">
                            <div className="kt-section">
                                <div className="kt-section__content">
                                    <table className="table table_dashboard_everrichturnover">
                                        <thead className="dima_dashboard_thead">
                                        <tr className="dashboard_tr">
                                            <td className="dashboard"><span
                                                className="dashboard_thead_span">Average turnover</span>
                                            </td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">Yesterday</td>
                                            <td className="dashboard_td_right">{averageTurnover.yesterday.averageSum + '\u00A0\u00A0\u00A0QAR'}</td>
                                        </tr>
                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">1 week</td>
                                            <td className="dashboard_td_right">{averageTurnover.week1.averageSum + '\u00A0\u00A0\u00A0QAR'}</td>
                                        </tr>
                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">2 weeks</td>
                                            <td className="dashboard_td_right">{averageTurnover.week2.averageSum + '\u00A0\u00A0\u00A0QAR'}</td>
                                        </tr>
                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">1 month</td>
                                            <td className="dashboard_td_right">{averageTurnover.month1.averageSum + '\u00A0\u00A0\u00A0QAR'}</td>
                                        </tr>

                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">3 months</td>
                                            <td className="dashboard_td_right">{averageTurnover.month3.averageSum + '\u00A0\u00A0\u00A0QAR'}</td>
                                        </tr>

                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">6 months</td>
                                            <td className="dashboard_td_right">{averageTurnover.month6.averageSum + '\u00A0\u00A0\u00A0QAR'}</td>
                                        </tr>

                                        <tr className="dashboard_tr">
                                            <td className="dashboard_td_left">1 year</td>
                                            <td className="dashboard_td_right">{averageTurnover.year1.averageSum + '\u00A0\u00A0\u00A0QAR'}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                    : <LoadingPage/>}
            </div>

        );


    }
}

export default Dashboard
