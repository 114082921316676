import React from "react";
import {Line} from "react-chartjs-2";
import './graph.css';

const data = {
    // labels: ['1', '2', '3', '4', '5', '6'],
    datasets: [
        {
            label: 'CoPi',
            data: [{x: '2016-12-25', y: 15}, {x: '2016-12-26', y: 25}, {x: '2016-12-27', y: 35}, {
                x: '2016-12-28',
                y: 45
            }, {x: '2016-12-29', y: 35}, {x: '2016-12-30', y: 25}, {x: '2016-12-31', y: 55}],
            fill: false,
            backgroundColor: 'rgb(210,77,27)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
        },
        {
            label: 'CaPi',
            data: [{x: '2016-12-25', y: 17}, {x: '2016-12-26', y: 27}, {x: '2016-12-27', y: 37}, {
                x: '2016-12-28',
                y: 47
            }, {x: '2016-12-29', y: 37}, {x: '2016-12-30', y: 27}, {x: '2016-12-31', y: 57}],
            fill: false,
            backgroundColor: 'rgb(210,158,27)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
        },
        {
            label: 'TFPi',
            data: [{x: '2016-12-25', y: 13}, {x: '2016-12-26', y: 23}, {x: '2016-12-27', y: 33}, {
                x: '2016-12-28',
                y: 43
            }, {x: '2016-12-29', y: 33}, {x: '2016-12-30', y: 23}, {x: '2016-12-31', y: 53}],
            fill: false,
            backgroundColor: 'rgb(27,210,57)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
        },
        {
            label: 'WRPi',
            data: [{x: '2016-12-25', y: 10}, {x: '2016-12-26', y: 20}, {x: '2016-12-27', y: 30}, {
                x: '2016-12-28',
                y: 40
            }, {x: '2016-12-29', y: 30}, {x: '2016-12-30', y: 20}, {x: '2016-12-31', y: 50}],
            fill: false,
            backgroundColor: 'rgb(210,158,27)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
        },

    ]
};

const options = {

    scales: {
        myScale: {
            type: 'logarithmic',
            position: 'right', // `axis` is determined by the position as `'y'`
        },
        yAxes: [

            {

                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};
export default function LineChart() {

    return (
        <div className="body_example">
            <div className={'mitra-raw'}>
                <div className="mitra-block-2">
                    <Line data={data} options={options}/>
                </div>
            </div>
        </div>
    )
}