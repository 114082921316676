import React from 'react';
import LoadingPage from "../LoadingPage";
import '../paymentForm/Form.css';
import Switch from "react-switch";
import {config} from "../util/constants";
import axios from "axios";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Input from "../Input";
import Button from "../Button";

class UserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bearer: localStorage.getItem("authToken"),

            id: null,
            name: '',
            password: '',
            enable: true,
            isAdmin: true,
            isIncassator: false,
            isManager: false,
            email: '',
            description: '',
            firstName: '',
            lastName: '',

            modalShow: false,
            statusText: '',
            errorMessage: '',
            errorStatus: null,

            isLoaded: false,
            edit: true,
        };

        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangeEnable = this.handleChangeEnable.bind(this);
        this.handleChangeIsIncassator = this.handleChangeIsIncassator.bind(this);
        this.handleChangeIsManager = this.handleChangeIsManager.bind(this);
        this.handleChangeIsAdmin = this.handleChangeIsAdmin.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {

        let request = config.url.API_URL + window.location.pathname;
        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    id: result.id,
                    name: result.name,
                    email: result.email,
                    description: result.description,
                    firstName: result.firstName,
                    lastName: result.lastName,
                    enable: result.enable,
                    isAdmin: result.isAdmin,
                    isIncassator: result.isIncassator,
                    isManager: result.isManager,

                    isLoaded: true,
                });
                document.title ='User: ' + this.state.name + ' - mitra processing';

            }
        ).catch(error => {
            if (error.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = error.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });

            }
        })
    };

    sendUserRequest() {
        const data = {
            id: parseInt(this.state.id),
            description: this.state.description,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            name: this.state.name,
            enable: this.state.enable,
            isAdmin: this.state.isAdmin,
            isIncassator: this.state.isIncassator,
            isManager: this.state.isManager,
        };
        axios.post(config.url.API_URL + "/user/", data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        })
            .then(res => {
                this.setState({
                    edit: true
                    // modalShow: true,
                });
            })
            .catch(e => {
                console.log(e);
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });
    };

    // sendUserChangePassword() {
    //     axios(config.url.API_URL + "/user/" + this.state.id, {
    //         headers: {
    //             'Authorization': 'Bearer ' + this.state.bearer
    //         }
    //     })
    //         .then(res => {
    //             const result = res.data;
    //             this.setState({
    //                 id: result.id,
    //             });
    //         })
    //         .catch(error => {
    //             if (error.request.status === 401) {
    //                 localStorage.setItem("auth", this.props.setAuth);
    //                 this.props.setAuth(false);
    //             } else {
    //                 let response = error.response;
    //                 let statusText = response.data.status;
    //                 let errorMessage = response.data.message;
    //                 console.log(errorMessage);
    //                 let errorStatus = response.status;
    //                 this.setState({
    //                     modalShow: true,
    //                     errorStatus,
    //                     statusText,
    //                     errorMessage,
    //                 });
    //
    //             }
    //         });
    // };

    handleSubmit(event) {
        event.preventDefault();
        this.sendUserRequest();
    }

    handleChangeUserName = (e) => {

        const value = e.target.value;
        this.setState({
            name: value,
        })
    };

    handleChangeEmail = (e) => {

        const value = e.target.value;
        this.setState({
            email: value,
        })
    };

    handleChangeFirstName = (e) => {

        const value = e.target.value;
        this.setState({
            firstName: value,
        })

    };

    handleChangePassword = (e) => {

        const value = e.target.value;
        this.setState({
            password: value,
        })

    };

    handleChangeLastName = (e) => {

        const value = e.target.value;
        this.setState({
            lastName: value,
        })

    };

    handleChangeDescription = (e) => {

        const value = e.target.value;
        this.setState({
            description: value,
        })

    };


    handleChangeEnable(checked) {
        this.setState({
            enable: checked
        });

    };

    handleChangeIsAdmin(checked) {
        this.setState({
            isAdmin: checked,
        })
    };

    handleChangeIsIncassator(checked) {
        this.setState({
            isIncassator: checked,
        })
    };

    handleChangeIsManager(checked) {
        this.setState({
            isManager: checked,
        })
    };

    handleClear(e) {
        e.preventDefault();
        this.setState({
            name: '',
            enable: true,
            isAdmin: false,
            isIncassator: false,
            isManager: false,
            email: '',
            description: '',
            firstName: '',
            lastName: '',
        })
    };

    handleCancel(event) {
        event.preventDefault();
        this.componentDidMount();
        this.setState({
            email: '',
            description: '',
            firstName: '',
            lastName: '',
            edit: true,
        })
    };

    handleEdit() {
        if (this.state.edit === false) {
            let edit = true;
            this.setState({
                edit
            })
        } else {
            let edit = false;
            this.setState({
                edit
            })
        }
    };

    render() {
        let modalClose = () => this.setState({modalShow: false});
        return (
            <div>
                {this.state.isLoaded === false ? <LoadingPage/> :

                    <div className="kt-portlet kt-portlet--mobile">
                        <MyVerticallyCenteredModal
                            heder={this.state.errorStatus}
                            h4={this.state.statusText}
                            text={this.state.errorMessage}
                            show={this.state.modalShow}
                            onHide={modalClose}
                        />

                        <form className="mitra-form">
                            <div className="dima_heder">User: {this.state.name}</div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">ID</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={true}
                                        type="text"
                                        id="id"
                                        value={this.state.id}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Description</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.edit}
                                        type="text"
                                        value={this.state.description}
                                        id="description"
                                        onChange={this.handleChangeDescription}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">User</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.edit}
                                        type="text"
                                        value={this.state.name}
                                        id="name"
                                        onChange={this.handleChangeUserName}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Password</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.edit}
                                        type="password"
                                        value={this.state.password}
                                        id="password"
                                        onChange={this.handleChangePassword}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">

                                <div className="label_inline">
                                    <label className="label_form">Email</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.edit}
                                        type="email"
                                        value={this.state.email}
                                        id="email"
                                        onChange={this.handleChangeEmail}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">

                                <div className="label_inline">
                                    <label className="label_form">First name</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.edit}
                                        type="text"
                                        value={this.state.firstName}
                                        id="firstName"
                                        onChange={this.handleChangeFirstName}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">

                                <div className="label_inline">
                                    <label className="label_form">Last name</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.edit}
                                        type="text"
                                        value={this.state.lastName}
                                        id="lastName"
                                        onChange={this.handleChangeLastName}
                                    />
                                </div>
                            </div>


                            <div className="dima_form_single_switch">
                                <div className="switch_block_dima">
                                    <Switch
                                        disabled={this.state.edit}
                                        checked={this.state.enable}
                                        onChange={this.handleChangeEnable}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        className="kt-switch"
                                        id="kt-switch"
                                    />
                                    <div className="label_inline_switch">
                                        <label className="label_form">Enable</label>
                                    </div>
                                </div>
                            </div>

                            <div className="dima_form_single_switch">
                                <div className="switch_block_dima">
                                    <Switch
                                        disabled={this.state.edit}
                                        checked={this.state.isAdmin}
                                        onChange={this.handleChangeIsAdmin}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        className="kt-switch"
                                        id="isAdmin"
                                    />

                                    <div className="label_inline_switch">
                                        <label className="label_form">Admin</label>
                                    </div>

                                </div>
                            </div>


                            <div className="dima_form_single_switch">
                                <div className="switch_block_dima">
                                    <Switch
                                        disabled={this.state.edit}
                                        checked={this.state.isIncassator}
                                        onChange={this.handleChangeIsIncassator}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        className="kt-switch"
                                        id="kt-switch"
                                    />

                                    <div className="label_inline_switch">
                                        <label className="label_form">Incassator</label>
                                    </div>

                                </div>
                            </div>

                            <div className="dima_form_single_switch" style={{marginTop : "3em"}}>
                                <div className="switch_block_dima">
                                    <Switch
                                        disabled={this.state.edit}
                                        checked={this.state.isManager}
                                        onChange={this.handleChangeIsManager}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        className="kt-switch"
                                        id="kt-switch"
                                    />

                                    <div className="label_inline_switch">
                                        <label className="label_form">Manager</label>
                                    </div>
                                </div>
                            </div>

                            <div className="btn_form-dima">
                                <Button
                                    type="button"
                                    name="Report"
                                />

                                <Button
                                    onClick={this.handleEdit}
                                    type="button"
                                    name="Edit"
                                />

                                <Button
                                    onClick={this.handleClear}
                                    disabled={this.state.edit}
                                    type="button"
                                    name="Clear"
                                />

                                <Button
                                    onClick={this.handleCancel}
                                    disabled={this.state.edit}
                                    type="button"
                                    className="mitra-button-cancel"
                                    name="Cancel"
                                />

                                <Button
                                    onClick={this.handleSubmit}
                                    disabled={this.state.edit}
                                    type="button"
                                    className="mitra-button-submit"
                                    name="Save"
                                />
                            </div>
                        </form>
                    </div>
                }
            </div>
        );
    }
}

export default UserForm;
