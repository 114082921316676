import React from 'react';
import {Link, NavLink, useHistory, useRouteMatch} from "react-router-dom";
import {setAuth} from "../util/auth";

export default function AsideMini(props) {
    const history = useHistory();
    let match = useRouteMatch();

    document.body.classList.add("kt-aside--minimize")

    function getNavLinkClass(path) {
        return match.path === path
            ? "kt-menu__item--active"
            : "";
    }

    function logout() {
        setAuth(false)
        history.push("/login")
    }

    return (
        <>

            <div id="asides">
                <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"/>
                </button>
                <div
                    className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
                    id="kt_aside"
                    style={{borderRightWidth: "4px", borderRightColor: "#5867DD"}}>
                    <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand"
                         style={{paddingLeft: "18px"}}>
                        <div className="kt-aside__brand-logo">
                            <Link to="/">
                                <img alt="Logo" src={"/images/01.png"} height="25" className="img-logo"/>
                            </Link>
                        </div>
                        <div className="kt-aside__brand-tools">
                            <button
                                className="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--active"
                                style={{paddingTop: "20px"}}
                                id="kt_aside_toggler"
                                onClick={props.handleButton}
                            >
                                   <span><svg xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                                              className="kt-svg-icon">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <polygon id="Shape" points="0 0 24 0 24 24 0 24"/>
                                        <path
                                            d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                            id="Path-94" fill="#000000" fillRule="nonzero"/>
                                        <path
                                            d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                            id="Path-94" fill="#000000" fillRule="nonzero" opacity="0.3"
                                            transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "/>
                                    </g>
                                </svg></span>
                            </button>
                        </div>
                    </div>

                    <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
                         id="kt_aside_menu_wrapper">
                        <div id="kt_aside_menu" className="kt-aside-menu kt-aside_dima" data-ktmenu-vertical="1"
                             data-ktmenu-scroll="1">
                            <ul className="kt-menu__nav kt-dima">
                                <li className={"kt-menu__item " + getNavLinkClass("/")}
                                    aria-haspopup="true">
                                    <NavLink exact to="/" className="kt-menu__link ">
                                        <i className="kt-menu__link-icon fa fa-desktop"/>
                                        <span className="kt-menu__link-text">Dashboard</span>
                                    </NavLink>
                                </li>

                                <li className={"kt-menu__item " + getNavLinkClass("/payment")}
                                    aria-haspopup="true">
                                    <NavLink to="/payment" className="kt-menu__link">
                                        <i className="kt-menu__link-icon fa fa-money-bill-alt"/>
                                        <span className="kt-menu__link-text">Payments</span>
                                    </NavLink>
                                </li>

                                <li className={"kt-menu__item " + getNavLinkClass("/monitoring")}
                                    aria-haspopup="true">
                                    <NavLink to="/monitoring" className="kt-menu__link">
                                        <i className="kt-menu__link-icon fa fa-eye"/>
                                        <span className="kt-menu__link-text">Monitoring</span>
                                    </NavLink>
                                </li>

                                <li className={"kt-menu__item " + getNavLinkClass("/terminal")}
                                    aria-haspopup="true">
                                    <NavLink to="/terminal" className="kt-menu__link">
                                        <i className="kt-menu__link-icon fa fa-sitemap"/>
                                        <span className="kt-menu__link-text">Terminals</span>
                                    </NavLink>
                                </li>

                                <li className={"kt-menu__item " + getNavLinkClass("/incass")}
                                    aria-haspopup="true">
                                    <NavLink to="/incass" className="kt-menu__link">
                                        <i className="kt-menu__link-icon fa fa-shopping-bag"/>
                                        <span className="kt-menu__link-text">Incass</span>
                                    </NavLink>
                                </li>


                                <li className={"kt-menu__item " + getNavLinkClass("/place")}
                                    aria-haspopup="true">
                                    <NavLink to="/place" className="kt-menu__link ">
                                        <i className="kt-menu__link-icon fa fa-map-marker-alt"/>
                                        <span className="kt-menu__link-text">Agent</span>
                                    </NavLink>
                                </li>

                                <li className="kt-menu__item " aria-haspopup="true">
                                    <Link to="/" className="kt-menu__link ">
                                        <i className="kt-menu__link-icon fa fa-image"/>
                                        <span className="kt-menu__link-text">Ads</span>
                                    </Link>
                                </li>

                                <li className={"kt-menu__item " + getNavLinkClass("/commissions")}
                                    aria-haspopup="true">
                                    <NavLink to="/commissions" className="kt-menu__link ">
                                        <i className="kt-menu__link-icon fa fa-coins"/>
                                        <span className="kt-menu__link-text">Commission</span>
                                    </NavLink>
                                </li>

                                <li className={"kt-menu__item " + getNavLinkClass("/report")} aria-haspopup="true">
                                    <NavLink to="/report" className="kt-menu__link ">
                                        <i className="kt-menu__link-icon fa fa-chart-pie"/>
                                        <span className="kt-menu__link-text">Report</span>
                                    </NavLink>
                                </li>

                                <li className={"kt-menu__item " + getNavLinkClass("/SIMCard")}
                                    aria-haspopup="true">
                                    <NavLink to="/SIMCard" className="kt-menu__link ">
                                        <i className="kt-menu__link-icon fa fa-sim-card"/>
                                        <span className="kt-menu__link-text">SIM</span>
                                    </NavLink>
                                </li>

                                <li className={"kt-menu__item " + getNavLinkClass("/list")}
                                    aria-haspopup="true">
                                    <NavLink to="/list" className="kt-menu__link ">
                                        <i className="kt-menu__link-icon fa fa-minus-circle"/>
                                        <span className="kt-menu__link-text">List</span>
                                    </NavLink>
                                </li>

                                <li className={"kt-menu__item " + getNavLinkClass("/settings")}
                                    aria-haspopup="true">
                                    <NavLink to="/settings" className="kt-menu__link ">
                                        <i className="kt-menu__link-icon fa fa-cog"/>
                                        <span className="kt-menu__link-text">Settings</span>
                                    </NavLink>
                                </li>
                                <li className={"kt-menu__item " + getNavLinkClass("/dictionaries")}
                                    aria-haspopup="true">
                                    <NavLink to="/dictionaries" className="kt-menu__link ">
                                        <i className="kt-menu__link-icon fa fas fa-book"/>
                                        <span className="kt-menu__link-text">Dictionaries</span>
                                    </NavLink>
                                </li>
                                <li className={"kt-menu__item " + getNavLinkClass("/commands")}
                                    aria-haspopup="true">
                                    <NavLink to="/commands" className="kt-menu__link ">
                                        <i className="kt-menu__link-icon fa fa-terminal"/>
                                        <span className="kt-menu__link-text">Commands</span>
                                    </NavLink>
                                </li>
                                <li className={"kt-menu__item " + getNavLinkClass("/user")}
                                    aria-haspopup="true">
                                    <NavLink to="/user" className="kt-menu__link ">
                                        <i className="kt-menu__link-icon fa fa-user-alt"/>
                                        <span className="kt-menu__link-text">User</span>
                                    </NavLink>
                                </li>
                                <li className="kt-menu__item"
                                    aria-haspopup="true">
                                    <div className="kt-menu__link ">
                                        <i onClick={logout} className="kt-menu__link-icon fa fa-power-off"/>
                                        <span
                                            className="kt-menu__link-text">{localStorage.getItem("user")}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

