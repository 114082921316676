import React from "react";
import Aside from "../aside/Aside";
import {Switch} from "react-router-dom";
import RoleRoute from "../../routers/RoleRoute";
import NewSimCardForm from "./NewSimСardForm";
import SimCardForm from "./SimCardForm";
import SimCardList from "./SimCardList";

export default function SIMRoute( {match} ) {
    return (
        <>
            <Aside/>
            <Switch>
                <RoleRoute
                    path={`${match.path}/new`}
                    component={NewSimCardForm}
                />
                <RoleRoute
                    path={`${match.path}/:id`}
                    component={SimCardForm}
                />
                <RoleRoute
                    path={`${match.path}/`}
                    component={SimCardList}
                />
            </Switch>
        </>
    )
}