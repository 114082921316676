import React from "react";
import CurrentStatistic from "./currentStatistic/CurrentStatistic";
// import PaymentStatistic from "./PaymentStatistic/PaymentStatistic";
import "./Dashboard.css";

export default function DashboardIncassator() {

    document.title = 'Dashboard - mitra processing';

    return (
        <div className="mitra_wrapper">
            <div className="mitra_row-dashboard">
                {/*<div className="mitra_item-dashboard"><PaymentStatistic/></div>*/}
                <div className="mitra_item-dashboard"><CurrentStatistic/></div>
            </div>
        </div>
    );
}

