import React from "react";
import Aside from "../aside/Aside";
import {Route, Switch} from "react-router-dom";
import RoleRoute from "../../routers/RoleRoute";
import PaymentList from "./PaymentList";
import PaymentNew from "./PaymentNew";
import PaymentFormId from "./PaymentFormId";
import {getRoles} from "../util/auth";
import NavIncass from "../aside/navIncass/NavIncass";
import PaymentChecking from "./paymentChecking/paymentChecking";
import PaymentCheckingForm from "./paymentCheckingForm/paymentChekingForm";

export default function PaymentRoute({match}) {

    return (

        <>
            {getRoles(["SA"]) || getRoles(["ADMIN"]) || getRoles(["MANAGER"]) ? <Aside/> : <NavIncass/>}
            <Switch>
                {getRoles(["SA"]) || getRoles(["ADMIN"]) || getRoles(["MANAGER"]) ?

                    (
                        <>
                        <Switch>

                            <RoleRoute
                                path={`${match.path}/new`}
                                component={PaymentNew}
                            />
                            <RoleRoute
                                path={`${match.path}/:id`}
                                component={PaymentFormId}
                            />
                            <RoleRoute
                                path={`${match.path}/`}
                                component={PaymentList}
                            />
                        </Switch>

                        </>
                    )
                    :
                    (
                        <>
                            <Route
                                path={`${match.path}/check`}
                                component={PaymentChecking}
                            />
                            <Route
                                path={`${match.path}/checking/:id`}
                                component={PaymentCheckingForm}
                            />
                        </>
                    )
                }
            </Switch>
        </>
    )
}

