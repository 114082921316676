import React from "react";
import './User.css'

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table className="kt-datatable__table">

            <thead className="kt-datatable__head" style={{borderBottom: "solid"}}>
            <tr className="kt-datatable__row">

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'username')}
                >
                    <span
                        style={{width: "200px"}}
                    >
                       UserName
                        {props.field === 'username' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'description')}
                >
                    <span
                        style={{width: "200px"}}
                    >
                       Description
                        {props.field === 'description' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    // onClick={props.onSort.bind(null, 'operator')}
                >
                    <span
                        style={{width: "200px"}}
                    >
                       Authorities
                        {props.field === 'operator' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>

                {/*<th className="kt-datatable__cell kt-datatable__cell--sort"*/}
                {/*    // onClick={props.onSort.bind(null, 'operator')}*/}
                {/*>*/}
                {/*    <span*/}
                {/*        style={{width: "200px"}}*/}
                {/*    >*/}
                {/*       Password change*/}
                {/*        {props.field === 'operator' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :*/}
                {/*            <i className="flaticon2-arrow-down"></i> : null}*/}
                {/*    </span>*/}
                {/*</th>*/}
            </tr>
            </thead>
        </table>
    </div>
)

