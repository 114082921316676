import React, {useEffect, useState} from 'react';
import 'select2';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Input from "../Input";
import moment from "moment";
import Button from "../Button";
import "./report.css";
import axios from "axios";
import {config} from "../util/constants";
import {getToken, setAuth} from "../util/auth";
import Select2 from "react-select2-wrapper";
import {useHistory} from "react-router-dom";

export default function Report() {
    const history = useHistory();
    const defaultParams = {
        startDate: moment().startOf('day').tz("Asia/Qatar"),
        endDate: moment().endOf('day').tz("Asia/Qatar"),
        initStartDate: moment().startOf('day'),
        initEndDate: moment().endOf('day'),
        reportType: null,
        terminals: [],
        city: [],
        hiddenPercent: false
    }

    const [params, setParams] = useState(defaultParams);
    const [defaultValue, setDefaultValue] = useState(true);

    useEffect(
        () => {
            document.title = 'Report - mitra processing';
        },
    );

    const handelChangeSelect = (event) => {
        let arr = getOption(event)
        setParams(params => ({...params, [event.target.name]: arr}));
    }
    const handelChangeSelectSingle = (event) => {
        setParams(params => ({...params, [event.target.name]: event.target.value}));
        console.log("DATA", params)

    }

    function getOption(event) {
        let options = event.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        return value
    }

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
            sendReport();
        }
    }
    const sendReport = () => {
        let data = {
            reportType: params.reportType,
            startDate: params.startDate.format('x'),
            finalDate: params.endDate.format('x'),
            terminals: params.terminals,
            city: params.city,
            hiddenPercent: false
        }
        console.log("DATA", data)
        let fileName = (data.reportType ? data.reportType.replace(' ', '_').toLowerCase() : null) + "_report_" + params.startDate.format("DD.MM.YYYY_HHmm") + "_to_" + params.endDate.format("DD.MM.YYYY_HHmm")
        console.log("fileName",fileName)

        axios.post("report", data, {
            baseURL: config.url.API_URL,
            headers: {
                Authorization: getToken(),
            }, responseType: 'blob',
        }).then(res => {
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName + '.pdf'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(e => {
            console.log(e)
            if (e.request.status === 401) {
                setAuth(false)
                if (history != null) {
                    history.push("/login");
                }
            }
        });

    }
    const handleChangeDate = (event, picker) => {
        setParams(params => ({...params, startDate: picker.startDate}));
        setParams(params => ({...params, endDate: picker.endDate}));
        setDefaultValue(false);
    }
    const Clear = (e) => {
        setParams(defaultParams)
        setDefaultValue(true);

    }
    return (
        <div>
            {/*FORM SEARC*/}
            <div className="kt-portlet kt-portlet--mobile">
                <form className="kt-form kt-form--label-right" onSubmit={handleSubmit}>
                    <div className="kt-portlet__body dima_form" style={{backgroundColor: "#9899ac"}}>
                        <div className="form-group row form-group-marginless">
                            <div className="col-lg-4 col-md-9 col-sm-12">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="terminals"
                                    name="terminals"
                                    data={JSON.parse(localStorage.getItem("terminalDict"))}
                                    value={params.terminals}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "Terminal"}}
                                />
                            </div>
                            <div className="col-lg-4 kt-align-left">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="city"
                                    name="city"
                                    data={JSON.parse(localStorage.getItem("cityDict"))}
                                    value={params.city}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "City"}}
                                />
                            </div>
                            <div className="col-lg-4 kt-align-left">
                                <Button
                                    type="button"
                                    // className="mitra-button-submit"
                                    name="Clear"
                                    id="clear"
                                    onClick={Clear}
                                />
                            </div>

                        </div>

                        <div className="form-group row form-group-marginless kt-margin-t-20">
                            <div className="col-lg-4 kt-align-left">
                                <Select2
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)",fontWeight: "600", fontSize: "1rem", color :"#6c7293"}}
                                    id="reportType"
                                    name="reportType"
                                    data={JSON.parse(localStorage.getItem("reportType"))}
                                    value={params.reportType}
                                    onChange={handelChangeSelectSingle}
                                    options={{placeholder: "Report"}}
                                />
                            </div>

                            <div className="col-lg-4">
                                <DateRangePicker
                                    // locale={}
                                    // defaultValue={"Asia/Qatar"}
                                    startDate={params.initStartDate}
                                    endDate={params.initEndDate}
                                    linkedCalendars={false}
                                    timePicker24Hour={true}
                                    timePicker={true}
                                    opens={"right"}
                                    applyClass={"btn-primary"}
                                    onApply={handleChangeDate}
                                    timePickerIncrement={1}
                                    containerStyles={{
                                        width: "100%",
                                        backgroundColor: "#d6d7ea",
                                        borderRadius: "30px"
                                    }}
                                >
                                    <Input
                                        type="text"
                                        readOnly
                                        placeholder={"Date"}
                                        value={defaultValue ? "" : params.startDate.format("DD.MM.YYYY HH:mm") + ' to ' + params.endDate.format("DD.MM.YYYY HH:mm")}
                                    />
                                </DateRangePicker>
                            </div>
                                <div className="col-lg-4 kt-align-left">
                                    <Button
                                        type="submit"
                                        className="mitra-button-submit"
                                        name="Generate"
                                        id="пenerate"
                                    />
                                </div>
                            <div className="col-lg-4 kt-align-left">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
