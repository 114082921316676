import React from "react";
import "../Input/Input.css"

function Input (props){
    let className = "mitra-input";
    if(props.className !== undefined && props.disabled !== true){
        className+= " " + props.className
    }
    return(
        <input
            step={props.step ? props.step : "1"}
            min={props.min}
            autocomplete={props.autocomplete}
            readOnly={props.readOnly}
            id={props.id}
            type={props.type}
            disabled={props.disabled ? props.disabled: false}
            className={className}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange ? props.onChange : null}
        />
    )
}
export default Input;