import React from "react";
import {Redirect, Route} from "react-router-dom";
import {isAuth} from "../components/util/auth";


export default function  PrivateRoute({component: Component, ...rest}) {
    return (
        <Route {...rest} render={props => (
            isAuth() ?
                <Component {...props} />
                : <Redirect to="/login" />
        )} />
    );
};