import React, {useEffect, useState} from "react";
import "./tableReport.css";
import Input from "../Input";
import {sendGETnoParams, sendPOSTdict} from "../util/sendRequest";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Select2 from "react-select2-wrapper";
import Switch from "react-switch";

export default function TableRow(props) {
    const [status, setStatus] = useState(null)
    const [dataT, setDataT] = useState(props.entity)
    const [data, setData] = useState(props.entity)
    const [modalShow, setModalShow] = useState(false)
    // const [newRow, setNewRow] = useState(false)
    const [edit, setEdit] = useState(false);
    useEffect(() => {
        setData(props.entity)
    }, [props.entity])
    const Edit = () => {
        setEdit(!edit)
        sendGETnoParams("d/" + props.url + "/" + props.entity.id, setData)
    }
    const handelChange = (event) => {
        setData({...data, [event.target.id]: event.target.value});
    }
    const handelChangeSelect = (event) => {
        setData({...data, [event.target.name]: event.target.value});
    }
    const handleSwithch = (checked) => {
        setData({...data, enable: checked});
    }
    const Send = () => {
        sendPOSTdict("d/" + props.url, data, setStatus, null)
        setModalShow(!modalShow)
    }
    const modalClose = () => {
        setModalShow(!modalShow)
        setEdit(!edit)
        setDataT(status.data)
    }
    return (
        <>
            <MyVerticallyCenteredModal
                h4={status ? status.request.status : ""}
                text={status ? "Changes saved" : ""}
                show={modalShow}
                onHide={modalClose}
            />

            {props.url === 'city' ?
                (
                        (
                            <tr>
                                <td className="cell_report">{dataT.id}</td>
                                <td className="cell_report">{edit ? (
                                    <Input onChange={handelChange} value={data.name} name="name"
                                           id="name"/>) : (dataT.name)}</td>
                                <td className="cell_report">{edit ? (
                                    <Input onChange={handelChange} value={data.queue} name="queue"
                                           id="queue"/>) : (dataT.queue)}</td>
                                <td className="cell_report_edit">{edit ? (
                                    <td><i onClick={Edit} className="fa fa-times" style={{marginRight: "15px"}}/><i
                                        onClick={Send}
                                        className="fa fa-check"/>
                                    </td>) : <i onClick={Edit} className="fa fa-edit"/>}</td>
                            </tr>
                        )
                )
                :
                (
                    props.url === 'operatorDenomination' ?
                        (
                            <tr>

                                <td className="cell_report">{props.entity.id}</td>
                                <td className="cell_report">{edit ? (
                                    <Input onChange={handelChange} value={data.denomination} name="denomination"
                                           id="denomination"/>) : (data.denomination)}</td>
                                <td className="cell_report">{edit ? (
                                    <Input onChange={handelChange} value={data.uid} name="uid"
                                           id="uid"/>) : (data.uid)}</td>
                                <td className="cell_report">{edit ? (
                                    <Input onChange={handelChange} value={data.operator} name="operator"
                                           id="operator"/>) : (data.operator)}</td>
                                <td className="cell_report">{edit ? (
                                    <Input onChange={handelChange} value={data.productId} name="productId"
                                           id="productId"/>) : (data.productId)}</td>
                                <td className="cell_report">{edit ? (
                                    <Input onChange={handelChange} value={data.productName} name="productName"
                                           id="productName"/>) : (data.productName)}</td>
                                <td className="cell_report">{edit ? (
                                    <Input onChange={handelChange} value={data.localBuffer} name="localBuffer"
                                           id="localBuffer"/>) : (data.localBuffer)}</td>
                                <td className="cell_report_edit">{edit ? (
                                    <td><i onClick={Edit} className="fa fa-times" style={{marginRight: "15px"}}/><i
                                        onClick={Send}
                                        className="fa fa-check"/>
                                    </td>) : <i onClick={Edit} className="fa fa-edit"/>}</td>
                            </tr>
                        )
                        :
                        (
                            props.url === 'operatorName' ?
                                (
                                    <tr>

                                        <td className="cell_report">{props.entity.id}</td>
                                        <td className="cell_report">{edit ? (
                                            <Input onChange={handelChange} value={data.original} name="original"
                                                   id="original"/>) : (data.original)}</td>
                                        <td className="cell_report">{edit ? (
                                            <Input onChange={handelChange} value={data.short} name="short"
                                                   id="short"/>) : (data.short)}</td>
                                        <td className="cell_report">{edit ? (
                                            <Input onChange={handelChange} value={data.country} name="country"
                                                   id="country"/>) : (data.country)}</td>
                                        <td className="cell_report_edit">{edit ? (
                                            <td><i onClick={Edit} className="fa fa-times"
                                                   style={{marginRight: "15px"}}/><i
                                                onClick={Send}
                                                className="fa fa-check"/>
                                            </td>) : <i onClick={Edit} className="fa fa-edit"/>}</td>
                                    </tr>
                                )
                                :
                                (
                                    props.url === 'country' ?
                                        (
                                            <tr>

                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.id} name="id"
                                                           id="id"/>) : (data.id)}</td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.name} name="name"
                                                           id="name"/>) : (data.name)}</td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.shortName}
                                                           name="shortName"
                                                           id="shortName"/>) : (data.shortName)}</td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.code2} name="code2"
                                                           id="code2"/>) : (data.code2)}</td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.code3} name="code3"
                                                           id="code3"/>) : (data.code3)}</td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.codeNumeric}
                                                           name="codeNumeric"
                                                           id="codeNumeric"/>) : (data.codeNumeric)}</td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.phonePrefix}
                                                           name="phonePrefix"
                                                           id="phonePrefix"/>) : (data.phonePrefix)}</td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.phoneMask}
                                                           name="phoneMask"
                                                           id="phoneMask"/>) : (data.phoneMask)}</td>
                                                <td className="cell_report">
                                                        <Switch
                                                            disabled={edit ? false: true}
                                                            checked={data.enable}
                                                            onChange={handleSwithch}
                                                            onColor="#acb3ee"
                                                            onHandleColor="#5867dd"
                                                            handleDiameter={30}
                                                            uncheckedIcon={true}
                                                            checkedIcon={true}
                                                            boxShadow="true"
                                                            activeBoxShadow="true"
                                                            offHandleColor="#e1e2e4"
                                                            height={20}
                                                            width={48}
                                                            className="kt-switch"
                                                            name="enable"
                                                        />
                                                </td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.queue} name="queue"
                                                           id="queue"/>) : (data.queue)}</td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.minAccLen}
                                                           name="minAccLen"
                                                           id="minAccLen"/>) : (data.minAccLen)}</td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.maxAccLen}
                                                           name="maxAccLen"
                                                           id="maxAccLen"/>) : (data.maxAccLen)}</td>
                                                <td className="cell_report">
                                                    <Select2
                                                        disabled={edit ? false: true}
                                                        // className="mitra-select"
                                                        style={{width:"100%"}}
                                                        // id="currency"
                                                        name="currency"
                                                        data={JSON.parse(localStorage.getItem("currencyDict"))}
                                                        value={data.currency}
                                                        onChange={handelChangeSelect}
                                                    />
                                                         </td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.minCommissionPercent}
                                                           name="minCommissionPercent"
                                                           id="minCommissionPercent"/>) : (data.minCommissionPercent)}</td>
                                                <td className="cell_report">{edit ? (
                                                    <Input onChange={handelChange} value={data.maxCommissionPercent}
                                                           name="maxCommissionPercent"
                                                           id="minCommissionPercent"/>) : (data.maxCommissionPercent)}</td>
                                                <td className="cell_report_edit">{edit ? (
                                                    <td><i onClick={Edit} className="fa fa-times"
                                                           style={{marginRight: "15px"}}/><i
                                                        onClick={Send}
                                                        className="fa fa-check"/>
                                                    </td>) : <i onClick={Edit} className="fa fa-edit"/>}</td>
                                            </tr>
                                        ) : "Yep!!!"
                                )
                        )
                )
            }
        </>
    )
}