import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class ModalChangePassword extends React.Component {

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Change password for user: &nbsp; {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/*<h4>Enter a new password</h4>*/}
                            <input type="password"
                                   value={this.props.value}
                                   className="dima_input form-control"
                                   placeholder=""
                                   id=""
                                   onChange={this.props.changePassword}
                            />

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{width: "95px", height: "calc(1.5em + 1em + 1px)"}}
                        onClick={this.props.onHide}
                    >Close</Button>
                    <Button
                        className="btn btn-success btn-icon-sm btm-sm"
                        style={{width: "95px", height: "calc(1.5em + 1em + 1px)"}}
                        onClick={this.props.sendChangePassword}
                    >Submit</Button>
                </Modal.Footer>
            </Modal>
        );
    }


}
export default ModalChangePassword;