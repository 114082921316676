import React from 'react';
import './Table.css'
// import ReactPaginate from "react-paginate";

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">

        <table className="kt-datatable__table">

            {/*{props.not === 0 ? <h1 className='noRecordFound'>No record found</h1> :*/}
                <tbody className="kt-datatable__body" style={{display: "block", fontFamily: "Heebo, sans-serif"}}>
                {/*{props.commissionList.map(item => (*/}
                    <tr className="kt-datatable__row"
                        // key={item.id}

                    >

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "90px"}}
                         >1
                             {/*{item.id}*/}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "90px"}}
                         >
                             {/*{item.id}*/} Plan test
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "300px"}}
                         >
                             {/*{item.id}*/}0% Test
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "90px"}}
                         >
                             {/*{item.id}*/}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "100px"}}
                         >100
                             {/*{item.id}*/}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "110px"}}
                         >1.0
                             {/*{item.id}*/}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "110px"}}
                         >0.1
                             {/*{item.id}*/}
                         </span>
                        </td>
                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "110px"}}
                         >0.8
                             {/*{item.id}*/}
                         </span>
                        </td>


                    </tr>
                 {/*))}*/}

                {/*<div className="d-flex justify-content-center">*/}
                {/*    <ReactPaginate*/}
                {/*        previousLabel={<i className="fas fa-angle-double-left"></i>}*/}
                {/*        nextLabel={<i className=" fas fa-angle-double-right"></i>}*/}
                {/*        breakLabel={'...'}*/}
                {/*        breakClassName={'break-me'}*/}
                {/*        pageCount={props.pageCount}*/}
                {/*        marginPagesDisplayed={2}*/}
                {/*        pageRangeDisplayed={5}*/}
                {/*        onPageChange={props.onPageChange}*/}
                {/*        containerClassName={'pagination'}*/}
                {/*        activeClassName={'active'}*/}
                {/*        pageClassName={'page-item'}*/}
                {/*        pageLinkClassName={'page-link'}*/}
                {/*        previousClassName={'page-item'}*/}
                {/*        nextClassName={'page-item'}*/}
                {/*        previousLinkClassName={'page-item'}*/}
                {/*        nextLinkClassName={'page-item'}*/}
                {/*        forcePage={props.forcePage}*/}
                {/*    />*/}
                {/*</div>*/}

                </tbody>
            {/*}*/}

        </table>

    </div>
)
