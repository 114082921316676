import React from "react";
import Aside from "../aside/Aside";
import {Route, Switch} from "react-router-dom";
import RoleRoute from "../../routers/RoleRoute";
import IncassList from "./IncassList";
import {getRoles} from "../util/auth";
import NavIncass from "../aside/navIncass/NavIncass";
import IncassatorRouteList from "./IcassatorRoleList";
import "./IncassCellTable.css"

export default function IncassatorRoute( {match} ) {
    return (
        <>
            {getRoles(["SA"]) || getRoles(["ADMIN"]) || getRoles(["MANAGER"]) ? <Aside/> : <NavIncass/>}
            <Switch>
                {getRoles(["SA"]) || getRoles(["ADMIN"]) || getRoles(["MANAGER"]) ?
                    (
                        <>
                            <RoleRoute
                                path={`${match.path}/`}
                                component={IncassList}
                            />
                        </>
                    )
                    :
                    (
                        <>
                            <Route
                                path={`${match.path}/`}
                                component={IncassatorRouteList}
                            />
                        </>

                    )
                }
            </Switch>
        </>
    );
}