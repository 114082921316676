import React from "react";
import Aside from "../aside/Aside";
import {Route, Switch} from "react-router-dom";
import RoleRoute from "../../routers/RoleRoute";
import Dashboard from "./Dashboard";
import {getRoles} from "../util/auth";
import DashboardIncassator from "./DashboardIncassator";
import NavIncass from "../aside/navIncass/NavIncass";


export default function DashboardRoute({match}) {

    return (
        <>
            {getRoles(["SA"]) || getRoles(["ADMIN"]) || getRoles(["MANAGER"]) ? <Aside/> : <NavIncass/>}
            <Switch>
                {getRoles(["SA"]) || getRoles(["ADMIN"]) || getRoles(["MANAGER"]) ?
                    <RoleRoute
                        path={`${match.path}/`}
                        component={Dashboard}
                    />
                    :
                    <Route
                        path={`${match.path}/`}
                        component={DashboardIncassator}
                    />
                }
            </Switch>
        </>
    )
}