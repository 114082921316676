import React from 'react'
import axios from "axios";
import {config} from "../util/constants";
import CommadsTable from "./CommadsTable";
import CommandsTableHead from "./CommandsTableHead";
import {Link} from "react-router-dom";
import LoadingPage from "../LoadingPage";
import Input from "../Input";
import Select2 from "react-select2-wrapper";
import Button from "../Button";

class Commands extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bearer: localStorage.getItem("authToken"),
            command: [],


            commandTypeDict: [],
            terminalDict: [],
            doneDict: [{"true": true}, {"false": false}],

            terminal: [],
            search: '',
            type: [],
            done: false,
            page: 0,
            pages: 0,
            sort: 'desc',
            field: 'id',

            errorStatus: '',
            statusText: '',
            errorMessage: '',
            modalShow: false,
            isLoaded: null,

            isLoadedTable: false,
        };
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.handleChangeTerminal = this.handleChangeTerminal.bind(this);
        this.handleChangeCommandTypeDict = this.handleChangeCommandTypeDict.bind(this);
        // this.handleChangeDone = this.handleChangeDone.bind(this);
    }

    componentDidMount() {
        document.title ='Commands - mitra processing';
        axios.get(config.url.API_URL + "/dictionary/commandTypeDict", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    commandTypeDict: result,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        });

        axios.get(config.url.API_URL + "/dictionary/terminalDict", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    terminalDict: result,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        });

        // axios.get(config.url.API_URL + "/command", {
        //     headers: {
        //         'Authorization': 'Bearer ' + this.state.bearer
        //     }
        // }).then(res => {
        //         const result = res.data;
        //         this.setState({
        //             command: result,
        //             page: result.meta.page,
        //             isLoaded: this.state.isLoaded + 1,
        //             isLoadedTable: true,
        //         });
        //     }
        // ).catch(e => {
        //     if (e.request.status === 401) {
        //         localStorage.setItem("auth", this.props.setAuth);
        //         this.props.setAuth(false);
        //     } else {
        //         let response = e.response;
        //         let statusText = response.data.status;
        //         let errorMessage = response.data.message;
        //         let errorStatus = response.status;
        //         this.setState({
        //             modalShow: true,
        //             errorStatus,
        //             statusText,
        //             errorMessage,
        //             isLoaded: this.state.isLoaded + 1,
        //         });
        //     }
        // });

    }

    handleClearClick = () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                terminal: [],
                search: '',
                type: [],
                done: false,
            });
            this.sendCommandRequest()
        }, 1);
    };

    valueForDict(array, index) {
        if ((array === null || array === undefined) && (index === null || index === undefined)) {
            return 'N/A'
        } else {
            let value = array.find(item => item.id === index);
            if (value === undefined) {
                return 'N/A'
            } else {
                return value.text;
            }
        }
    };

    getOption(event) {
        let options = event.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        return value
    }

    handleChangeTerminal(e) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                terminal: this.getOption(e),
                page: 0,
                isLoadedTable: false,
            });
            this.sendCommandRequest()
        }, 1);
    }

    // getOption(event) {
    //     let options = event.target.options;
    //     let value = [];
    //     for (let i = 0, l = options.length; i < l; i++) {
    //         if (options[i].selected) {
    //             value.push(options[i].value);
    //         }
    //     }
    //     return value
    // }
    //
    // handleChangeDone(e) {
    //     this.setState({
    //         done: this.getOption(e),
    //         page: 0,
    //         isLoadedTable: false,
    //     })
    //     this.sendCommandRequest()
    // };

    handleChangeCommandTypeDict(e) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

            this.setState({
                type: this.getOption(e),
                page: 0,
                isLoadedTable: false,
            });
            this.sendCommandRequest()
        }, 1);
    }

    sendCommandRequest() {
        let request = config.url.API_URL + "/command" +
            "?search=" + this.state.search +
            "&page=" + this.state.page +
            "&type=" + this.state.type +
            "&done=" + this.state.done +
            "&terminal=" + this.state.terminal +
            "&sort=" + this.state.sort +
            "&field=" + this.state.field;

        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    page: result.meta.page,
                    pages: result.meta.pages,
                    command: result.data,
                    isLoadedTable: true
                });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    }

    handleClick = sortField => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

            const sortDefault = this.state.sort === 'asc' ? 'desc' : 'asc';
            this.setState({
                sort: sortDefault,
                field: sortField,
                page: 0,
                isLoadedTable: false,
            });
            this.sendCommandRequest()
        }, 1);
    };

    pageHandler = ({selected}) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({page: selected});
            this.sendCommandRequest()
        }, 1)
    };

    handleSearch(event) {
        let search = event.target.value;
        this.setState({
            search,
            // page: 0
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.sendCommandRequest()
        }, 1)
    }

    render() {
        return (
            <div>
                {this.state.isLoaded < 1 ? <LoadingPage/> :
                    <div className="kt-portlet kt-portlet--mobile">
                        <form className="kt-form kt-form--label-right">
                            <div className="kt-portlet__body dima_form" style={{backgroundColor: "#9899ac"}}>
                                {/*<div className="dima_heder_list">Payment</div>*/}
                                <div className="form-group row form-group-marginless">
                                    <div className="col-lg-4 col-md-9 col-sm-12 dima_pravka">
                                        <Input
                                            type="text"
                                            value={this.state.search}
                                            placeholder={'Search'}
                                            id="generalSearch"
                                            onChange={this.handleSearch}
                                        />
                                    </div>
                                    <div className="col-lg-4 kt-align-left">
                                        <Select2
                                            multiple
                                            className="form-control kt-select2"
                                            style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                            id="terminal"
                                            data={this.state.terminalDict}
                                            value={this.state.terminal}
                                            onChange={this.handleChangeTerminal}
                                            options={{placeholder: "Terminal"}}
                                        />
                                    </div>

                                    <div className="col-lg-4 kt-align-left">
                                        <button type="button"
                                                className="btn btn-primary btn-icon-sm btm-sm"
                                                style={{width: "95px", height: "calc(1.5em + 1em + 1px)"}}
                                                onClick={this.handleClearClick}
                                        >
                                            {/*<i className="flaticon2-refresh"/> */}
                                            Clear
                                        </button>
                                    </div>
                                </div>

                                <div className="form-group row form-group-marginless kt-margin-t-20">
                                    <div className="col-lg-4 kt-align-left">
                                        <Select2
                                            multiple
                                            className="form-control kt-select2"
                                            style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                            id="typeSelect"
                                            data={this.state.commandTypeDict}
                                            value={this.state.type}
                                            onChange={this.handleChangeCommandTypeDict}
                                            options={{placeholder: "Type"}}
                                        />
                                    </div>

                                    {/*<div className="switch_filter">*/}
                                    {/*    <Switch*/}
                                    {/*        checked={this.state.done}*/}
                                    {/*        onChange={this.handleChangeDone}*/}
                                    {/*        onColor="#acb3ee"*/}
                                    {/*        onHandleColor="#5867dd"*/}
                                    {/*        handleDiameter={30}*/}
                                    {/*        uncheckedIcon={true}*/}
                                    {/*        checkedIcon={true}*/}
                                    {/*        boxShadow="true"*/}
                                    {/*        activeBoxShadow="true"*/}
                                    {/*        offHandleColor="#e1e2e4"*/}
                                    {/*        height={20}*/}
                                    {/*        width={48}*/}
                                    {/*        className="kt-switch"*/}
                                    {/*        id="kt-switch"*/}
                                    {/*    />*/}
                                    {/*    <div className="label_inline_switch">*/}
                                    {/*        <label className="label_form_filter"*/}
                                    {/*            style={{fontWeight: "500"}}*/}
                                    {/*        >Done</label>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-lg-4 kt-align-left">
                                    </div>
                                    <div className="col-lg-4 kt-align-left">
                                        <Link to="/commands/new"
                                              target="_blank"
                                        >
                                            <Button
                                                type="button"
                                                className="mitra-button-submit"
                                                name="New"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <CommandsTableHead
                            onSort={this.handleClick}
                            field={this.state.field}
                            sort={this.state.sort}
                        />

                        {this.state.isLoadedTable === false ? <LoadingPage/> :
                            <CommadsTable
                                valueForDict={this.valueForDict}
                                commandTypeDict={this.state.commandTypeDict}
                                commmandsList={this.state.command}
                                not={this.state.pages}
                                forcePage={this.state.page}
                                onPageChange={this.pageHandler}
                                pageCount={this.state.pages}
                            />
                        }
                    </div>


                }
            </div>

        )
        // }

    }
}

export default Commands