import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {sendGET} from "../../util/sendRequest";
import "./style.css";

export default function CurrentStatistic() {

    const history = useHistory();
    const [entity, setEntity] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sendGET("/dashboard/currentStatistic/", null, setEntity, history, setLoading)
    }, [entity, history]);

    if (loading) {
        return (<div/>);
    } else {
        return (
            <>
                <table className="mitra-table-statistic">
                    <thead>
                    <tr>
                        <th colSpan={2}>Current</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Online</td>
                        <td className="mitra-table-today-statistic">{entity.online}</td>
                    </tr>
                    <tr>
                        <td>Offline</td>
                        <td className="mitra-table-today-statistic">{entity.offline}</td>
                    </tr>
                    <tr>
                        <td>Failed</td>
                        <td className="mitra-table-today-statistic">{entity.failed}</td>
                    </tr>
                    <tr>
                        <td>Incassed</td>
                        <td className="mitra-table-today-statistic">{entity.incassed}</td>
                    </tr>
                    <tr>
                        <td>Payments queue</td>
                        <td className="mitra-table-today-statistic">{entity.paymentsQueue}</td>
                    </tr>
                    <tr>
                        <td>Cash inside</td>
                        <td className="mitra-table-today-statistic">{entity.cashInTerminals + '\u00A0\u00A0\u00A0QAR'}</td>
                    </tr>
                    <tr>
                        <td>Today incass</td>
                        <td className="mitra-table-today-statistic">{entity.todayIncass + '\u00A0\u00A0\u00A0QAR'}</td>
                    </tr>
                    </tbody>
                </table>
            </>
        );
    }
}