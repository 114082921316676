import React from "react";
import Switch from "react-switch";
import {config} from "../util/constants";
import axios from "axios";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";

class InternationalSwitch extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            enable: props.enable,
            bearer: localStorage.getItem("authToken"),
            desk: '',
            errorStatus: '',
            statusText:'',
            errorMessage:'',
            modalShow:'',
        };
        this.handleChangeActive = this.handleChangeActive.bind(this);
    }

    handleChangeActive = (event)=> {
        if (this.state.enable === true) {
            this.setState({
                enable: false,
            })
        } else {
            this.setState({
                enable: true,
            })
        }
        this.sendInternational(this.props.id, this.state.enable);
    };

    sendInternational(id, enable){
        const request = config.url.API_URL + "/settings/countryEnabledSwitch" +
            "?countryId=" + id +
            "&enable=" + enable;

        axios.get(request,{
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            let result = res.data;
            let statusText = result.desc;
            let errorStatus = res.status;
            this.setState({
                statusText,
                errorStatus,
                modalShow: true,
            })
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;
                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    let errorStatus = response.status;
                    if (this.state.enable === true) {
                        this.setState({
                            enable: false,
                        })
                    } else {
                        this.setState({
                            enable: true,
                        })
                    }
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,

                    });
                }
            });
    }

    render(){
        let modalClose = () => this.setState({modalShow: false});
        return(
            <div>
                <MyVerticallyCenteredModal
                    heder={this.state.errorStatus}
                    h4={this.state.statusText}
                    text={this.state.errorMessage}
                    show={this.state.modalShow}
                    onHide={modalClose}
                />
                <Switch
                checked={this.state.enable}
                onChange={this.handleChangeActive}
                onColor="#acb3ee"
                onHandleColor="#5867dd"
                handleDiameter={30}
                uncheckedIcon={true}
                checkedIcon={true}
                boxShadow="true"
                activeBoxShadow="true"
                offHandleColor="#e1e2e4"
                height={20}
                width={48}
                className="kt-switch"
                id="kt-switch"
            /></div>
        )
    }
}
export default InternationalSwitch;