import React from 'react';
import 'select2';
import ListTable from "./ListTable";
import LoadingPage from "../LoadingPage";
import TableHead from "./ListTableHead";
import {Link} from "react-router-dom";
import {config} from "../util/constants";
import axios from "axios";
import Input from "../Input";
import Button from "../Button";
import Select2 from "react-select2-wrapper";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import {getToken} from "../util/auth";


class List extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bearer: localStorage.getItem("authToken"),

            error: null,
            isLoaded: false,
            isLoadedTable: false,

            field: 'id',
            sort: 'desc',

            list: [],

            searchValue: '',

            typeDict: [],
            typeValue: [],

            modalShow: false,
            statusText: '',
            errorMessage: '',

            pages: 0,
            page: 0
        };
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.delete = this.delete.bind(this)
        this.change = this.change.bind(this)

    }


    handleClearClick = (e) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                searchValue: '',
                typeValue: [],
                page: 0
            });
            this.sendListRequest()
        }, 1);
    };

    handleSearch = (e) => {
        const value = e.target.value;
        this.setState({
            searchValue: value,
            page: 0
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

            this.sendListRequest()
        }, 1);
    };
    pageHandler = ({selected}) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({page: selected})
            this.sendListRequest()
        }, 1)
    };

    handleChangeType(e) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

            this.setState({
                typeValue: this.getOption(e),
                page: 0,
                isLoadedTable: false,
            });
            this.sendListRequest()
        }, 1);
    }

    handleClick = sortField => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

            const sortDefaul = this.state.sort === 'asc' ? 'desc' : 'asc';
            this.setState({
                sort: sortDefaul,
                field: sortField,
                page: 0,
                isLoadedTable: false,
            });
            this.sendListRequest()
        }, 1);
    };

    sendListRequest() {

        const request = config.url.API_URL + "/list" +
            "?search=" + this.state.searchValue +
            "&typeID=" + this.state.typeValue +
            "&field=" + this.state.field +
            "&sort=" + this.state.sort +
            "&page=" + this.state.page;

        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            console.log(result)
            this.setState({
                list: result.data,
                pages: result.meta.pages,
                page: result.meta.page,
                isLoadedTable: true
            });
        })
            .catch(e => {
                localStorage.setItem("auth", false);
                this.props.setAuth(false);

            });
    }

    getOption(event) {
        let options = event.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        return value
    }


    componentDidMount() {
        document.title = 'List - mitra processing';

        axios.get(config.url.API_URL + '/dictionary/listTypeDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                typeDict: result
            });
        })
            .catch(e => {
                localStorage.setItem("auth", false);
                this.props.setAuth(false);

            });

    }

    delete(id, account) {
        axios.delete('/list/' + id, {
            baseURL: config.url.API_URL,
            headers: {
                Authorization: getToken()
                // 'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            },
        }).then(res => {
            console.log(res)
            const result = res.data;
            this.setState({
                modalShow: true,
                statusText: result.desc,
                errorMessage: 'Delete account ' + account,
            });
        })
        this.sendListRequest()
    }

    change(id, account, type) {
        axios.get('/list/' + id + '/change', {
            baseURL: config.url.API_URL,
            headers: {
                Authorization: getToken()
                // 'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            },
        }).then(res => {
            console.log(res)
            const result = res.data;
            this.setState({
                modalShow: true,
                statusText: result.desc,
                errorMessage: 'Change account type',
            });
        })
        axios.get( config.url.API_URL + "/list" , {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            console.log(result)
            this.setState({
                list: result.data,
                pages: result.meta.pages,
                page: result.meta.page,
                isLoadedTable: true
            });
        })
            .catch(e => {
                localStorage.setItem("auth", false);
                this.props.setAuth(false);

            });
    }


    render() {
        const {typeDict, typeValue, modalShow, statusText, errorMessage} = this.state
        let modalClose = () => this.setState({modalShow: false});

        return (
            <div>
                <MyVerticallyCenteredModal
                    h4={statusText}
                    text={errorMessage}
                    show={modalShow}
                    onHide={modalClose}
                />
                <div className="kt-portlet kt-portlet--mobile">
                    <form className="kt-form kt-form--label-right">
                        <div className="kt-portlet__body dima_form" style={{backgroundColor: "#9899ac"}}>
                            {/*<div className="dima_heder_list">List</div>*/}
                            <div className="form-group row form-group-marginless">

                                <div className="col-lg-4 col-md-9 col-sm-12">
                                    <Input
                                        type="text"
                                        value={this.state.searchValue}
                                        placeholder="Account"
                                        id="generalSearch"
                                        onChange={this.handleSearch}
                                    />
                                </div>


                                <div className="col-lg-4 kt-align-left">
                                    <Button
                                        type="button"
                                        onClick={this.handleClearClick}
                                        name="Clear"
                                    />
                                </div>
                            </div>

                            <div className="form-group row form-group-marginless kt-margin-t-20">
                                <div className="col-lg-4 kt-align-left">
                                    <Select2
                                        multiple
                                        className="form-control kt-select2"
                                        style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                        id="typeSelect"
                                        data={typeDict}
                                        value={typeValue}
                                        onChange={this.handleChangeType}
                                        options={{placeholder: "Type"}}
                                    />
                                </div>

                                <div className="col-lg-4 kt-align-left">
                                    <Link
                                        to="list\new"
                                        target="_blank"
                                    >
                                        <Button
                                            type="button"
                                            className="mitra-button-submit"
                                            name="New"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <TableHead
                    onSort={this.handleClick}
                    field={this.state.field}
                    sort={this.state.sort}
                />
                {this.state.isLoadedTable === false ? <LoadingPage/> :
                    <ListTable
                        delete={this.delete}
                        change={this.change}
                        List={this.state.list}
                        errorCss={this.state.errorStatus}
                        not={this.state.pages}
                        forcePage={this.state.page}
                        onPageChange={this.pageHandler}
                        pageCount={this.state.pages}
                    />
                }
            </div>
        );
    }
}

export default List;
