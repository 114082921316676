import React from 'react';
import Switch from "react-switch";
import {config} from "../util/constants";
import axios from "axios";
import {Link} from "react-router-dom";
import LoadingPage from "../LoadingPage";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Input from "../Input";
import Select2 from "react-select2-wrapper";
import Button from "../Button";
import {saveDict} from "../util/dicts";


class NewSimCardForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            autoRechage: false,
            number: '',
            operator: null,
            operatorDict: [],
            duration: '',
            rechageAmount: '',
            startDate: new Date(),
            bearer: localStorage.getItem("authToken"),
            disabled: false,

            response: [],
            error: null,
            isLoaded: false,

        };
        this.handleChangeAutoRechage = this.handleChangeAutoRechage.bind(this);
        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeOperator = this.handleChangeOperator.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeDuration = this.handleChangeDuration.bind(this);
        this.handleChangeRechageAmount = this.handleChangeRechageAmount.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({disabled : true})
        this.sendSimCardRequest();
    }

    handleChangeAutoRechage(checked) {
        this.setState({
            autoRechage: checked
        });

    };

    sendSimCardRequest() {
        const data = {
            number: this.state.number,
            operator: this.state.operator,
            autoRefill: this.state.autoRefill,
        };

        axios.put(config.url.API_URL + "/SIMCard/", data, {

            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        })
            .then(res => {
                    const result = res.data;
                saveDict();
                this.setState({
                        id: result.id,
                        statusText: "OK",
                        errorMessage: 'SIM card id: ' + result.id + ' save',
                        modalShow: true,
                        redirect: true,
                    });
                }
            ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    disabled : false,
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    }

    handleChangeNumber = (e) => {

        const value = e.target.value;
        this.setState({
            number: value,
        })

    };

    handleChangeDate(date) {

        // const value = e.target.value;
        this.setState({
            startDate: date,
        })

    };

    handleChangeDuration = (e) => {

        const value = e.target.value;
        this.setState({
            duration: value,
        })

    };
    handleChangeRechageAmount = (e) => {

        const value = e.target.value;
        this.setState({
            rechageAmount: value,
        })

    };
    handleClear = (event) => {

        event.preventDefault();
        this.setState({
            autoRechage: false,
            number: '',
            operator: null,
            duration: '',
            rechageAmount: '',
        })

    };

    handleChangeOperator(e) {
        let value = e.target.value;
        this.setState({
            operator: value,
        })
    };


    componentDidMount() {
        document.title ='New SIM - mitra processing';

        axios.get(config.url.API_URL + '/dictionary/operatorDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    operatorDict: result,
                    isLoaded: +1
                });
            },
        ).catch(e => {
            localStorage.setItem("auth", false);
            this.props.setAuth(false);

        });
    }


    render() {
        let modalClose = () => this.setState({modalShow: false});
        return (
            <div>
                {this.state.isLoaded < 1 ? <LoadingPage/> :
                    <div className="kt-portlet kt-portlet--mobile">
                        <MyVerticallyCenteredModal
                            redirect={this.state.redirect}
                            id={this.state.id}
                            path={"/SIMCard/"}
                            heder={this.state.errorStatus}
                            h4={this.state.statusText}
                            text={this.state.errorMessage}
                            show={this.state.modalShow}
                            onHide={modalClose}
                        />
                        <form className="mitra-form">
                            {this.state.id > 0 ?
                                <div className="dima_heder">New SIM card: <Link style={{color: "#646c9a"}}
                                                                                to={"/simCard/" + this.state.id}> {this.state.id}</Link>
                                </div> :
                                <div className="dima_heder">New SIM card</div>
                            }
                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Number</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        type="text"
                                        value={this.state.number}
                                        id="Number"
                                        onChange={this.handleChangeNumber}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Suppler</label>
                                </div>
                                <div className="input_form_single">
                                    <Select2
                                        className="mitra-select"
                                        id="operatorSelect"
                                        data={this.state.operatorDict}
                                        value={this.state.operator}
                                        onChange={this.handleChangeOperator}
                                    />
                                </div>
                            </div>
                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Duration</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        type="text"
                                        value={this.state.duration}
                                        id="duration"
                                        onChange={this.handleChangeDuration}
                                    />
                                </div>
                            </div>
                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Rechage amount</label>
                                </div>

                                <div className="input_form_single">
                                    <Input type="text"
                                           value={this.state.rechageAmount}
                                           id="rechageAmount"
                                           onChange={this.handleChangeRechageAmount}
                                    />
                                </div>
                            </div>
                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">Last rechage</label>*/}
                            {/*    </div>*/}
                            {/*    <div className="input_form_single">*/}
                            {/*        <DatePicker*/}
                            {/*            selected={this.state.startDate}*/}
                            {/*            onChange={this.handleChangeDate}*/}
                            {/*            showTimeSelect*/}
                            {/*            dateFormat="d.mm.yyyy hh:mm"*/}
                            {/*            containerStyles={{*/}
                            {/*                width: "100%",*/}
                            {/*                backgroundColor: "#d6d7ea",*/}
                            {/*                borderRadius: "30px"*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="dima_form_single_switch" style={{marginTop : "3em"}}>
                                <div className="switch_block_dima">
                                    <Switch
                                        checked={this.state.autoRechage}
                                        onChange={this.handleChangeAutoRechage}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        className="kt-switch"
                                        id="kt-switch"
                                    />
                                    <div className="label_inline_switch">
                                        <label className="label_form">Auto rechage</label>
                                    </div>
                                </div>
                            </div>
                            <div className="btn_form-dima">
                                <Button
                                    disabled={this.state.disabled}
                                    type="submit"
                                    className="mitra-button-submit"
                                    onClick={this.handleSubmit}
                                    name="Submit"
                                />
                                <Button
                                    onClick={this.handleClear}
                                    name="Clear"
                                />
                            </div>
                        </form>
                    </div>
                }
            </div>
        )
    }
}

export default NewSimCardForm;
