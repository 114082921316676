import React from "react";
import Aside from "../aside/Aside";
import {Switch} from "react-router-dom";
import RoleRoute from "../../routers/RoleRoute";
import NewUserForm from "./NewUserForm";
import UserForm from "./UserForm";
import UserList from "./UserList";

export default function UserRoute( {match} ) {
    return (
        <>
            <Aside/>
            <Switch>
                <RoleRoute
                    path={`${match.path}/new`}
                    component={NewUserForm}
                />
                <RoleRoute
                    path={`${match.path}/:id`}
                    component={UserForm}
                />
                <RoleRoute
                    path={`${match.path}/`}
                    component={UserList}
                />
            </Switch>
        </>
    )
}