let prod = {
    production: true,
    bearer: '',
    roleSet: ['ADMIN'],
    auth: false,
    lastPage: null,
    url: {
        API_URL: 'https://gt.proc.one:10001/back'
    }
};

let dev = {
    production: false,
    bearer: '',
    auth: false,
    roleSet: ['SA','ADMIN'],
    url: {
        API_URL: 'https://dev.proc.one/back'
    }
};

export const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;