

export function isAuth() {
    const auth = localStorage.getItem("auth")
    if (auth === undefined) {
        return false;
    } else if (auth === 'true' || auth === true || auth === '1') {
        return true;
    } else {
        return false;
    }
}

export function setAuth(value) {
    if (value === true || value === 'true' || value === "1") {
        localStorage.setItem("auth", 'true');

    } else {
        localStorage.setItem("auth", 'false');
    }
}

export function getToken() {
    return 'Bearer ' + localStorage.getItem("authToken")
}

export function saveAuth(authData) {
    localStorage.setItem("authToken", authData.token);
    localStorage.setItem("auth", 'true');
    localStorage.setItem("user", authData.username);
    localStorage.setItem("roles", JSON.stringify(authData.roles));
    localStorage.setItem("loadingDict", 'false');
}

export function getRoles(roleList) {
    if (roleList != null && roleList.length > 0) {
        try {
            const roleSet = JSON.parse(localStorage.getItem("roles"));
            return roleSet.some(r => roleList.indexOf(r) >= 0)
        } catch (err) {
            setAuth(false)
            // history.push
            alert("old local storage, please - relogin!!!")
            throw new Error("old local storage")
        }
    } else {
        return false
    }
}