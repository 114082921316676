import React from 'react';


export default props =>(

    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">

        <table className="kt-datatable__table">
            <thead className="kt-datatable__head" style={{borderBottom: "solid"}}>
            <tr
                className="kt-datatable__row"
                style={{left: "0px"}}>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'name')}
                >
                            <span
                                style={{width: "200px"}}
                            >
                                    Agent
                                {props.field === 'name' ? (props.sort === "asc") ?
                                    <i className="flaticon2-arrow-up"/> :
                                    <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'terminal')}
                    >
                    <span
                                style={{width: "80px"}}
                            >
                                    Terminal
                        {props.field === 'terminal' ? (props.sort === "asc") ?
                            <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'average_turnover_week')}
                    >
                    <span
                                style={{width: "60px"}}
                            >
                                    AT1W
                        {props.field === 'average_turnover_week' ? (props.sort === "asc") ?
                            <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'average_turnover_two_week')}
                >
                    <span
                        style={{width: "60px"}}
                    >
                                    AT2W
                        {props.field === 'average_turnover_two_week' ? (props.sort === "asc") ?
                            <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'average_turnover_month')}
                    >
                    <span
                                style={{width: "60px"}}
                            >
                                    AT1M
                        {props.field === 'average_turnover_month' ? (props.sort === "asc") ?
                            <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'average_turnover_year')}
                    >
                    <span
                                style={{width: "60px"}}
                            >
                                    AT1Y
                        {props.field === 'average_turnover_year' ? (props.sort === "asc") ?
                            <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'city')}
                >

                    <span
                                style={{width: "90px"}}
                            >
                                    City
                        {props.field === 'city' ? (props.sort === "asc") ?
                            <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                {/*<th className="kt-datatable__cell kt-datatable__cell--sort"*/}
                {/*    // onClick={props.onSort.bind(null, 'area')}*/}
                {/*>*/}
                {/*    <span*/}
                {/*                style={{width: "80px"}}*/}
                {/*            >*/}
                {/*                    Area*/}
                {/*        {props.field === 'area' ? (props.sort === "asc") ?*/}
                {/*            <i className="flaticon2-arrow-up"/> :*/}
                {/*            <i className="flaticon2-arrow-down"/> : null}*/}
                {/*            </span>*/}
                {/*</th>*/}

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'commission_plan')}
                >
                    <span
                                style={{width: "120px"}}
                            >
                                    Commission
                        {props.field === 'commission_plan' ? (props.sort === "asc") ?
                            <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                {/*<th className="kt-datatable__cell kt-datatable__cell--sort"*/}
                {/*    onClick={props.onSort.bind(null, 'permission')}*/}
                {/*>*/}
                {/*    <span*/}
                {/*                style={{width: "120px"}}*/}
                {/*            >*/}
                {/*                    Description*/}
                {/*        {props.field === 'permission' ? (props.sort === "asc") ?*/}
                {/*            <i className="flaticon2-arrow-up"/> :*/}
                {/*            <i className="flaticon2-arrow-down"/> : null}*/}
                {/*            </span>*/}
                {/*</th>*/}

                {/*<th className="kt-datatable__cell" style={{width: "20%"}}>*/}
                {/*            <span*/}
                {/*                style={{width: "180px"}}*/}
                {/*            >*/}
                {/*                    Valid till*/}
                {/*            </span>*/}
                {/*</th>*/}
            </tr>
            </thead>
        </table>
    </div>
)
