import React, {useState, useEffect} from "react";
import "./paymentCheckingForm.css"
import moment from "moment-timezone";
import {useParams} from "react-router-dom";
import LoadingPage from "../../LoadingPage";
import {sendGET, sendPIN} from "../../util/sendRequest";
import Button from "../../Button";

export default function PaymentCheckingForm() {
    let {id} = useParams();
    const [state, setState] = useState({});
    const [pin, setPin] = useState({});
    const [loading, setLoading] = useState({});
    document.title = 'Payment checking: ' + id + ' - mitra processing';


    useEffect(() => {
        sendGET("/check/" + id, null, setState, null, setLoading)
        sendPIN(id, setPin, null)

    }, [id]);

    const  getPin = () => {
        // sendPIN(id, setPin, null, setLoading)
        navigator.clipboard.writeText(pin.data.msg)
        console.log('pin', pin.data.msg)
        console.log('pin')
    };
    return (
        <div className="mitra_wrapper">

            {loading ? (<LoadingPage/>) :
                <div className="mitra_row-check">
                    <ul className="mitra-check-ul">
                        <li className="mitra-check-li-h1"><h1>Transaction: {state.ticket}</h1></li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Start</label> {state.timeStart ? moment(state.timeStart).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : 'N/A'}
                        </li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Processing</label> {state.timeProcessing ? moment(state.timeProcessing).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : 'N/A'}
                        </li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Complete</label> {state.timeFinal ? moment(state.timeFinal).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : 'N/A'}
                        </li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Try</label> {state.repeats}</li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Status</label> {state.status}</li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Terminal</label> {state.terminal}</li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Agent</label> {state.place}</li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Service</label> {state.service}</li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Product</label> {state.operatorName}</li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Account</label> {state.phone}</li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Chanel</label> {state.chanel}</li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Got</label> {state.sum + ' ' + state.currencyIn}</li>
                        <li className="mitra-check-li"><label
                            className="mitra-check-label">Paid</label> {state.receive + ' ' + state.receiveCurrency}
                        </li>
                        <li className="mitra-check-li-respounce"><label
                            className="mitra-check-label-respounce">Response</label>
                            <textarea className="mitra-check-textarea" readOnly rows={27}>{state.operatorResponse}</textarea>
                        </li>
                        <li className="mitra-check-li-button">
                            <Button
                                name={"Close"}
                                className="mitra-check-button mitra-button-incass"
                                onClick={()=>{window.close()}}
                            />
                            <Button
                                name={"Pin"}
                                className="mitra-check-button mitra-button-incass"
                                onClick={getPin}
                            />
                        </li>
                    </ul>
                </div>
            }
        </div>
    );

}

