import React from "react";
import Aside from "../aside/Aside";
import {Switch} from "react-router-dom";
import RoleRoute from "../../routers/RoleRoute";
import Commands from "./Commands";
import CommandNew from "./CommandNew";

export default function CommandsRoute( {match} ) {
    return (
        <>
            <Aside/>
            <Switch>
                <RoleRoute
                    path={`${match.path}/new`}
                    component={CommandNew}
                />
                <RoleRoute
                    path={`${match.path}/`}
                    component={Commands}
                />
            </Switch>
        </>
    )
}