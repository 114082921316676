import React, {useEffect, useRef, useState} from "react";

import useOutsideClick from "../../Hooks/useOutsideClick";

import style from "./select.module.css";

export default function Select({value, dict, handleSelect, placeholder, name, clear}) {
    const ref = useRef();
    const renderValue = dict.filter(item => value.includes(item.id) ? item : null);
    const [openOptions, setOpenOptions] = useState(false);
    const [target, setTarget] = useState(value);
    const [multiValue, setMultiValue] = useState([]);

    useEffect(() => {
        if (clear==='clear'){
            setTarget([])
            setMultiValue([])
        }
        callback()
    }, [multiValue,clear]);// eslint-disable-line react-hooks/exhaustive-deps

    useOutsideClick(ref, () => {
        closeOutside()
    });

    const closeOutside = () => {
        setOpenOptions(false)
    }
    const toggle = (event) => {
        if (event.target.id === 'control' || event.target.id === 'placeholder' || event.target.id === 'text') {
            setOpenOptions(!openOptions)
        }
    };

    const addValue = (id) => {

        setOpenOptions(false)
        if (multiValue.includes(id)) {
            const removeId = multiValue.filter(item => item === id ? null : item)
            setMultiValue(removeId)
            setTarget(removeId)
        } else {
            setMultiValue([...multiValue, id])
            setTarget([...target, id])
        }
    };

    const remove = (id) => {
        const removeId = multiValue.filter(item => item === id ? null : item)
        setMultiValue(removeId)
        setTarget(removeId)
        setOpenOptions(true)
    };

    const callback = () => {
        handleSelect(multiValue, name)
    }

    return (
        <div className={style.container}
             ref={ref}
             onChange={callback}
        >
            <div
                id={'control'}
                onClick={toggle}
                className={openOptions ? style.controlFocus + " " + style.control : style.control}
            >
                {value.length > 0 ?
                    renderValue.map(item =>
                        <div className={style.multiValue} key={item.id}>
                            <div className={style.multiValueIcon}
                                 onClick={remove.bind(null, item.id)}
                            ><i className="fa fa-times"/></div>
                            <div id={'text'} onClick={toggle} className={style.multiValueText}>{item.text}</div>
                        </div>
                    )
                    :
                    <div className={style.placeholder} id={'placeholder'} onClick={toggle}>
                        {placeholder}
                    </div>
                }
            </div>
            <div className={openOptions ? style.options : style.close}>
                {dict ?
                    dict.map(item => <div
                        className={target.includes(item.id) ? style.optionsItemTarget + " " + style.optionsItem : style.optionsItem}
                        key={item.id}
                        onClick={addValue.bind(null, item.id)}
                    >
                        {item.text}
                    </div>)
                    :
                    <div className={style.optionsItem}>No options</div>
                }
            </div>
        </div>
    );
};