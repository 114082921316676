import React from 'react'
import Select2 from "react-select2-wrapper";
import './example.css'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import Switch from "react-switch";
import ReactPaginate from "react-paginate";
import Popover from "react-bootstrap/Popover";
import PopoverTitle from "react-bootstrap/PopoverTitle";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PopoverContent from "react-bootstrap/PopoverContent";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Button from "../Button";
import Input from "../Input";

class ExampleList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bearer: localStorage.getItem("authToken"),
            todo: ['Option1', 'Option2', 'Option3', 'Option4', 'Option5', 'Option6', 'Option7', 'Option8'],
            valueSelect: null,
            valueInput: '',
            startDate: moment(),
            endDate: moment(),
            startDateDefault: moment().subtract(14, 'days'),
            endDateDefault: moment(),
            checked: false,
            extended: false,
            modalClose: false,
            message: "JSON conversion problem: " +
                "Instantiation of [simple type, class ru.marx.mitra.enteties.CommissionPlanEntity]" +
                " value failed for JSON property name due to missing (therefore NULL)" +
                " value for creator parameter name which is a non-nullable type; nested " +
                "exception is com.fasterxml.jackson.module.kotlin.MissingKotlinParameterException: " +
                "Instantiation of [simple type, class ru.marx.mitra.enteties.CommissionPlanEntity] " +
                "value failed for JSON property name due to missing (therefore NULL) value for creator " +
                "parameter name which is a non-nullable type\\n at" +
                " [Source: (PushbackInputStream); line: 1, column: 2] "
        };


        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
        this.handleExtended = this.handleExtended.bind(this);
        this.modalClose = this.modalClose.bind(this);
    }
 componentDidMount() {
     document.title ='Example - mitra processing';

 }

    handleChangeSelect = (event) => {
        this.setState({
            valueSelect: event.target.value,
        })

    };
    handleChangeInput = (event) => {
        this.setState({
            valueInput: event.target.value,
        })

    };
    handleChangeDate = (event, picker) => {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
        })

    };
    handleChangeSwitch = (checked) => {

        this.setState({
            checked
        })

    };

    handleExtended = () => {
        if (this.state.extended === false) {
            this.setState({
                extended: true
            })
        } else {
            this.setState({
                extended: false
            })
        }

    };

    modalClose = () => {
        if (this.state.modalClose === false) {
            this.setState({
                modalClose: true
            })
        } else {
            this.setState({
                modalClose: false
            })
        }
    };


    render() {
        const {
            todo, valueSelect, valueInput, startDate, startDateDefault, endDateDefault, endDate, checked, extended, modalClose, message
        } = this.state;
        return (
            <div className="body_example">

                {console.log(startDate)}
                {/*Buttons and Form group*/}
                <div className={'mitra-raw'}>
                    {/*Buttons*/}
                    <div className="mitra-block-2">
                        <div className="example_block_head">
                            <div className="example_block_raw">
                                Buttons
                            </div>
                        </div>

                        <div className="example_block_body">
                            <div className="example_block_raw">
                                <div className={'example_cell'}>
                                    <Button
                                        className={'mitra-button-submit'}
                                    />
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Используется в формах для отправки и сохранения данных на сервере.
                                    </p>
                                </div>
                            </div>
                            <div className="example_block_raw">
                                <div className={'example_cell'}>
                                    <Button/>
                                </div>

                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Кнопки Clear, также используются для расширения функционала в формах.
                                    </p>
                                </div>
                            </div>

                            <div className={'example_block_raw'}>
                                <div className="example_cell">
                                   <Button
                                    className={'mitra-button-cancel'}
                                   />
                                </div>

                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Кнопка выбора "плохих" статусов платежей, кнопка Cancel в формах.
                                    </p>
                                </div>
                            </div>

                            <div className={'example_block_raw'}>
                                <div className="example_cell">
                                    <Button
                                        disabled={true}
                                    />
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Неактивные кнопки.
                                    </p>
                                </div>
                            </div>

                            <div className={'example_block_raw'}>
                                <div className="example_cell">
                                    <Switch
                                        checked={checked}
                                        onChange={this.handleChangeSwitch}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        id="kt-switch"
                                    />
                                    <div className="label_inline_switch_settings">
                                        <label className="label_settings">Label switch</label>
                                    </div>
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Switch - Переключатель On Off
                                    </p>
                                </div>
                            </div>

                            <div className={'example_block_raw'}>
                                <div className="example_cell">
                                    <Switch
                                        disabled={true}
                                        checked={checked}
                                        onChange={this.handleChangeSwitch}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        id="kt-switch"
                                    />
                                    <div className="label_inline_switch_settings">
                                        <label className="label_settings">Label switch disabled</label>
                                    </div>
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Switch disabled - Переключатель On Off неактивный

                                    </p>
                                </div>
                            </div>

                            <div className={'example_block_raw'}>
                                <div className="example_cell">
                                    <i className="fa fa-edit"/>
                                </div>

                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Edit - открывает возможность редактировать лимиты на странице Settings блок
                                        "Suppliers control and minimal limits"
                                    </p>
                                </div>
                            </div>

                            <div className={'example_block_raw'}>
                                <div className="example_cell">
                                    <button className="mitra-extended-button"
                                            type="button"
                                            onClick={this.handleExtended}>
                                    <span className="extended_span">
                                        {extended === false ? "+ Extended" : "- Extended"}
                                    </span>
                                    </button>
                                </div>

                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Drop down - используется в некоторых формах для скрытия второстепенных данных
                                    </p>
                                </div>
                            </div>
                            <div className={'example_cell'}>
                                <div className="example_block_raw">
                                    <p className={'mitra-paragraph'}>
                                        {extended === false ? '' : 'Text show'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Input group*/}
                    <div className="mitra-block-2">
                        <div className="example_block_head">
                            <div className="example_block_raw">
                                Form group
                            </div>
                        </div>

                        <div className="example_block_body">
                            <div className="example_block_raw">
                                <div className={'example_cell'}>
                                    <Input
                                        value={valueInput}
                                        placeholder={'Placeholder'}
                                        onChange={this.handleChangeInput}
                                    />
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Input - поле ввода данных, используются в блоке фильтров вывода основной
                                        информации (Search)
                                        и в формах при редактировании данных.
                                    </p>
                                </div>
                            </div>
                            <div className="example_block_raw">
                                <div className={'example_cell'}>
                                    <Input
                                        disabled={true}
                                        value={valueInput}
                                        placeholder={'Placeholder'}
                                    />
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Input disabled - используется для вывода данных в формах без возможности
                                        редактирования.
                                    </p>
                                </div>
                            </div>
                            <div className="example_block_raw">
                                <div className={'example_cell'}>

                                    <Select2
                                        multiple
                                        // value={valueSelect}
                                        data={todo}
                                        // onChange={this.handleChangeSelect}
                                        className={'mitra-select'}
                                        options={
                                            {
                                                placeholder: 'Placeholder',
                                            }
                                        }
                                    />
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Multi select - поле выбора данных из словарей, используется в блоке фильтров
                                        вывода
                                        основной информации.
                                    </p>
                                </div>
                            </div>

                            <div className="example_block_raw">
                                <div className={'example_cell'}>
                                    <Select2
                                        value={valueSelect}
                                        data={todo}
                                        onChange={this.handleChangeSelect}
                                        className={'mitra-select'}
                                        options={
                                            {
                                                placeholder: 'Placeholder',
                                            }
                                        }
                                    />
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Select - поле выбора данных из словарей, используется в формах при
                                        редактировании
                                        данных.
                                    </p>
                                </div>
                            </div>

                            <div className="example_block_raw">
                                <div className={'example_cell'}>
                                    <Select2
                                        disabled={true}
                                        value={valueSelect}
                                        data={todo}
                                        // onChange={this.handleChangeSelect}
                                        className="mitra-select"
                                        options={
                                            {
                                                placeholder: 'Placeholder',
                                            }
                                        }
                                    />
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Select disabled - используется для вывода данных из словарей в формах без
                                        возможности
                                        редактирования.
                                    </p>
                                </div>
                            </div>

                            <div className="example_block_raw">
                                <div className={'example_cell'}>
                                    <DateRangePicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        linkedCalendars={false}
                                        timePicker24Hour={true}
                                        opens={"left"}
                                        applyClass={"btn-primary"}
                                        onApply={this.handleChangeDate}
                                        timePicker={true}
                                        timePickerIncrement={30}
                                        locale={{format: "DD.MM.YYYY HH:mm"}}

                                    >
                                        <input
                                            type="text"
                                            className="mitra-input"
                                            readOnly
                                            placeholder="Date &amp; time"
                                            defaultValue={startDateDefault.tz("Asia/Qatar").tz("Asia/Qatar").format("DD.MM.YYYY HH:mm") + ' to ' + endDateDefault.format("DD.MM.YYYY HH:mm")}
                                            value={startDate.tz("Asia/Qatar").format("DD.MM.YYYY HH:mm") + ' to ' + endDate.tz("Asia/Qatar").format("DD.MM.YYYY HH:mm")}

                                        />
                                    </DateRangePicker>
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Date picker - используется для выбора временного диапазона.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Table*/}
                <div className={'mitra-raw'}>

                    <div className="example_block">
                        <div className="example_block_head">
                            <div className="example_block_raw">
                                Table
                            </div>
                        </div>

                        <div className={'mitra-table'}>
                            <table className={'mitra-table'}>
                                <thead className={'mitra-table-head'}>
                                <tr className={'mitra-table-head-raw'}>
                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        Text
                                        <i className={'mitra-icon-table-head flaticon2-arrow-up'}/>
                                    </th>
                                    <th className={'mitra-table-cell'}
                                        style={{width: "200px"}}
                                    >
                                        Date
                                        <i className={'mitra-icon-table-head flaticon2-arrow-down'}/>
                                    </th>
                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        Retry
                                    </th>
                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        Done
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        Change type
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        Delete
                                    </th>
                                    <th className={'mitra-table-cell'}
                                        style={{width: "400px", textAlign: "center"}}
                                    >
                                        Cash
                                    </th>

                                </tr>
                                </thead>
                                <tbody className={'mitra-table-body'}>
                                <tr className={'mitra-table-body-raw'}>
                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        Text
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "200px"}}
                                    >
                                        {moment().tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm")}
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className='kt-menu_link-icon fa fa-sync-alt'/>
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className="fa fa-check"/>
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className="fa fa-exchange-alt" style={{size: "x-large"}}/>
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className="flaticon2-delete"/>
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "400px"}}
                                    >
                                        <div className="progress"
                                            // style={{width: "400px"}}
                                        >
                                            <div className="progress-bar"
                                                 role="progressbar"
                                                 aria-valuenow={2}
                                                 aria-valuemin="0"
                                                 aria-valuemax="600"
                                                 style={{width: 2}}
                                            >

                                            </div>
                                            <div className="progress-bar-title">{2}</div>
                                        </div>
                                    </th>
                                </tr>
                                <tr className={'mitra-table-body-raw'}>
                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        Text
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "200px"}}
                                    >
                                        {moment().tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm")}
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className='kt-menu_link-icon fa fa-sync-alt'/>
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className="fa fa-ban"/>
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className="fa fa-exchange-alt" style={{size: "x-large"}}/>
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className="flaticon2-delete"/>
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "400px"}}
                                    >
                                        <div className="progress">
                                            <div className="progress-bar"
                                                 role="progressbar"
                                                 aria-valuenow={200}
                                                 aria-valuemin="0"
                                                 aria-valuemax="600"
                                                 style={{width: 200}}
                                            >

                                            </div>
                                            <div className="progress-bar-title">{200}</div>
                                        </div>
                                    </th>
                                </tr>
                                <tr className={'mitra-table-body-raw-error'}>
                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        Text
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "200px"}}
                                    >
                                        {moment().tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm")}
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className='kt-menu_link-icon fa fa-sync-alt'/>
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className="fa fa-ban"/>
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className="fa fa-exchange-alt" style={{size: "x-large"}}/>
                                    </th>

                                    <th className={'mitra-table-cell'}
                                        style={{width: "100px"}}
                                    >
                                        <i className="flaticon2-delete"/>
                                    </th>
                                    <th className={'mitra-table-cell'}
                                        style={{width: "400px"}}
                                    >
                                        <div className="progress"
                                            // style={{width: "400px"}}
                                        >
                                            <div className="progress-bar"
                                                 role="progressbar"
                                                 aria-valuenow={50}
                                                 aria-valuemin="0"
                                                 aria-valuemax="600"
                                                 style={{width: 50}}
                                            >

                                            </div>
                                            <div className="progress-bar-title">{50}</div>
                                        </div>
                                    </th>


                                </tr>

                                </tbody>
                                {/*Paginator*/}
                                <div className="d-flex justify-content-center">
                                    <ReactPaginate
                                        previousLabel={<i className="fas fa-angle-double-left"/>}
                                        nextLabel={<i className=" fas fa-angle-double-right"/>}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        // pageCount={props.pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        // onPageChange={props.onPageChange}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        nextClassName={'page-item'}
                                        previousLinkClassName={'page-item'}
                                        nextLinkClassName={'page-item'}
                                        // forcePage={props.forcePage}
                                    />
                                </div>
                            </table>
                        </div>
                    </div>
                </div>
                {/*Monitoring and font*/}
                <div className={'mitra-raw'}>
                    {/*Monitoring and Fonts*/}
                    <div className={'mitra-block-2'}>
                        <div className={'example_block_head'}>
                            <div className={'example_block_raw'}>
                                <div className={'example_cell'}>
                                    Monitoring
                                </div>
                            </div>
                        </div>
                        <div className={'example_block_body'}>
                            {/*Legend status*/}
                            <div className={'example_block_raw'}>
                                <div className={'example_cell'}>
                                    <div className="monitoring-legend-bloc">
                                        <div className="block-grid-">
                                            <div className="monitoring-legend-ok"></div>
                                        </div>
                                        <div className="block-grid-">
                                            <div className="monitoring-legend-text"
                                                 style={{color: "#646b9d"}}
                                            >OK
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'example_cell'}>
                                    <div className="monitoring-legend-bloc">
                                        <div className="block-grid-">
                                            <div className="monitoring-legend-noPayments"></div>
                                        </div>
                                        <div className="block-grid-">
                                            <div className="monitoring-legend-text"
                                                 style={{color: "#646b9d"}}
                                            >No payments
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'example_cell'}>
                                    <div className="monitoring-legend-bloc">
                                        <div className="block-grid-">
                                            <div className="monitoring-legend-oneFalied"></div>
                                        </div>
                                        <div className="block-grid-">
                                            <div className="monitoring-legend-text"
                                                 style={{color: "#646b9d"}}
                                            >One failed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'example_block_raw'}>
                                <div className={'example_cell'}>
                                    <div className="monitoring-legend-bloc">
                                        <div className="block-grid-">
                                            <div className="monitoring-legend-allFailed"></div>
                                        </div>
                                        <div className="block-grid-">
                                            <div className="monitoring-legend-text"
                                                 style={{color: "#646b9d"}}
                                            >All failed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'example_cell'}>
                                    <div className="monitoring-legend-bloc">
                                        <div className="block-grid-">
                                            <div className="monitoring-legend-offline"></div>
                                        </div>
                                        <div className="block-grid-">
                                            <div className="monitoring-legend-text"
                                                 style={{color: "#646b9d"}}
                                            >Offline
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Table monitoring Terminals*/}
                            <div className={'example_block_raw'}>
                                <div className={'example_cell'}>
                                    <table className="mitra-table-monitoring-example">
                                        <tr className={'mitra-table-monitoring-row'}>
                                            <td className={'mitra-table-monitoring-cell'}>
                                                <OverlayTrigger
                                                    trigger="click"
                                                    key={"top"}
                                                    placement={"top"}
                                                    overlay={
                                                        <Popover
                                                            id={`popover-positioned-${"top"}`}
                                                            title={`Popover ${"top"}`}
                                                        >
                                                            <PopoverTitle>
                                                                <h3>Terminal 007</h3>
                                                            </PopoverTitle>
                                                            <PopoverContent>
                                                                <ul className={'mitra-popover-content-list'}>
                                                                    <li>Agent : Wanees Restaurant,</li>
                                                                    <li>Phone: 042696284 / 0506989906</li>
                                                                    <li>Cash: 7</li>
                                                                    <li>Last
                                                                        online: {moment(1555247119961).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm")}</li>
                                                                </ul>
                                                            </PopoverContent>
                                                        </Popover>
                                                    }
                                                >
                                                    <button className="mitra-button-monitoring monitoring-ok">007
                                                    </button>
                                                </OverlayTrigger>
                                            </td>
                                            <td className={'mitra-table-monitoring-cell'}>
                                                <OverlayTrigger
                                                    trigger="click"
                                                    key={"top"}
                                                    placement={"top"}
                                                    overlay={
                                                        <Popover
                                                            id={`popover-positioned-${"top"}`}
                                                            title={`Popover ${"top"}`}
                                                        >
                                                            <PopoverTitle>
                                                                <h3>Terminal 015</h3>
                                                            </PopoverTitle>
                                                            <PopoverContent>
                                                                <ul className={'mitra-popover-content-list'}>
                                                                    <li>Agent : Wanees Restaurant,</li>
                                                                    <li>Phone: 042696284 / 0506989906</li>
                                                                    <li>Cash: 7</li>
                                                                    <li>Last
                                                                        online: {moment(1555247119961).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm")}</li>
                                                                </ul>
                                                            </PopoverContent>
                                                        </Popover>
                                                    }
                                                >
                                                    <button
                                                        className="mitra-button-monitoring monitoring-noPayments">015
                                                    </button>
                                                </OverlayTrigger>
                                            </td>
                                            <td className={'mitra-table-monitoring-cell'}>
                                                <OverlayTrigger
                                                    trigger="click"
                                                    key={"top"}
                                                    placement={"top"}
                                                    overlay={
                                                        <Popover
                                                            id={`popover-positioned-${"top"}`}
                                                            title={`Popover ${"top"}`}
                                                        >
                                                            <PopoverTitle>
                                                                <h3>Terminal 029</h3>
                                                            </PopoverTitle>
                                                            <PopoverContent>
                                                                <ul className={'mitra-popover-content-list'}>
                                                                    <li>Agent : Wanees Restaurant,</li>
                                                                    <li>Phone: 042696284 / 0506989906</li>
                                                                    <li>Cash: 7</li>
                                                                    <li>Last
                                                                        online: {moment(1555247119961).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm")}</li>
                                                                </ul>
                                                            </PopoverContent>
                                                        </Popover>
                                                    }
                                                >
                                                    <button className="mitra-button-monitoring monitoring-oneFalied">029
                                                    </button>
                                                </OverlayTrigger>
                                            </td>
                                            <td className={'mitra-table-monitoring-cell'}>
                                                <OverlayTrigger
                                                    trigger="click"
                                                    key={"top"}
                                                    placement={"top"}
                                                    overlay={
                                                        <Popover
                                                            id={`popover-positioned-${"top"}`}
                                                            title={`Popover ${"top"}`}
                                                        >
                                                            <PopoverTitle>
                                                                <h3>Terminal 036</h3>
                                                            </PopoverTitle>
                                                            <PopoverContent>
                                                                <ul className={'mitra-popover-content-list'}>
                                                                    <li>Agent : Wanees Restaurant,</li>
                                                                    <li>Phone: 042696284 / 0506989906</li>
                                                                    <li>Cash: 7</li>
                                                                    <li>Last
                                                                        online: {moment(1555247119961).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm")}</li>
                                                                </ul>
                                                            </PopoverContent>
                                                        </Popover>
                                                    }
                                                >
                                                    <button className="mitra-button-monitoring monitoring-allFailed">036
                                                    </button>
                                                </OverlayTrigger>
                                            </td>
                                            <td className={'mitra-table-monitoring-cell'}>
                                                <OverlayTrigger
                                                    trigger="click"
                                                    key={"top"}
                                                    placement={"top"}
                                                    overlay={
                                                        <Popover
                                                            id={`popover-positioned-${"top"}`}
                                                            title={`Popover ${"top"}`}
                                                        >
                                                            <PopoverTitle>
                                                                <h3>Terminal 481</h3>
                                                            </PopoverTitle>
                                                            <PopoverContent>
                                                                <ul className={'mitra-popover-content-list'}>
                                                                    <li>Agent : Wanees Restaurant,</li>
                                                                    <li>Phone: 042696284 / 0506989906</li>
                                                                    <li>Cash: 7</li>
                                                                    <li>Last
                                                                        online: {moment(1555247119961).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm")}</li>
                                                                </ul>
                                                            </PopoverContent>
                                                        </Popover>
                                                    }
                                                >
                                                    <button className="mitra-button-monitoring monitoring-offline">485
                                                    </button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className={'example_cell'}>
                                    <p className="mitra-paragraph">Monitoring table - вывод таблицы терминалов с краткой
                                        информацией по нажатию на терминал</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Fonts*/}
                    <div className="mitra-block-2">
                        <div className="example_block_head">
                            <div className="example_block_raw">
                                Fonts
                            </div>
                        </div>
                        <div className="example_block_body">
                            <div className="example_block_raw">
                                <div className="example_cell">
                                    <p className="mitra-paragraph-heebo">
                                        A B C D E F G H I J K L M N O P Q R S T U V W X Y Z<br/>
                                        a b c d e f g h i j k l m n o p q r s t u v w x y z<br/>
                                        1 2 3 4 5 6 7 8 9 0
                                    </p>
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Fonts Heebo - шрифт используется в таблицах.
                                    </p>
                                </div>
                            </div>
                            <div className="example_block_raw">
                                <div className="example_cell">
                                    <p className="mitra-paragraph-poppins">
                                        A B C D E F G H I J K L M N O P Q R S T U V W X Y Z<br/>
                                        a b c d e f g h i j k l m n o p q r s t u v w x y z<br/>
                                        1 2 3 4 5 6 7 8 9 0
                                    </p>
                                </div>
                                <div className={'example_cell'}>
                                    <p className={'mitra-paragraph'}>
                                        Fonts Poppins - используется как основной шрифт приложения.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Icon and Modal*/}
                <div className="mitra-raw">
                    {/*Icons*/}
                    <div className="mitra-block-2">
                        <div className="example_block_head">
                            <div className="example_block_raw">
                                Icons
                            </div>
                            <div className="example_block_body">
                                <div className="example_block_raw">

                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Modal*/}
                    <div className="mitra-block-2">
                        <div className="example_block_head">
                            <div className="example_block_raw">
                                Modal
                            </div>
                        </div>
                        <div className="example_block_body">
                            <div className="example_block_raw">
                                <div className="example_cell">
                                    <MyVerticallyCenteredModal
                                        heder="Internal Server Error"
                                        h4="500"
                                        text={message}
                                        onHide={this.modalClose}
                                        show={modalClose}
                                    />
                                    <button className="button button_clear"
                                            onClick={this.modalClose}
                                    >Click me
                                    </button>
                                </div>
                                <div className="example_cell">
                                    <p className="mitra-paragraph">
                                        Modal window - область видимости все приложение, всплывает в случаи "плохих"
                                        ответов от сервера.<br/><br/>
                                        Header - поле содержащие название ошибки.<br/><br/>
                                        Title - поле содержащие цифровой код ошибки <br/><br/>
                                        Message text - поле содержащие подробное описание ошибки.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}


export default ExampleList