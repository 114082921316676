import React from 'react'
import '../paymentForm/Form.css'
import moment from "moment-timezone";
import LoadingPage from "../LoadingPage";
import {config} from "../util/constants";
import axios from "axios";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Switch from "react-switch";
import Button from "../Button";
import Input from "../Input";
import Select2 from "react-select2-wrapper";

class SimCardForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bearer: localStorage.getItem("authToken"),

            id: null,
            description: '',
            number: '',
            operator: 330, // Dictionary from /dictionary/operatorDict
            terminal: null,
            autoRefill: false,
            moreTime: null,
            moreData: null,
            moreDataDays: '',
            signal: null,
            lastRefill: '', //($date-time)
            minimalTime: null,
            minimalData: null,

            operatorDict: [],
            terminalDict: [],

            edit: false,
            extendedForm: false,
            disabled: true,
            modalShow: false,
            errorStatus: '',
            statusText: '',
            errorMessage: '',
            error: null,
            isLoaded: null,
        };
        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangeOperator = this.handleChangeOperator.bind(this);
        this.handleChangeTerminal = this.handleChangeTerminal.bind(this);
        this.handleChangeMoreTime = this.handleChangeMoreTime.bind(this);
        this.handleChangeMoreData = this.handleChangeMoreData.bind(this);
        this.handleChangeMoreDataDays = this.handleChangeMoreDataDays.bind(this);
        this.handleChangeSignal = this.handleChangeSignal.bind(this);
        this.handleChangeMinimalTime = this.handleChangeMinimalTime.bind(this);
        this.handleChangeMinimalData = this.handleChangeMinimalData.bind(this);
        this.handleChangeAutoRefill = this.handleChangeAutoRefill.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    componentDidMount() {

        let request = config.url.API_URL + window.location.pathname;
        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                id: result.id,
                number: result.number,
                operator: result.operator,
                terminal: result.terminal,
                autoRefill: result.autoRefill,
                moreTime: result.moreTime,
                moreData: result.moreData,
                moreDataDays: result.moreDataDays,
                signal: result.signal,
                lastRefill: result.lastRefill,
                minimalTime: result.minimalTime,
                minimalData: result.minimalData,

                isLoaded: this.state.isLoaded + 1,
            });
            document.title ='SIM: ' + this.state.number + ' - mitra processing';
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;
                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    let errorStatus = response.status;

                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

        axios.get(config.url.API_URL + '/dictionary/operatorDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;

            this.setState({
                operatorDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;

                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

        axios.get(config.url.API_URL + '/dictionary/terminalDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;

            this.setState({
                terminalDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;

                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    };

    valueForDict(array, index) {
        if ((array === null || array === undefined) && (index === null || index === undefined)) {
            return 'N/A'
        } else {
            let value = array.find(item => item.id === index);
            if (value === undefined) {
                return 'N/A'
            } else {
                return value.text;
            }
        }
    };

    handleChangeNumber(e) {
        const value = e.target.value;
        this.setState({
            number: value,
        })
    };

    handleChangeDescription(e) {
        const value = e.target.value;
        this.setState({
            description: value,
        })
    };

    handleChangeOperator(e) {
        const value = e.target.value;
        this.setState({
            operator: value,
        })
    };

    handleChangeTerminal(e) {
        const value = e.target.value;
        this.setState({
            terminal: value,
        })
    };

    handleChangeMoreTime(e) {
        const value = e.target.value;
        this.setState({
            moreTime: value,
        })
    };

    handleChangeMoreData(e) {
        const value = e.target.value;
        this.setState({
            moreData: value,
        })
    };

    handleChangeMoreDataDays(e) {
        const value = e.target.value;
        this.setState({
            moreDataDays: value,
        })
    };

    handleChangeSignal(e) {
        const value = e.target.value;
        this.setState({
            signal: value,
        })
    };

    handleChangeMinimalTime(e) {
        const value = e.target.value;
        this.setState({
            minimalTime: value,
        })
    };

    handleChangeMinimalData(e) {
        const value = e.target.value;
        this.setState({
            minimalData: value,
        })
    };

    handleChangeAutoRefill(checked) {

            this.setState({
                autoRefill: checked
            })

    };

    handleEdit() {
        if (this.state.edit === false) {
            this.setState({
                edit: true,
                disabled: false

            })
        } else {
            this.setState({
                edit: false,
                disabled: true
            })
        }
    };

    handleClear() {
        this.setState({
            id: null,
            ticket: '',
            service: null,
            chanel: null,
            place: null,
            terminal: null,
            status: null,
            phone: '',
            sum: 0,
            currencyIn: null,
            amount: 0,
            commission: 0,
            currencyOut: null,
            receive: 0,
            receiveCurrency: null,
            timeStart: '',
            timeProcessing: '',
            timeFinal: '',
            operator: null,
            note: '',
            extended1: '',
            extended2: '',
            repeats: 0,
            operatorResponse: '',
            transactionId: null,
            country: null,
            operatorName: '',
        })
    };

    handleCancel() {
        this.componentDidMount();
        this.setState({
            edit: false,
            disabled: true
        })
    };

    handleSubmit() {
        this.sendSimCardRequest();
    };

    sendSimCardRequest() {
        const data = {
            id: parseInt(this.state.id),
            number: this.state.number,
            operator: parseInt(this.state.operator),
            terminal: parseInt(this.state.terminal),
            autoRefill: this.state.autoRefill,
            moreTime: parseInt(this.state.moreTime),
            moreData: parseInt(this.state.moreData),
            moreDataDays: this.state.moreDataDays,
            signal: parseInt(this.state.signal),
            lastRefill: this.state.lastRefill,
            minimalTime: parseInt(this.state.minimalTime),
            minimalData: parseInt(this.state.Data),
        };

        axios.post(config.url.API_URL + "/SIMCard/", data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;

            this.setState({
                id: result.id,
                number: result.number,
                operator: result.operator,
                terminal: result.terminal,
                autoRefill: result.autoRefill,
                moreTime: result.moreTime,
                moreData: result.moreData,
                moreDataDays: result.moreDataDays,
                signal: result.signal,
                lastRefill: result.lastRefill,
                minimalTime: result.minimalTime,
                minimalData: result.minimalData,

                edit: false,
                disabled: true
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;

                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    };


    render() {
        let modalClose = () => this.setState({modalShow: false});
        return (
            <div>
                {this.state.isLoaded < 3 ? <LoadingPage/> :
                    <div className="kt-portlet kt-portlet--mobile">
                        <MyVerticallyCenteredModal
                            heder={this.state.errorStatus}
                            h4={this.state.statusText}
                            text={this.state.errorMessage}
                            show={this.state.modalShow}
                            onHide={modalClose}
                        />
                        <form className="mitra-form">
                            <div className="dima_heder">Sim card: {this.state.id}</div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">ID</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={true}
                                        type="text"
                                        value={this.state.id ? this.state.id : 'N/A'}
                                        id="id"
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Description</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.disabled}
                                        type="text"
                                        value={this.state.description ? this.state.description : 'N/A'}
                                        id="description"
                                        onChange={this.handleChangeDescription}
                                    />
                                </div>
                            </div>


                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Account</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.disabled}
                                        type="number"
                                        value={this.state.number ? this.state.number : 'N/A'}
                                        id="number"
                                        onChange={this.handleChangeNumber}
                                    />
                                </div>

                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Operator</label>
                                </div>

                                <div className="input_form_single">
                                    <Select2
                                        disabled={this.state.disabled}
                                        className="mitra-select"
                                        id="operator"
                                        data={this.state.operatorDict}
                                        value={this.state.operator}
                                        onChange={this.handleChangeOperator}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Terminal</label>
                                </div>

                                <div className="input_form_single">
                                    <Select2
                                        disabled={this.state.disabled}
                                        className="mitra-select"
                                        id="terminal"
                                        data={this.state.terminalDict}
                                        value={this.state.terminal}
                                        onChange={this.handleChangeTerminal}
                                    />
                                </div>
                            </div>

                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">More time</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.edit === false ?*/}
                            {/*            <p className="dima_form_p">{this.state.moreTime}</p> :*/}

                            {/*            <input type="number"*/}
                            {/*                   value={this.state.moreTime}*/}
                            {/*                   className="form-control"*/}
                            {/*                   placeholder={''}*/}
                            {/*                   id="moreTime"*/}
                            {/*                   onChange={this.handleChangeMoreTime}*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">More data</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.edit === false ?*/}
                            {/*            <p className="dima_form_p">{this.state.moreData}</p> :*/}

                            {/*            <input type="number"*/}
                            {/*                   value={this.state.moreData}*/}
                            {/*                   className="form-control"*/}
                            {/*                   placeholder={''}*/}
                            {/*                   id="moreData"*/}
                            {/*                   onChange={this.handleChangeMoreData}*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">More data days</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.edit === false ?*/}
                            {/*            <p className="dima_form_p">{this.state.moreDataDays}</p> :*/}

                            {/*            <input type="text"*/}
                            {/*                   value={this.state.moreDataDays}*/}
                            {/*                   className="form-control"*/}
                            {/*                   placeholder={''}*/}
                            {/*                   id="moreDataDays"*/}
                            {/*                   onChange={this.handleChangeMoreDataDays}*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}


                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Last recharge</label>
                                </div>

                                <div className="input_form_single">
                                    <p className="dima_form_p ">{this.state.lastRefill ? moment(this.state.lastRefill).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : 'N/A'}</p>
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Signal</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.disabled}
                                        type="number"
                                        value={this.state.signal ? this.state.signal : 'N/A'}
                                        id="signal"
                                        onChange={this.handleChangeSignal}
                                    />
                                </div>
                            </div>

                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">Minimal Time</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.edit === false ?*/}
                            {/*            <p className="dima_form_p">{this.state.minimalTime}</p> :*/}

                            {/*            <input type="number"*/}
                            {/*                   value={this.state.minimalTime}*/}
                            {/*                   className="form-control"*/}
                            {/*                   placeholder={''}*/}
                            {/*                   id="minimalTime"*/}
                            {/*                   onChange={this.handleChangeMinimalTime}*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">Minimal data</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.edit === false ?*/}
                            {/*            <p className="dima_form_p">{this.state.minimalData}</p> :*/}

                            {/*            <input type="number"*/}
                            {/*                   value={this.state.minimalData}*/}
                            {/*                   className="form-control"*/}
                            {/*                   placeholder={''}*/}
                            {/*                   id="minimalData"*/}
                            {/*                   onChange={this.handleChangeMinimalData}*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="dima_form_single_switch" style={{marginTop : "3em"}}>
                                <div className="switch_block_dima">
                                    <Switch
                                        disabled={this.state.disabled}
                                        checked={this.state.autoRefill}
                                        onChange={this.handleChangeAutoRefill}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        className="kt-switch"
                                        id="kt-switch"
                                    />
                                    <div className="label_inline_switch">
                                        <label className="label_form">Auto rechage</label>
                                    </div>
                                </div>
                            </div>

                            <div className="btn_form-dima">
                                <Button
                                    onClick={this.handleEdit}
                                    disabled={this.state.disabled === false}
                                    type="button"
                                    name="Edit"
                                />

                                <Button
                                    onClick={this.handleClear}
                                    disabled={this.state.disabled}
                                    type="button"
                                    name="Clear"
                                />

                                <Button
                                    onClick={this.handleCancel}
                                    disabled={this.state.disabled}
                                    type="button"
                                    className="mitra-button-cancel"
                                    name="Cancel"
                                />

                                <Button
                                    onClick={this.handleSubmit}
                                    disabled={this.state.disabled}
                                    type="button"
                                    className="mitra-button-submit"
                                    name="Save"
                                />
                            </div>
                        </form>
                    </div>
                }

            </div>
        );
    }
}

export default SimCardForm;
