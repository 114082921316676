import React from "react";
import './Table.css'

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table className="kt-datatable__table">

            <thead className="kt-datatable__head" style={{borderBottom: "solid"}}>
            <tr className="kt-datatable__row">


                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'date')}
                >
                    <span style={{width: "112px"}}>
                       Date & Time
                        {props.field === 'date' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'terminal')}
                >
                    <span style={{width: "80px"}}>
                       Terminal
                        {props.field === 'terminal' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'city')}
                >
                    <span style={{width: "90px"}}>
                       City
                        {props.field === 'city' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>
                {/*<th className="kt-datatable__cell kt-datatable__cell--sort"*/}
                {/*    // onClick={props.onSort.bind(null, 'area')}*/}
                {/*>*/}
                {/*    <span style={{width: "90px"}}>*/}
                {/*       Area*/}
                {/*        /!*{props.field === 'area' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :*!/*/}
                {/*        /!*    <i className="flaticon2-arrow-down"></i> : null}*!/*/}
                {/*    </span>*/}
                {/*</th>*/}
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'place')}
                >
                    <span style={{width: "150px"}}>
                       Agent
                        {props.field === 'place' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'team')}
                >
                    <span style={{
                        // textAlign: "right",
                        width: "70px",
                        // paddingLeft: "9px",
                    }}>
                       Team
                        {props.field === 'team' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'sumPayment')}
                >
                    <span style={{
                        width: "90px",
                        // textAlign:"right",
                        // paddingRight:"25px"
                    }}>
                       Processed
                        {props.field === 'sumPayment' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'sum')}
                >
                    <span style={{
                        width: "90px",
                        // textAlign:"right",
                        paddingLeft: "39px"
                    }}>
                       Cash
                        {props.field === 'sum' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'b1')}
                >
                    <span style={{
                        width: "70px",
                        paddingLeft: "39px",
                        // paddingRight:"25px"
                    }}>
                       1
                        {props.field === 'b1' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'b5')}

                >
                    <span style={{
                        width: "70px",
                        paddingLeft: "39px",
                        // paddingRight:"25px"
                    }}>
                       5
                        {props.field === 'b5' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'b10')}
                >
                    <span style={{
                        width: "70px",
                        paddingLeft: "39px",
                        // paddingRight:"25px"
                    }}>
                       10
                        {props.field === 'b10' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'b50')}
                >
                    <span style={{
                        width: "70px",
                        paddingLeft: "35px",
                        // paddingRight:"25px"
                    }}>
                       50
                        {props.field === 'b50' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'b100')}
                >
                    <span style={{
                        width: "70px",
                        paddingLeft: "31px",
                        // paddingRight:"25px"
                    }}>
                       100
                        {props.field === 'b100' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'b200')}
                >
                    <span style={{
                        width: "70px",
                        paddingLeft: "28px",
                        // paddingRight:"25px"
                    }}>
                       200
                        {props.field === 'b200' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'b500')}
                >
                    <span style={{
                        width: "70px",
                        // textAlign:"right",
                        paddingLeft: "27px",
                        marginRight: "100px"
                    }}>
                       500
                        {props.field === 'b500' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>


            </tr>
            </thead>
        </table>
    </div>
)

