import React from 'react';
import '../paymentForm/Form.css';
import Switch from "react-switch";
import axios from "axios";
import {Link} from "react-router-dom";
import {config} from "../util/constants";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Input from "../Input";
import Button from "../Button";
import {saveDict} from "../util/dicts";

class NewUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bearer: localStorage.getItem("authToken"),

            id: null,
            name: '',
            password: '',
            email: '',
            firstName: '',
            lastName: '',
            description: '',
            enable: true,
            isAdmin: false,
            isIncassator: false,
            isManager: false,

            modalShow: false,
            errorStatus: '',
            statusText: '',
            errorMessage: '',
            isLoaded: false,

        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeEnable = this.handleChangeEnable.bind(this);
        this.handleChangeIsAdmin = this.handleChangeIsAdmin.bind(this);
        this.handleChangeIsIncassator = this.handleChangeIsIncassator.bind(this);
        this.handleChangeIsManager = this.handleChangeIsManager.bind(this);
        this.handleClear = this.handleClear.bind(this);

    }

    componentDidMount() {
        document.title = 'New user - mitra processing';

    }

    handleSubmit(event) {
        event.preventDefault();
        this.sendUser();
    };

    handleChangeUserName = (e) => {

        const value = e.target.value;
        this.setState({
            name: value,
        })

    };

    handleChangeFirstName = (e) => {

        const value = e.target.value;
        this.setState({
            firstName: value,
        })

    };

    handleChangeLastName = (e) => {

        const value = e.target.value;
        this.setState({
            lastName: value,
        })

    };
    handleChangeDescription = (e) => {

        const value = e.target.value;
        this.setState({
            description: value,
        })

    };

    handleChangePassword = (e) => {

        const value = e.target.value;
        this.setState({
            password: value,
        })
    };
    handleChangeEmail = (e) => {

        const value = e.target.value;
        this.setState({
            email: value,
        })
    };

    handleChangeEnable(checked) {
        this.setState({
            enable: checked
        });

    };

    handleChangeIsAdmin(checked) {
        this.setState({
            isAdmin: checked,
        })
    };

    handleChangeIsIncassator(checked) {
        this.setState({
            isIncassator: checked,
        })
    };

    handleChangeIsManager(checked) {
        this.setState({
            isManager: checked,
        })
    };

    handleClear(e) {
        e.preventDefault();
        this.setState({
            id: null,
            name: '',
            password: '',
            email: '',
            firstName: '',
            lastName: '',
            description: '',
            enable: true,
            isAdmin: false,
            isIncassator: false,
            isManager: false,
        })
    };


    sendUser() {
        let data = {
            name: this.state.name,
            password: this.state.password,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            description: this.state.description,
            enable: this.state.enable,
            isAdmin: this.state.isAdmin,
            isIncassator: this.state.isIncassator,
            isManager: this.state.isManager,
        };

        axios.put(config.url.API_URL + "/user/", data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        })
            .then(res => {

                const result = res.data;
                saveDict();
                console.log(result);
                let name = result.name;
                let id = result.id;
                this.setState({
                    // modalShow: true,
                    name,
                    id
                });
            })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });


    }


    render() {
        let modalClose = () => this.setState({modalShow: false});
        return (
            <div>
                <div className="kt-portlet kt-portlet--mobile">
                    <MyVerticallyCenteredModal
                        heder={this.state.errorStatus}
                        h4={this.state.statusText}
                        text={this.state.errorMessage}
                        show={this.state.modalShow}
                        onHide={modalClose}
                    />
                    <form className="mitra-form">
                        {this.state.id > 0 ?
                            <div className="dima_heder">New user: <Link style={{color: "#646c9a"}}
                                                                        to={"/SimCardForm/" + this.state.id}> {this.state.name}</Link>
                            </div> :
                            <div className="dima_heder">New user form</div>
                        }
                        <div className="dima_form_single">
                            <div className="label_inline">
                                <label className="label_form">Login</label>
                            </div>

                            <div className="input_form_single">
                                <Input
                                    type="text"
                                    value={this.state.name}
                                    id="name"
                                    onChange={this.handleChangeUserName}
                                />
                            </div>
                        </div>

                        <div className="dima_form_single">
                            <div className="label_inline">
                                <label className="label_form">Password</label>
                            </div>

                            <div className="input_form_single">
                                <Input
                                    type="password"
                                    value={this.state.password}
                                    id="password"
                                    onChange={this.handleChangePassword}
                                />
                            </div>
                        </div>
                        <div className="dima_form_single">
                            <div className="label_inline">
                                <label className="label_form">Email</label>
                            </div>
                            <div className="input_form_single">
                                <Input
                                    type="email"
                                    value={this.state.email}
                                    id="email"
                                    onChange={this.handleChangeEmail}
                                />
                            </div>
                        </div>
                        <div className="dima_form_single">
                            <div className="label_inline">
                                <label className="label_form">First Name</label>
                            </div>

                            <div className="input_form_single">
                                <Input
                                    type="text"
                                    value={this.state.firstName}
                                    id="firstName"
                                    onChange={this.handleChangeFirstName}
                                />
                            </div>
                        </div>

                        <div className="dima_form_single">
                            <div className="label_inline">
                                <label className="label_form">Last Name</label>
                            </div>

                            <div className="input_form_single">
                                <Input
                                    type="text"
                                    value={this.state.lastName}
                                    id="lastName"
                                    onChange={this.handleChangeLastName}
                                />
                            </div>
                        </div>

                        <div className="dima_form_single">
                            <div className="label_inline">
                                <label className="label_form">Description</label>
                            </div>

                            <div className="input_form_single">
                                <Input
                                    type="text"
                                    value={this.state.description}
                                    id="description"
                                    onChange={this.handleChangeDescription}
                                />
                            </div>
                        </div>

                        <div className="dima_form_single_switch" style={{marginTop: "3em"}}>
                            <div className="switch_block_dima">
                                <Switch
                                    checked={this.state.enable}
                                    onChange={this.handleChangeEnable}
                                    onColor="#acb3ee"
                                    onHandleColor="#5867dd"
                                    handleDiameter={30}
                                    uncheckedIcon={true}
                                    checkedIcon={true}
                                    boxShadow="true"
                                    activeBoxShadow="true"
                                    offHandleColor="#e1e2e4"
                                    height={20}
                                    width={48}
                                    className="kt-switch"
                                    id="kt-switch"
                                />
                                <div className="label_inline_switch">
                                    <label className="label_form">Enable</label>
                                </div>

                            </div>
                        </div>

                        <div className="dima_form_single_switch">
                            <div className="switch_block_dima">
                                <Switch
                                    checked={this.state.isAdmin}
                                    onChange={this.handleChangeIsAdmin}
                                    onColor="#acb3ee"
                                    onHandleColor="#5867dd"
                                    handleDiameter={30}
                                    uncheckedIcon={true}
                                    checkedIcon={true}
                                    boxShadow="true"
                                    activeBoxShadow="true"
                                    offHandleColor="#e1e2e4"
                                    height={20}
                                    width={48}
                                    className="kt-switch"
                                    id="kt-switch"
                                />

                                <div className="label_inline_switch">
                                    <label className="label_form">Admin</label>
                                </div>
                            </div>
                        </div>

                        <div className="dima_form_single_switch">
                            <div className="switch_block_dima">
                                <Switch
                                    checked={this.state.isIncassator}
                                    onChange={this.handleChangeIsIncassator}
                                    onColor="#acb3ee"
                                    onHandleColor="#5867dd"
                                    handleDiameter={30}
                                    uncheckedIcon={true}
                                    checkedIcon={true}
                                    boxShadow="true"
                                    activeBoxShadow="true"
                                    offHandleColor="#e1e2e4"
                                    height={20}
                                    width={48}
                                    className="kt-switch"
                                    id="kt-switch"
                                />
                                <div className="label_inline_switch">
                                    <label className="label_form">Incassator</label>
                                </div>
                            </div>
                        </div>

                        <div className="dima_form_single_switch">
                            <div className="switch_block_dima">
                                <Switch
                                    checked={this.state.isManager}
                                    onChange={this.handleChangeIsManager}
                                    onColor="#acb3ee"
                                    onHandleColor="#5867dd"
                                    handleDiameter={30}
                                    uncheckedIcon={true}
                                    checkedIcon={true}
                                    boxShadow="true"
                                    activeBoxShadow="true"
                                    offHandleColor="#e1e2e4"
                                    height={20}
                                    width={48}
                                    className="kt-switch"
                                    id="kt-switch"
                                />
                                <div className="label_inline_switch">
                                    <label className="label_form">Manager</label>
                                </div>
                            </div>
                        </div>

                        <div className="btn_form-dima">
                            <Button
                                type="button"
                                onClick={this.handleClear}
                                name="Clear"
                            />

                            <Button
                                type="submit"
                                onClick={this.handleSubmit}
                                className="mitra-button-submit"
                                name="Submit"
                            />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default NewUserForm;
