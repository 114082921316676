import React from 'react';
import 'select2';
import CommissionTable from "./CommissionTable";
import TableHead from "./CommissionTableHead";
import {Link} from "react-router-dom";
import {config} from "../util/constants";
import axios from "axios";
import LoadingPage from "../LoadingPage";
import Button from "../Button";


class CommissionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            field: 'id',
            sort: 'desc',
            bearer: localStorage.getItem("authToken"),
            commissionList: [],
            isLoadedTable: false,
            pages: 0,
            page: 0,
        };
        this.pageHandler = this.pageHandler.bind(this);
        this.timer = null;
        this.handleClick = this.handleClick.bind(this);
    }


    handleClick = sortField => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

            const sortDefaul = this.state.sort === 'asc' ? 'desc' : 'asc';
            this.setState({
                sort: sortDefaul,
                field: sortField,
                page: 0,
                isLoadedTable: false,
            });
            this.sendPaymentRequest()
        }, 1);
    };

    componentDidMount() {
        document.title ='Commissions - mitra processing';
        let request = config.url.API_URL + "/commission";

        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            console.log(result)
            this.setState({
                commissionList: result,
                pages: result.meta.pages,
                isLoadedTable: true
            });
        })
            .catch(e => {
                // localStorage.setItem("auth", false);
                // this.props.setAuth(false);

            });
    }

    pageHandler = ({selected}) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({page: selected})
            this.sendPaymentRequest()
        }, 1)
    };


    render() {
        return (
            <div>
                <div className="kt-portlet kt-portlet--mobile">
                    <form className="kt-form kt-form--label-right">
                        <div className="kt-portlet__body dima_form" style={{backgroundColor: "#9899ac"}}>
                            {/*<div className="dima_heder_list">Commission</div>*/}
                            <div className="form-group row form-group-marginless">
                                <div className="col-lg-4 kt-align-left">
                                    <Link to="/form">
                                        <Button
                                            type="button"
                                            className="mitra-button-submit"
                                            name="New"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <TableHead
                    onSort={this.handleClick}
                    field={this.state.field}
                    sort={this.state.sort}
                />
                {this.state.isLoadedTable === false ? <LoadingPage/> :
                    <CommissionTable
                        commissionList={this.state.commissionList}
                        not={this.state.pages}
                        forcePage={this.state.page}
                        onPageChange={this.pageHandler}
                        pageCount={this.state.pages}
                    />
                }
            </div>
        );
    }
}

export default CommissionList;
