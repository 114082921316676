import React from 'react';
import '../paymentForm/Form.css';
import Switch from "react-switch";
import "./settings.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment-timezone";
import {config} from "../util/constants";
import axios from "axios";
import InternationalSwitch from "../InternationalSwitch/InternationalSwitch";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import LoadingPage from "../LoadingPage";
import Input from "../Input";
import SettingsSuppliers from "../SettingsSuppliers";
import Button from "../Button";

class SettingsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            processingPayments: null,
            acceptancePayments: null,

            bearer: localStorage.getItem("authToken"),
            operatorDict: [],
            country: [],
            monitoringLimits: [],
            suppliers: [],

            startDate: moment().startOf('day'),
            endDate: moment().endOf('day'),
            startDateValue: null,
            endDateValue: null,

            errorStatus: '',
            statusText: '',
            errorMessage: '',
            modalShow: false,
            isLoaded: false,
        };
        this.handleChangeProcessingPayments = this.handleChangeProcessingPayments.bind(this);
        this.handleChangeAcceptancePayments = this.handleChangeAcceptancePayments.bind(this);
        this.RetryPayments = this.RetryPayments.bind(this);
        this.update = this.update.bind(this);
    }

    componentDidMount() {
        document.title ='Settings - mitra processing';

        axios.get(config.url.API_URL + "/settings", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    processingPayments: result.processingPayments,
                    acceptancePayments: result.acceptancePayments,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,

                });
            }
        });

        axios.get(config.url.API_URL + "/country", {
            params: {
                onlyQueue: true
            },
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    country: result,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,

                });
            }
        });

        axios.get(config.url.API_URL + "/settings/getSuppliers", {
            params: {
                onlyQueue: true
            },
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    suppliers: result,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    };

    update(){
       this.componentDidMount();
    };

    RetryPayments(event, picker) {
        console.log(picker.startDate)
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate
        })
        this.sendRetryPayments();
    }
    sendRetryPayments() {
        const request = config.url.API_URL + "/settings/retryPayments" +
            "?from=" + this.state.startDate +
            "&to=" + this.state.endDate;

        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            let result = res.data;
            let statusText = result.desc;
            let errorStatus = res.status;
            this.setState({
                statusText,
                errorStatus,
                modalShow: true,
            })
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

    }

    handleChangeProcessingPayments(checked) {
        this.setState({
            processingPayments: checked
        });
        this.sendProcessingPaymentsSwitch();
    };

    sendProcessingPaymentsSwitch() {
        const request = config.url.API_URL + "/settings/processingPaymentsSwitch" +
            "?enable=" + this.state.processingPayments;

        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            let result = res.data;
            let statusText = result.desc;
            let errorStatus = res.status;
            this.setState({
                statusText,
                errorStatus,
                modalShow: true,
            })
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                    processingPayments: !this.state.processingPayments
                });
            }
        });

    }

    handleChangeAcceptancePayments(checked) {
        this.setState({
            acceptancePayments: checked,
        });
        this.sendAcceptancePaymentsSwitch();
    };

    sendAcceptancePaymentsSwitch() {

        const request = config.url.API_URL + "/settings/acceptancePaymentsSwitch" +
            "?enable=" + this.state.acceptancePayments;
        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            let result = res.data;
            let statusText = result.desc;
            let errorStatus = res.status;
            this.setState({
                statusText,
                errorStatus,
                modalShow: true,
            })
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                    acceptancePayments: !this.state.acceptancePayments
                });
            }
        });

    }

    renderRowOne() {
        const arr = this.state.country;
        if (arr.length > 0) {
            return arr.slice(0, 24)
        } else
            return arr

    }

    renderRowTwo() {
        const arr = this.state.country;
        if (arr.length > 0) {
            return arr.slice(24, 48)
        } else
            return arr

    }

    renderRowThree() {
        const arr = this.state.country;
        if (arr.length > 0) {
            return arr.slice(48, 72)
        } else
            return arr

    }

    renderRowFour() {
        const arr = this.state.country;
        if (arr.length > 0) {
            return arr.slice(72, 96)
        } else
            return arr

    }

    render() {
        let modalClose = () => {this.setState({modalShow: false}); this.update()}
        return (
            <div>
                <MyVerticallyCenteredModal
                    h4={this.state.errorMessage}
                    text={this.state.errorStatus}
                    show={this.state.modalShow}
                    onHide={modalClose}
                />
                {this.state.isLoaded < 3 ? <LoadingPage/> :
                    <div className="kt-portlet kt-portlet--mobile kt-portlet_settings">
                        {/*Retry payments*/}
                        <div className="settings_bloc">
                            <h1 className="paragraf_settings">Retry payments</h1>
                            <div className="cell_settings_payments">
                                <div className="col-lg-4">
                                    <DateRangePicker
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        linkedCalendars={false}
                                        timePicker24Hour={true}
                                        opens={"right"}
                                        applyClass={"btn-primary"}
                                        onApply={this.RetryPayments}
                                        timePicker={true}
                                        timePickerIncrement={30}
                                        locale={{format: "DD.MM.YYYY HH:mm"}}
                                        containerStyles={{
                                            width: "100%",
                                            backgroundColor: "#d6d7ea",
                                            borderRadius: "30px"
                                        }}
                                    >
                                        <Input
                                            type="text"
                                            readOnly
                                            placeholder="Today"
                                            defaultValue={this.state.startDateValue ? this.state.startDateValue + ' to ' + this.state.endDateValue : ''}
                                        />
                                    </DateRangePicker>

                                </div>
                                <div className="col-lg-4 kt-align-left">
                                    <Button
                                        type="button"
                                        className="mitra-button-submit"
                                        name="Submit"
                                    />
                                </div>
                            </div>
                        </div>
                        {/*Payments control*/}
                        <div className="settings_bloc">
                            <h1 className="paragraf_settings">Payments control</h1>
                            <div className="cell_settings_payments">
                                <Switch
                                    checked={this.state.acceptancePayments}
                                    onChange={this.handleChangeAcceptancePayments}
                                    onColor="#acb3ee"
                                    onHandleColor="#5867dd"
                                    handleDiameter={30}
                                    uncheckedIcon={true}
                                    checkedIcon={true}
                                    boxShadow="true"
                                    activeBoxShadow="true"
                                    offHandleColor="#e1e2e4"
                                    height={20}
                                    width={48}
                                    className="kt-switch"
                                    id="kt-switch"
                                />
                                <div className="label_inline_switch_settings">
                                    <label className="label_settings">Accept payments</label>
                                </div>


                                <Switch
                                    checked={this.state.processingPayments}
                                    onChange={this.handleChangeProcessingPayments}
                                    onColor="#acb3ee"
                                    onHandleColor="#5867dd"
                                    handleDiameter={30}
                                    uncheckedIcon={true}
                                    checkedIcon={true}
                                    boxShadow="true"
                                    activeBoxShadow="true"
                                    offHandleColor="#e1e2e4"
                                    height={20}
                                    width={48}
                                    className="kt-switch"
                                    id="kt-switch"
                                />
                                <div className="label_inline_switch_settings">
                                    <label className="label_settings">Retry payments</label>
                                </div>

                            </div>
                        </div>
                        {/*Suppliers control and minimal limits*/}
                        <div className="settings_bloc">
                            <h1 className="paragraf_settings">Suppliers control and minimal limits</h1>
                            <div className="cell_settings">
                                {this.state.suppliers.map(index => (
                                    <SettingsSuppliers
                                        update={this.update}
                                        id={index.id}
                                        minBalance={index.minBalance}
                                        name={index.name}
                                        enable={index.enable}
                                    />
                                ))}
                            </div>
                        </div>
                        {/*International*/}
                        < div className="settings_bloc_bottom">
                            <h1 className="paragraf_settings">International</h1>
                            <div className="cell_settings_international">
                                <table className="switch_table_dima">
                                    <tbody>
                                    {this.renderRowOne().map(item => (

                                        <tr
                                            key={item.id}

                                        >
                                            <td>
                                                <div className="switch_block_dima_settings">
                                                    <InternationalSwitch
                                                        enable={item.enable}
                                                        id={item.id}
                                                        name={item.shortName}
                                                    />

                                                    <div
                                                        className="label_inline_switch_settings label_inline_switch_country">
                                                        <label className="label_form_switch_country">
                                                            {item.shortName}
                                                        </label>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>

                                    ))}

                                    </tbody>
                                </table>
                                <table className="switch_table_dima">
                                    <tbody>
                                    {this.renderRowTwo().map(item => (

                                        <tr
                                            key={item.id}

                                        >
                                            <td>
                                                <div className="switch_block_dima_settings">
                                                    <InternationalSwitch
                                                        enable={item.enable}
                                                        id={item.id}
                                                        name={item.shortName}
                                                    />
                                                    <div
                                                        className="label_inline_switch_settings label_inline_switch_country">
                                                        <label className="label_form_switch_country">
                                                            {item.shortName}
                                                        </label>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>

                                    ))}

                                    </tbody>
                                </table>
                                <table className="switch_table_dima">
                                    <tbody>
                                    {this.renderRowThree().map(item => (

                                        <tr
                                            key={item.id}

                                        >
                                            <td>
                                                <div className="switch_block_dima_settings">
                                                    <InternationalSwitch
                                                        enable={item.enable}
                                                        id={item.id}
                                                        name={item.shortName}
                                                    />
                                                    <div
                                                        className="label_inline_switch_settings label_inline_switch_country">
                                                        <label className="label_form_switch_country">
                                                            {item.shortName}
                                                        </label>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>

                                    ))}

                                    </tbody>
                                </table>
                                <table className="switch_table_dima">
                                    <tbody>
                                    {this.renderRowFour().map(item => (

                                        <tr
                                            key={item.id}

                                        >
                                            <td>
                                                <div className="switch_block_dima_settings">
                                                    <InternationalSwitch
                                                        enable={item.enable}
                                                        id={item.id}
                                                        name={item.shortName}
                                                    />
                                                    <div
                                                        className="label_inline_switch_settings label_inline_switch_country">
                                                        <label className="label_form_switch_country">
                                                            {item.shortName}
                                                        </label>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default SettingsList;
