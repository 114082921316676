import React from 'react';
import 'select2';
import SimCardTable from "./SimCardTable";
import LoadingPage from "../LoadingPage";
import TableHead from "./SimCardTableHead";
import {Link} from "react-router-dom";
import {config} from "../util/constants";
import axios from "axios";
import Input from "../Input";
import Select2 from "react-select2-wrapper";
import Button from "../Button";


class SimCardList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bearer: localStorage.getItem("authToken"),
            error: null,
            isLoaded: false,
            isLoadedTable: false,

            field: 'id',
            sort: 'desc',

            simcardList: [],

            searchValue: '',

            operatorDict: [],
            operatorValue: [],

            terminalDict: [],
            terminalValue: [],

            pages: 0,
            page: 0
        };
        this.handleChangeOperator = this.handleChangeOperator.bind(this);
        this.handleChangeTerminals = this.handleChangeTerminals.bind(this);
        this.handleSearc = this.handleSearc.bind(this);
    }


    handleClearClick = (e) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                searchValue: '',
                terminalValue: [],
                operatorValue: [],
                page: 0
            })
            this.sendSimcardRequest()
        }, 1);
    }

    // handleSearc = (e) => {
    //     const value = e.target.value; // cached!
    //     this.setState({
    //         searchValue: value,
    //         page: 0
    //     });
    //     clearTimeout(this.timer);
    //     this.timer = setTimeout(() => {
    //
    //         this.sendSimcardRequest()
    //     }, 1);
    // }

    handleClick = sortField => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

            const sortDefaul = this.state.sort === 'asc' ? 'desc' : 'asc';
            this.setState({
                sort: sortDefaul,
                field: sortField,
                page: 0,
                isLoadedTable: false,
            })
            this.sendSimcardRequest()
        }, 1);
    }

    handleChangeOperator(e) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

            this.setState({
                operatorValue: this.getOption(e),
                page: 0,
                isLoadedTable: false,
            })
            this.sendSimcardRequest()
        }, 1);
    }

    handleChangeTerminals(e) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

            this.setState({
                terminalValue: this.getOption(e),
                page: 0,
                isLoadedTable: false,
            })
            this.sendSimcardRequest()
        }, 1);
    }

    handleSearc(e) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

            this.setState({
                searchValue: this.getOption(e),
                page: 0,
                isLoadedTable: false,
            })
            this.sendSimcardRequest()
        }, 1);
    }


    sendSimcardRequest() {
        const request = config.url.API_URL + "/SIMCard\n" +
            "?search=" + this.state.searchValue +
            "&terminal=" + this.state.terminalValue +
            "&operator=" + this.state.operatorValue +
            "&field=" + this.state.field +
            "&sort=" + this.state.sort +
            "&page=" + this.state.page;

        axios.get((request), {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    simcardList: result.data,
                    pages: result.meta.pages,
                    isLoadedTable: true

                });
            }
        );
    }

    getOption(event) {
        let options = event.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        return value
    }

    componentDidMount() {
        document.title ='SIM - mitra processing';

        axios.get(config.url.API_URL + '/dictionary/terminalDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        })
            .then(res => {
                    const result = res.data;
                    this.setState({
                        terminalDict: result
                    });
                },
            )
            .catch(e => {
                localStorage.setItem("auth", false);
                this.props.setAuth(false);

            });

        axios.get(config.url.API_URL + '/dictionary/operatorDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    operatorDict: result
                });
            },
        ).catch(e => {
            localStorage.setItem("auth", false);
            this.props.setAuth(false);

        });
    }

    pageHandler = ({selected}) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({page: selected})
            this.sendSimcardRequest()
        }, 1)
    }


    render() {
        const {
            searchValue, terminalDict, terminalValue, operatorDict, operatorValue
        } = this.state
        return (
            <div>
                <div className="kt-portlet kt-portlet--mobile">
                    <form className="kt-form kt-form--label-right">
                        <div className="kt-portlet__body dima_form" style={{backgroundColor: "#9899ac"}}>
                            <div className="form-group row form-group-marginless">
                                <div className="col-lg-4 col-md-9 col-sm-12">
                                    <Input
                                        type="text"
                                        value={searchValue}
                                        placeholder="Phone"
                                        id="generalSearch"
                                        onChange={this.handleSearc}
                                    />
                                </div>

                                <div className="col-lg-4 kt-align-left">
                                    <Select2
                                        multiple
                                        className="form-control kt-select2"
                                        style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                        id="terminalSelect"
                                        data={terminalDict}
                                        value={terminalValue}
                                        onChange={this.handleChangeTerminals}
                                        options={{placeholder: "Terminal"}}
                                    />
                                </div>

                                <div className="col-lg-4 kt-align-left">
                                    <Button
                                        type="button"
                                        onClick={this.handleClearClick}
                                        name="Clear"
                                    />
                                </div>
                            </div>

                            <div className="form-group row form-group-marginless kt-margin-t-20">
                                <div className="col-lg-4 kt-align-left">
                                    <Select2
                                        multiple
                                        className="form-control kt-select2"
                                        style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                        id="OperatorSelect"
                                        data={operatorDict}
                                        value={operatorValue}
                                        onChange={this.handleChangeOperator}
                                        options={{placeholder: "Operator"}}
                                    />
                                </div>
                                <div className="col-lg-4 kt-align-right">
                                </div>
                                <div className="col-lg-4 kt-align-left">

                                    <Link to="/SIMCard/new"
                                          target="_blank"
                                    >
                                        <Button
                                            type="button"
                                            className="mitra-button-submit"
                                            name="New"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <TableHead
                    onSort={this.handleClick}
                    field={this.state.field}
                    sort={this.state.sort}
                />
                {this.state.isLoadedTable === false ? <LoadingPage/> :
                    <SimCardTable
                        simcardList={this.state.simcardList}
                        errorCss={this.state.errorStatus}
                        not={this.state.pages}
                        forcePage={this.state.page}
                        onPageChange={this.pageHandler}
                        pageCount={this.state.pages}
                    />
                }
            </div>
        );
    }
}

export default SimCardList;
