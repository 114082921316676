import React from 'react';
import './Monitoring.css'
import "./monitoringIncassator.css"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import PopoverTitle from "react-bootstrap/PopoverTitle";
import PopoverContent from "react-bootstrap/PopoverContent";


function MonitoringTableIncass(props) {

    return (
        <div style={{width: "100%"}}>
            {props.list.map(index => (
                <table className={"mitra-popover-content-list-incass"}>
                    {/*<tr className={"mitra-table-monitoring-header-row-incass"}>*/}
                    {/*    <th style={{padding: "30px 20px 50px 50px"}}>{index.incassator ? (index.incassator + " - " + index.description) : 'N/A'}</th>*/}
                    {/*</tr>*/}
                    <tr className={'mitra-table-monitoring-row'}>
                        {index.data.map(item => (
                            <td className={'mitra-table-monitoring-cell'}>
                                <OverlayTrigger
                                    trigger={["click", "hover"]}
                                    placement="top"
                                    delay={{show: 900000, hide: 5000}}
                                    overlay={
                                        <Popover>
                                            <PopoverTitle>
                                                <h3 className={"mitra-popover-title-incass"}>{item.billAmount}</h3>
                                            </PopoverTitle>
                                            <PopoverContent>
                                                <ul className={"mitra-popover-content-list-incass"}>
                                                    <li>{item.place ? item.place : 'N/A'}</li>
                                                    <li>{item.phone ? item.phone : 'N/A'}</li>
                                                </ul>
                                            </PopoverContent>
                                        </Popover>
                                    }
                                >
                                    <button
                                        className={
                                            item.status === 0 ? "mitra-button-monitoring-incass monitoring-ok" :
                                                item.status === 1 ? "mitra-button-monitoring-incass monitoring-noPayments" :
                                                    item.status === 2 ? "mitra-button-monitoring-incass monitoring-oneFalied" :
                                                        item.status === 3 ? "mitra-button-monitoring-incass monitoring-allFailed" :
                                                            item.status === 4 ? "mitra-button-monitoring-incass monitoring-offline" : ""
                                        }>{item.name ? item.name : 'N/A'}</button>
                                </OverlayTrigger>
                            </td>
                        ))}
                    </tr>

                </table>))}

        </div>
    )
}

export default MonitoringTableIncass;