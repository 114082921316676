import axios from "axios";
import {config} from "./constants";
import {getToken, setAuth} from "./auth";

export function sendGET(url, params, setState, history, setLoading) {
    axios.get(url, {
        baseURL: config.url.API_URL,
        headers: {
            Authorization: getToken()
        },
        params: params
    }).then(res => {
        setState(res.data);
        if (setLoading !== undefined || setLoading !== null) {
            setLoading(false)
        }
    }).catch(e => {
        console.log(e)
        if (e.request.status === 401) {
            setAuth(false)
            if (history !== undefined || history !== null) {
                history.push("/login");
            }
        }
    });
}
export function sendPIN(id, setState, history) {
    axios.get("/payment/" + id + '/getPIN', {
        baseURL: config.url.API_URL,
        headers: {
            Authorization: getToken()
        },
    }).then(res => {
        console.log('res',res)
        setState(res);

    }).catch(e => {
        console.log(e)
        if (e.request.status === 401) {
            setAuth(false)
            if (history !== undefined || history !== null) {
                history.push("/login");
            }
        }
    });
}

export function sendGETnoParams(url, setState) {
    axios.get(url, {
        baseURL: config.url.API_URL,
        headers: {
            Authorization: getToken()
        },
    }).then(res => {
        setState(res.data);
        // if (setLoading !== undefined || setLoading !== null) {
        //     setLoading(false)
        // }
    }).catch(e => {
        console.log(e)
        if (e.request.status === 401) {
            setAuth(false)
            // if (history !== undefined || history !== null) {
            //     history.push("/login");
            // }
        }
    });
}

export function sendPOST(url, params, data, setState, history) {
    axios.post(url, data, {
        baseURL: config.url.API_URL,
        headers: {
            Authorization: getToken()
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        },
        params: params
    }).then(res => {
        console.log(res)
        setState(res.data);
    }).catch(e => {
        console.log(e)
        if (e.request.status === 401) {
            setAuth(false)
            if (history != null) {
                history.push("/login");
            }
        }
    });
}

export function sendPOSTdict(url, data, setState, history) {
    axios.post(url, data, {
        baseURL: config.url.API_URL,
        headers: {
            Authorization: getToken()
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        },
    }).then(res => {
        console.log(res)
        setState(res);
    }).catch(e => {
        console.log(e)
        if (e.request.status === 401) {
            setAuth(false)
            if (history != null) {
                history.push("/login");
            }
        }
    });
}

export function sendReport(url, data, setState, history) {
    axios.post(url, data, {
        baseURL: config.url.API_URL,
        headers: {
            Authorization: getToken()
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        },
    }).then(res => {
        console.log(res)
        setState(res.data);
    }).catch(e => {
        console.log(e)
        if (e.request.status === 401) {
            setAuth(false)
            if (history != null) {
                history.push("/login");
            }
        }
    });
}

export function sendPUT(url, data, history, setError, setDisabled, setModalShow) {
    axios.put(url, data, {
        baseURL: config.url.API_URL,
        headers: {
            Authorization: getToken()
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        },
    }).then(res => {
        console.log(res)
        setError(res)
        setDisabled(false)
        setModalShow(true)
        // history.push("/payment/"+ res.data.id)
    }).catch(e => {
        console.log(e)
        if (e.request.status === 401) {
            setAuth(false)
            if (history != null) {
                history.push("/login");
            }
        } else {
            setError(e)
            setDisabled(false)
            setModalShow(true)
        }
    });
}

export async function sendSimplePOST(url, params, data) {
    return await axios.post(url, data, {
        baseURL: config.url.API_URL,
        headers: {
            Authorization: getToken()
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        },
        params: params
    }).then(res => {
        console.log(res)
        return res.data
    }).catch(e => {
        console.log(e)
        if (e.request.status === 401) {
            setAuth(false)
            return 'wrong username or password'
        }
    });
}

export async function sendDictGET(url, history) {
    return await axios.get(url, {
        baseURL: config.url.API_URL,
        headers: {
            Authorization: getToken()
        },
    }).then(res => {
        return res.data;
    }).catch(e => {
        console.log(e)
        if (e.request.status === 401) {
            setAuth(false)
            history.push("/login");
        }
    });
}

export async function sendDelete(url, history, setRequest, setModalShow) {
    axios.delete(url, {
        baseURL: config.url.API_URL,
        headers: {
            Authorization: getToken()
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        },
    }).then(res => {
        console.log(res)
        setRequest(res)
        setModalShow(true)
        // history.push("/payment/"+ res.data.id)
    }).catch(e => {
        console.log(e)
        if (e.request.status === 401) {
            setAuth(false)
            if (history != null) {
                history.push("/login");
            }
        } else {
            setRequest(e)
            setModalShow(true)
        }
    });

}
