import React, {useState} from "react";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Input from "../Input";
import Select2 from "react-select2-wrapper";
import Button from "../Button";
import {useHistory} from "react-router-dom";
import {sendPUT} from "../util/sendRequest";

export default function ListNew() {
    document.title = 'New list - mitra processing';

    const dataDefault = {
        account : '',
        type : 0
    }
    const [error, setError] = useState(null);
    const [data, setData] = useState(dataDefault);
    const history = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [disabled, setDisabled] = useState(false);


    const inputChange = (event) => {
        event.persist();
            setData({...data, [event.target.id]: event.target.value})
    }

    const handelChangeSelect = (event) => {
        setData( ({...data, [event.target.name]: event.target.value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setDisabled(true);
        sendPUT("list", data, history, setError, setDisabled, setModalShow)
    }
    const clear = () => {
        setData(dataDefault);
    };
    const modalClose = () => setModalShow(!modalShow);

    return (
        <>
            {console.log(data)}
            <div className="kt-portlet kt-portlet--mobile">
                <MyVerticallyCenteredModal
                    h4={error ? error.data.desc : 'error'}
                    text={error ? error.data.msg : 'error'}
                    show={modalShow}
                    onHide={modalClose}
                />
                <form className="mitra-form">
                    {/*<div className="dima_heder">Payment: {this.state.id ? this.state.id : 'N/A'}</div>*/}
                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Account</label>
                        </div>

                        <div className="input_form_single">
                            <Input
                                name={"account"}
                                id="account"
                                type="text"
                                value={data.account}
                                onChange={inputChange}
                            />
                        </div>
                    </div>


                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Type</label>
                        </div>
                        <Select2
                            className="form-control kt-select2"
                            style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                            id="type"
                            name={"type"}
                            data={JSON.parse(localStorage.getItem("listTypeDict"))}
                            value={data.type}
                            onChange={handelChangeSelect}
                            // options={{placeholder: "Type"}}
                        />
                    </div>

                    <div className="btn_form-dima">

                        <Button
                            disabled={disabled}
                            type="button"
                            id="clear"
                            name="Clear"
                            onClick={clear}
                        />

                        <Button
                            disabled={disabled}
                            type="submit"
                            id="save"
                            name="Save"
                            onClick={handleSubmit}
                            className="mitra-button-submit"
                        />
                    </div>
                </form>
            </div>
        </>
    );
}

