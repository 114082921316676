import React from "react";
import './Table.css'

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table className="kt-datatable__table">

            <thead className="kt-datatable__head" style={{borderBottom: "solid"}}>
            <tr className="kt-datatable__row">


                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'id')}
                >
                    <span style={{width: "100px"}}>
                       ID
                        {props.field === 'id' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'terminal')}
                >
                    <span style={{width: "200px"}}>
                       Terminal
                        {props.field === 'terminal' ? (props.sort === "asc") ?
                            <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'type')}
                >
                    <span style={{width: "200px"}}>
                       Type
                        {props.field === 'type' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'done')}
                >
                    <span style={{width: "200px"}}>
                       Done
                        {props.field === 'done' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'parameter')}
                >
                    <span style={{width: "300px"}}>
                       Parameter
                        {props.field === 'parameter' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'date')}
                >
                    <span style={{width: "300px"}}>
                       Date
                        {props.field === 'date' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
            </tr>
            </thead>
        </table>
    </div>
)

