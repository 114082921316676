import React from "react";
import Aside from "../aside/Aside";
import {Switch} from "react-router-dom";
import RoleRoute from "../../routers/RoleRoute";
import PlaceForm from "./PlaceForm";
import PlaceList from "./PlaceList";
import NewPlaceForm from "./NewPlaceForm";

export default function PlaceRoute( {match} ) {
    return (
        <>
            <Aside/>
            <Switch>
                <RoleRoute
                    path={`${match.path}/new`}
                    component={NewPlaceForm}
                />
                <RoleRoute
                    path={`${match.path}/:id`}
                    component={PlaceForm}
                />
                <RoleRoute
                    path={`${match.path}/`}
                    component={PlaceList}
                />
            </Switch>
        </>
    )
}