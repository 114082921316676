import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Button from "../Button";
import Input from "../Input";
import Select2 from "react-select2-wrapper";
import {sendPUT} from "../util/sendRequest";

export default function PaymentNew() {

    document.title = 'New payment - mitra processing';

    const history = useHistory();
    const dataDefault = {
        id: null,
        ticket: '',
        sum: 0,
        amount: 0,
        commission: 0,
        repeats: null,
        note: '',
        extended1: '',
        extended2: '',
        operatorResponse: '',
        transactionId: null,
        operatorName: '',
        phone: '',
        operator: 0,
        status: 0,
        place: 0,
        terminal: 0,
        currencyIn: null,
        currencyOut: null,
        provider: null,
        country: null,
        receive: null,
        receiveCurrency: null,
        service: 1,
        chanel: 3,
    };
    const [data, setData] = useState(dataDefault);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState(null);
    const [modalShow, setModalShow] = useState(false);

    // payment: '',
    //     response: [],
    //     disabled: false,
    //     error: null,
    //     redirect: false,
    //     isLoaded: false,

    const inputChange = (event) => {
        event.persist();
        if (event.target.id === "sum") {
            setData({...data, [event.target.id]: event.target.value >= 0 ? event.target.value : 0})
        } else {
            setData({...data, [event.target.id]: event.target.value})
        }
    }
    const handelChangeSelect = (event) => {
        setData( ({...data, [event.target.name]: event.target.value}));
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        setDisabled(true);
        console.log(data)
        sendPUT("payment", data, history, setError, setDisabled, setModalShow)
    }

    const clear = () => {
        setData(dataDefault);
    };
    const modalClose = () => setModalShow(!modalShow);
    return (
        <div className="kt-portlet kt-portlet--mobile">
            <MyVerticallyCenteredModal
                h4={error ? error.response.data.status : 'error'}
                text={error ? error.response.data.message : 'error'}
                show={modalShow}
                onHide={modalClose}
            />
            <form className="mitra-form">
                <div className="dima_heder">New payment</div>
                <div className="dima_form_single">
                    <div className="label_inline">
                        <label className="label_form">Account</label>
                    </div>

                    <div className="input_form_single">
                        <Input
                            type="text"
                            value={data.phone}
                            id="phone"
                            onChange={inputChange}
                        />
                    </div>
                </div>

                <div className="dima_form_single">
                    <div className="label_inline">
                        <label className="label_form">Supplier</label>
                    </div>
                    <div className="input_form_single" id='operatorSelect'>
                        <Select2
                            id="operator"
                            name="operator"
                            className="mitra-select"
                            data={JSON.parse(localStorage.getItem("operatorDict"))}
                            value={data.operator}
                            onChange={handelChangeSelect}
                        />
                    </div>
                </div>

                <div className="dima_form_single">
                    <div className="label_inline">
                        <label className="label_form">Terminal</label>
                    </div>
                    <div className="input_form_single">
                        <Select2
                            id="terminal"
                            name="terminal"
                            data={JSON.parse(localStorage.getItem("terminalDict"))}
                            value={data.terminal}
                            className="mitra-select"
                            onChange={handelChangeSelect}

                        />
                    </div>
                </div>
                <div className="dima_form_single_currency">
                    <div className="label_inline ">
                        <label className="label_form">Got</label>
                    </div>
                    <div className=" input_form_single_currency">
                        <Input
                            step="0,01"
                            type="number"
                            min="0"
                            value={data.sum}
                            // className={this.state.sum <= 0 ? 'mitra-invalid' : ''}
                            placeholder={''}
                            id="sum"
                            onChange={inputChange}
                        />
                    </div>
                    <div className='dima_input_form_single_select_currency'>
                        <Select2
                            id="currencyIn"
                            name="currencyIn"
                            data={JSON.parse(localStorage.getItem("currencyDict"))}
                            value={data.currencyIn}
                            className="input_form_single_select_currency"
                            onChange={handelChangeSelect}

                        />
                    </div>
                </div>

                <div className="dima_form_single">
                    <div className="label_inline">
                        <label className="label_form">Service</label>
                    </div>
                    <div className="input_form_single">
                        <Select2
                            className="mitra-select"
                            id="service"
                            name="service"
                            data={JSON.parse(localStorage.getItem("serviceDict"))}
                            value={data.service}
                            onChange={handelChangeSelect}
                        />

                    </div>
                </div>
                <div className="btn_form-dima">
                    <Button
                        disabled={disabled}
                        type="submit"
                        id="save"
                        name="Save"
                        onClick={handleSubmit}
                        className="mitra-button-submit"
                    />
                    <Button
                        type="button"
                        id="clear"
                        name="Clear"
                        onClick={clear}
                    />
                </div>
            </form>
        </div>
    );
}

