import React from "react";
import {Redirect, Route} from "react-router-dom";
import {getRoles} from "../components/util/auth";


export default function  RoleRoute({component: Component, ...rest}) {
    return (
        <Route {...rest} render={props => (
            getRoles(["SA"]) || getRoles(["ADMIN"]) || getRoles(["MANAGER"] || localStorage.getItem("user") === "cake") ?
                <Component {...props} />
                : <Redirect to="/login" />
        )} />
    );
};