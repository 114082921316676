import React, {useEffect, useState} from 'react';
import 'select2';
import PaymentTable from "./PaymentTable";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import LoadingPage from "../LoadingPage";
import TableHead from "./TableHead";
import {Link, useHistory} from "react-router-dom";
import Button from "../Button";
import Input from "../Input";
import Select2 from "react-select2-wrapper";
import {sendGET, sendGETnoParams} from "../util/sendRequest";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";

export default function PaymentList() {

    document.title = 'Payments - mitra processing';

    const defaultParams = {
        page: 0,
        perPage: 100,
        sort: "desc",
        field: "id",
        search: "",
        status: [],
        operator: [],
        place: [],
        terminal: [],
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
    }
    const history = useHistory();
    const [list, setList] = useState([{}]);
    const badPaymentStatus = JSON.parse(localStorage.getItem("badPaymentStatus"));
    const [loading, setLoading] = useState(0);
    const [params, setParams] = useState(defaultParams);
    const [retry, setRetry] = useState(null);
    const [defaultValue, setDefaultValue] = useState(true);
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        sendGET("/payment?search=" + params.search +
            "&status=" + params.status +
            "&terminal=" + params.terminal +
            "&operator=" + params.operator +
            "&place=" + params.place +
            "&field=" + params.field +
            "&sort=" + params.sort +
            "&startDate=" + params.startDate.format('x') +
            "&endDate=" + params.endDate.format('x') +
            "&page=" + params.page,
            null, setList, history, setLoading);
    }, [params, history, retry])
    const handelChange = (event) => {
        event.persist();
        setParams(params => ({...params, search: event.target.value, page: 0}));
    }
    const handelSort = (id) => {
        let sortDefault = "desc"
        if (params.sort === sortDefault) {
            sortDefault = "asc"
        } else {
            sortDefault = "desc"
        }
        setParams({...params, sort: sortDefault, field: id})
    }
    const handleChangeDate = (event, picker) => {
        setDefaultValue(false)
        setParams(params => ({...params, startDate: picker.startDate}));
        setParams(params => ({...params, endDate: picker.endDate}));
    }
    const handelChangeSelect = (event) => {
        let arr = getOption(event)
        setParams(params => ({...params, [event.target.name]: arr, page: 0}));
    }

    function getOption(event) {
        let options = event.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        return value
    }

    const Clear = () => {
        setParams(defaultParams)
        setDefaultValue(true);
    }
    const Check = () => {
        setParams({...params, status: badPaymentStatus, page: 0});
    }
    const Retry = (id) => {
        sendGETnoParams("/payment/" + id + "/reprocessing", setRetry)
        setModalShow(true);
        console.log("Retry", retry)
    }
    const pageHandler = ({selected}) => {
        setParams(params => ({...params, page: selected}));

    };
    const modalClose = () => {
        setModalShow(false);
    };

    return (
        <div>
            <MyVerticallyCenteredModal
                text={retry ? retry.msg : ""}
                show={modalShow}
                onHide={modalClose}
            />
            {console.log('List', list)}
            {console.log('params', params)}
            {/*FORM SEARC*/}
            <div className="kt-portlet kt-portlet--mobile">
                <form className="kt-form kt-form--label-right">
                    <div className="kt-portlet__body dima_form" style={{backgroundColor: "#9899ac"}}>
                        <div className="form-group row form-group-marginless">
                            <div className="col-lg-4 col-md-9 col-sm-12 dima_pravka">
                                <Input
                                    type="text"
                                    name="search"
                                    value={params.search}
                                    placeholder="Search"
                                    id="search"
                                    onChange={handelChange}
                                />
                            </div>

                            <div className="col-lg-4 col-md-9 col-sm-12 dima_pravka">
                                <Select2
                                    multiple
                                    id="status"
                                    name="status"
                                    value={params.status}
                                    data={JSON.parse(localStorage.getItem("statusDict"))}
                                    onChange={handelChangeSelect}
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    options={{placeholder: "Status"}}
                                />
                            </div>

                            <div className="col-lg-4 kt-align-left">
                                <Button
                                    type="button"
                                    name="Clear"
                                    id="newPayment"
                                    onClick={Clear}
                                />
                            </div>
                        </div>

                        <div className="form-group row form-group-marginless kt-margin-t-20">
                            <div className="col-lg-4 kt-align-left">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="place"
                                    name="place"
                                    data={JSON.parse(localStorage.getItem("placeDict"))}
                                    value={params.place}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "Agent"}}
                                />
                            </div>
                            <div className="col-lg-4 kt-align-left">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="operator"
                                    name="operator"
                                    data={JSON.parse(localStorage.getItem("operatorDict"))}
                                    value={params.operator}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "Operator"}}
                                />
                            </div>

                            <div className="col-lg-4 kt-align-left">
                                <Link to="payment/new"
                                      target="_blank"
                                >
                                    <Button
                                        type="button"
                                        className="mitra-button-submit"
                                        name="New"
                                        id="newPayment"
                                    />
                                </Link>
                            </div>
                        </div>

                        <div className="form-group row form-group-marginless kt-margin-t-20">
                            <div className="col-lg-4 col-md-9 col-sm-12">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="terminal"
                                    name="terminal"
                                    data={JSON.parse(localStorage.getItem("terminalDict"))}
                                    value={params.terminal}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "Terminal"}}
                                />
                            </div>
                            <div className="col-lg-4">
                                <DateRangePicker
                                    startDate={params.startDate}
                                    endDate={params.endDate}
                                    linkedCalendars={false}
                                    timePicker24Hour={true}
                                    timePicker={true}
                                    opens={"left"}
                                    applyClass={"btn-primary"}
                                    onApply={handleChangeDate}
                                    timePickerIncrement={1}
                                    containerStyles={{
                                        width: "100%",
                                        backgroundColor: "#d6d7ea",
                                        borderRadius: "30px"
                                    }}
                                >
                                    <Input
                                        type="text"
                                        readOnly
                                        placeholder={"Today"}
                                        value={defaultValue ? "" : params.startDate.format("DD.MM.YYYY HH:mm") + ' to ' + params.endDate.format("DD.MM.YYYY HH:mm")}
                                    />
                                </DateRangePicker>
                            </div>
                            <div className="col-lg-4 kt-align-left">
                                <Button
                                    type="button"
                                    name="Check"
                                    className="mitra-button-cancel"
                                    id="newPayment"
                                    onClick={Check}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <TableHead
                onSort={handelSort}
                field={params.field}
                sort={params.sort}
            />
            {loading === 0 ? <LoadingPage/> :
                <PaymentTable
                    retray={Retry}
                    paymentList={list.data}
                    errorCss={badPaymentStatus}
                    not={list.meta.pages}
                    forcePage={list.meta.page - 1}
                    onPageChange={pageHandler}
                    pageCount={list.meta.pages}
                />
            }

        </div>

    )
}

