import React from 'react';
import ReactPaginate from "react-paginate";
import moment from 'moment';
import {Link} from "react-router-dom";

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">

        <table className="kt-datatable__table" style={{width: "100%"}}>
            {props.not === 0 ? <h1 className='noRecordFound'>No record found</h1> :

                <tbody
                    className="kt-datatable__body"
                    style={{display: "block", fontFamily: "Heebo, sans-serif"}}>
                {props.terminalList.map(item => (

                    <tr className="kt-datatable__row"
                        style={item.monitoringStatus > 1 && (item.id > 1000002) ? {backgroundColor: "#d22038",} : {}}
                        key={item.id}

                    >
                        <td className="kt-datatable__cell">
                            <span
                                style={item.monitoringStatus > 1 && (item.id > 1000002) ?
                                    {color: "#ffffff",width: "80px"}:{color: "#6C7293", width: "80px"}}

                            >
                               <Link to={"/terminal/" + item.id}
                                     target="_blank"
                                     style={item.monitoringStatus > 1 && (item.id > 1000002) ?
                                         {color: "#ffffff"} : {color: "#6C7293"}}
                               >{item.name ? item.name : 'N/A'}  </Link>
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={item.monitoringStatus > 1 && (item.id > 1000002) ?
                                    {color: "#ffffff",width: "80px"}: {color: "#6C7293", width: "80px"}}
                            >
                                {item.city ? item.city : 'N/A'}
                            </span>
                        </td>

                        {/*<td className="kt-datatable__cell">*/}
                        {/*    <span*/}
                        {/*        style={item.monitoringStatus > 1 && (item.id > 1000002) ?*/}
                        {/*            {color: "#ffffff",width: "70px"}: {color: "#6C7293", width: "70px"}}*/}
                        {/*    >*/}
                        {/*        {item.area ? item.area : 'N/A'}*/}

                        {/*    </span>*/}
                        {/*</td>*/}

                        <td className="kt-datatable__cell">
                            <span
                                style={item.monitoringStatus > 1 && (item.id > 1000002) ?
                                    {color: "#ffffff",width: "200px"}: {color: "#6C7293", width: "200px"}}
                            >
                                {item.place ? item.place : 'N/A'}

                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <div className="progress" style={{width: "320px"}}>
                                <div className="progress-bar"
                                     role="progressbar"
                                     aria-valuenow={item.billCount ? item.billCount : 0}
                                     aria-valuemin="0"
                                     aria-valuemax="600"
                                     style={{width: item.billCount ? item.billCount : 0}}
                                >
                                </div>
                                <div className="progress-bar-title">{item.billAmount ? item.billAmount : 0}</div>
                            </div>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={item.monitoringStatus > 1 && (item.id > 1000002) ?
                                     {color: "#ffffff", width: "80px"}: {color: "#6C7293", width: "80px"}}
                            >
                                {item.version ? item.version : 'N/A'}
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={item.monitoringStatus > 1 && (item.id > 1000002) ?
                                    {color: "#ffffff", width: "120px"}: {color: "#6C7293", width: "120px"}}
                            >
                                {item.firmware ? item.firmware: 'N/A'}
                            </span>
                        </td>

                        <td className="kt-datatable__cell"><span
                            style={item.monitoringStatus > 1 && (item.id > 1000002) ?
                                {color: "#ffffff", width: "120px", overflow: "hidden", whiteSpace: "nowrap"}: {color: "#6C7293", width: "120px", overflow: "hidden", whiteSpace: "nowrap"}}
                        >
                            {item.status ? item.status: 'N/A'}
                        </span>
                        </td>

                        <td className="kt-datatable__cell"><span
                            style={item.monitoringStatus > 1 && (item.id > 1000002) ?
                                {color: "#ffffff", width: "90px", textAlign: "right", paddingRight: "35px"}: {color: "#6C7293", width: "90px", textAlign: "right", paddingRight: "35px"}}
                        >
                            {item.rejectPercent ? item.rejectPercent : 0 }
                        </span>
                        </td>

                        <td className="kt-datatable__cell"><span
                            style={item.monitoringStatus > 1 && (item.id > 1000002) ?
                                {color: "#ffffff", width: "230px", overflow: "hidden", whiteSpace: "nowrap"}: {color: "#6C7293", width: "230px", overflow: "hidden", whiteSpace: "nowrap"}}
                        >
                           {item.lastOnline ? moment(item.lastOnline).tz("Asia/Qatar").format("DD.MM.YYYY HH:mm") : 'N/A'}
                        </span>
                        </td>

                    </tr>
                ))}

                {props.not === 1 ? <div style={{paddingTop: "100px"}}/> :
                    <div className="d-flex justify-content-center">
                        <ReactPaginate
                            previousLabel={<i className="fas fa-angle-double-left"/>}
                            nextLabel={<i className=" fas fa-angle-double-right"/>}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={props.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={props.onPageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            nextClassName={'page-item'}
                            previousLinkClassName={'page-item'}
                            nextLinkClassName={'page-item'}
                            forcePage={props.forcePage}
                        />
                    </div>}

                </tbody>}

        </table>

    </div>
)
