import React from 'react';
import LoadingPage from "../LoadingPage";
import '../paymentForm/Form.css';
import ScrollTop from "../aside/ScrollTop";
import Switch from "react-switch";
import moment from "moment-timezone";
import {config} from "../util/constants";
import axios from "axios";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Button from "../Button";
import Input from "../Input";
import Select2 from "react-select2-wrapper";

class TerminalForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            terminal: [],
            bearer: localStorage.getItem("authToken"),

            id: null,
            name: '',
            description: '',
            login: '',
            city: null,
            area: null,
            agent: null,
            password: '',
            lastOnline: null,
            version: '',
            billValidator: '',
            ipAddress: '',
            active: false,
            hold: false,
            hide: false,
            billCount: null,
            billCountAmount: null,
            receiverAmount: null,
            rejectedCount: null,
            status: null,
            statusDict: [],
            type: null,
            typeDict: [],
            lastIncass: null,
            lastTransaction: null,
            team: null,
            sim: null,

            modalShow: false,
            response: '',
            errorStatus: '',
            statusText: '',
            errorMessage: '',
            isLoaded: 0,
            edit: false,

        };
        this.handleChangeIncass = this.handleChangeIncass.bind(this);
        this.handleChangeReboot = this.handleChangeReboot.bind(this);
        this.handleChangeCalibrate = this.handleChangeCalibrate.bind(this);
        this.sendTerminalTestPrint = this.sendTerminalTestPrint.bind(this);
        this.handleChangeShutdown = this.handleChangeShutdown.bind(this);
        this.handleChangeActive = this.handleChangeActive.bind(this);
        this.handleChangeHold = this.handleChangeHold.bind(this);
        this.handleChangeHide = this.handleChangeHide.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeLogin = this.handleChangeLogin.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeBillCount = this.handleChangeBillCount.bind(this);
        this.handleChangeBillCountAmount = this.handleChangeBillCountAmount.bind(this);
        this.handleChangeBillValidator = this.handleChangeBillValidator.bind(this);
        this.handleChangeReceiverAmount = this.handleChangeReceiverAmount.bind(this);
        this.handleChangeRejectedCount = this.handleChangeRejectedCount.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleClear = this.handleClear.bind(this);

    }

    // valueForDict(array, index) {
    //     if ((array === null || array === undefined) && (index === null || index === undefined)) {
    //         return 'N/A'
    //     } else {
    //         let value = array.find(item => item.id === index);
    //         if (value === undefined) {
    //             return 'N/A'
    //         } else {
    //             return value.text;
    //         }
    //     }
    // };

    handleSubmit(event) {
        event.preventDefault();
        this.sendTerminalRequest();
        this.setState({
            edit: false
        })
    }

    sendTerminalRequest() {
        const data = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            login: this.state.login,
            password: this.state.password,
            lastOnline: this.state.lastOnline,
            version: this.state.version,
            billValidator: this.state.billValidator,
            ipAddress: this.state.ipAddress,
            active: this.state.active,
            hold: this.state.hold,
            hide: this.state.hide,
            billCount: parseInt(this.state.billCount),
            billCountAmount: parseInt(this.state.billCountAmount),
            receiverAmount: parseInt(this.state.receiverAmount),
            rejectedCount: parseInt(this.state.rejectedCount),
            status: parseInt(this.state.status),
            type: parseInt(this.state.type),
        };
        axios.post(config.url.API_URL + "/terminal/", data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        })
            .then(res => {

                const result = res.data;
                console.log(result)
                this.setState({
                    modalShow: true,
                });
            })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {
                    let response = e.response;
                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });
    };

    sendTerminalReboot() {
        axios.get(config.url.API_URL + "/terminal/" + this.state.id + "/reboot", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                console.log(result);
                this.setState({
                    modalShow: true,
                    statusText : result.desc,
                    errorMessage : result.msg,
                });

            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    };

    sendTerminalIncass() {
        axios.get(config.url.API_URL + "/terminal/" + this.state.id + "/manualIncass", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            console.log(result);
            this.setState({
                modalShow: true,
                statusText : result.desc,
                errorMessage : result.msg,
            });

            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    };

    sendTerminalCalibrate() {
        axios.get(config.url.API_URL + "/terminal/"+ this.state.id + "/calibrate", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            console.log(result);
            this.setState({
                modalShow: true,
                statusText : result.desc,
                errorMessage : result.msg,
            });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    };

    sendTerminalShutdown() {
        axios.get(config.url.API_URL + "/terminal/"+ this.state.id + "/shutdown", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            console.log(result);
            this.setState({
                modalShow: true,
                statusText : result.desc,
                errorMessage : result.msg,
            });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    };

    sendTerminalTestPrint() {
        axios.get(config.url.API_URL + "/terminal/"+ this.state.id +  "/testPrint", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            console.log(result);
            this.setState({
                modalShow: true,
                statusText : result.desc,
                errorMessage : result.msg,
            });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    };

    handleChangeIncass = (e) => {
        e.preventDefault();
        this.sendTerminalIncass();
    };
    handleChangeShutdown = (e) => {
        e.preventDefault();
        this.sendTerminalShutdown();
    };
    handleChangeTestPrint = (e) => {
        e.preventDefault();
        this.sendTerminalTestPrint();
    };
    handleChangeReboot = (e) => {
        e.preventDefault();
        this.sendTerminalReboot();
    };
    handleChangeCalibrate = (e) => {
        e.preventDefault();
        this.sendTerminalCalibrate();
    };

    handleChangeName = (e) => {

        const value = e.target.value;
        this.setState({
            name: value,
        })

    };

    handleChangeDescription = (e) => {

        const value = e.target.value;
        this.setState({
            description: value,
        })

    };
    handleChangeLogin = (e) => {

        const value = e.target.value;
        this.setState({
            login: value,
        })
    };
    handleChangePassword = (e) => {

        const value = e.target.value;
        this.setState({
            password: value,
        })
    };

    handleChangeBillCount = (e) => {

        const value = e.target.value;
        this.setState({
            billCount: value,
        })
    };
    handleChangeBillCountAmount = (e) => {

        const value = e.target.value;
        this.setState({
            billCountAmount: value,
        })
    };
    handleChangeBillValidator = (e) => {

        const value = e.target.value;
        this.setState({
            billValidator: value,
        })
    };
    handleChangeReceiverAmount = (e) => {

        const value = e.target.value;
        this.setState({
            receiverAmount: value,
        })
    };
    handleChangeRejectedCount = (e) => {

        const value = e.target.value;
        this.setState({
            rejectedCount: value,
        })
    };
    handleChangeStatus = (e) => {
        const value = e.target.value;
        this.setState({
            status: value,
        })
    };

    handleChangeType = (e) => {
        const value = e.target.value;
        this.setState({
            type: value,
        })
    };

    handleChangeActive(checked) {
        this.setState({
            active: checked
        });

    };

    handleChangeHold(checked) {
        this.setState({
            hold: checked,
        })
    };

    handleChangeHide(checked) {
        this.setState({
            hide: checked,
        })
    };

    handleClear() {

        this.setState({
            name: '',
            description: '',
            login: '',
            password: '',
            lastOnline: null,
            version: '',
            billValidator: '',
            ipAddress: '',
            active: false,
            hold: false,
            hide: false,
            billCount: null,
            billCountAmount: null,
            receiverAmount: null,
            rejectedCount: null,
            status: null,
            type: null,


        })
    };

    handleCancel() {
        this.componentDidMount();
        this.setState({
            edit: false
        })
    };

    handleEdit() {

        const editDefault = this.state.edit === false;
        // editDefault === false ? true : false;
        this.setState({
            edit: editDefault,
        })
    };

    moreInfo() {
        axios.get(config.url.API_URL + '/terminal/' + this.state.id + '/additionalInfo', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            console.log(result);

            this.setState({
                sim: result.sim ? result.sim : 'N/A',
                area: result.area ? result.area : 'N/A',
                city: result.city ? result.city : 'N/A',
                agent: result.place ? result.place : 'N/A',
                lastIncass: result.lastIncass ? result.lastIncass : 'N/A',
                lastTransaction: result.lastTransaction ? result.lastTransaction : 'N/A',
                isLoaded: this.state.isLoaded + 1,
            });
            console.log(this.state.sim)
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

    }

    componentDidMount() {
        let request = config.url.API_URL + window.location.pathname;
        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    id: result.id,
                    name: result.name ? result.name : 'N/A',
                    description: result.description ? result.description : 'N/A',
                    login: result.login ? result.login : 'N/A',
                    password: result.password ? result.password : 'N/A',
                    lastOnline: result.lastOnline,
                    version: result.version ? result.version : 'N/A',
                    billValidator: result.billValidator ? result.billValidator : 'N/A',
                    ipAddress: result.ipAddress ? result.ipAddress : 'N/A',
                    active: result.active,
                    hold: result.hold,
                    hide: result.hide,
                    billCount: result.billCount ? result.billCount : 0,
                    billCountAmount: result.billCountAmount ? result.billCountAmount : 0,
                    receiverAmount: result.receiverAmount ? result.receiverAmount : 0,
                    rejectedCount: result.rejectedCount ? result.rejectedCount : 0,
                    status: result.status,
                    type: result.type,

                    isLoaded: this.state.isLoaded + 1,
                });
                document.title = 'Terminal: ' + this.state.name + ' - mitra processing';

                this.moreInfo()
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

        axios.get(config.url.API_URL + '/dictionary/terminalTypeDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                typeDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

        axios.get(config.url.API_URL + '/dictionary/terminalStatusDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                statusDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

    };

    render() {
        let modalClose = () => this.setState({modalShow: false});

        return (
            <div>
                {this.state.isLoaded < 4 ? <LoadingPage/> :
                    <div className="kt-portlet kt-portlet--mobile">
                        <MyVerticallyCenteredModal
                            heder={this.state.errorStatus}
                            h4={this.state.statusText}
                            text={this.state.errorMessage}
                            show={this.state.modalShow}
                            onHide={modalClose}
                        />

                        <form className="mitra-form">
                            <div className="dima_heder">Terminal: {this.state.name}</div>
                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">ID</label>
                                </div>
                                <div className="input_form_single">
                                    <p className="dima_form_p">{this.state.id}</p>

                                </div>
                            </div>
                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">Name</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.edit === false ?*/}
                            {/*            (<p className="dima_form_p">{this.state.name}</p>) :*/}

                            {/*            (<div className="input_form_single">*/}
                            {/*                <input type="text"*/}
                            {/*                       value={this.state.name}*/}
                            {/*                       className="form-control dima_form_control"*/}
                            {/*                       placeholder={''}*/}
                            {/*                       id="name"*/}
                            {/*                       onChange={this.handleChangeName}*/}
                            {/*                />*/}
                            {/*            </div>)*/}
                            {/*        }*/}
                            {/*    </div>*/}

                            {/*</div>*/}
                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Description</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        onChange={this.handleChangeDescription}
                                        type='text'
                                        id='description'
                                        disabled={this.state.edit !== true}
                                        value={this.state.description}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">City</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        type='text'
                                        id='city'
                                        disabled={true}
                                        value={this.state.city}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Area</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        type='text'
                                        id='area'
                                        disabled={true}
                                        value={this.state.area}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Agent</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        type='text'
                                        id='agent'
                                        disabled={true}
                                        value={this.state.agent}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Cash</label>
                                </div>
                                <div className="input_form_single">
                                    <Input
                                        onChange={this.handleChangeBillCountAmount}
                                        type='number'
                                        id='billCountAmount'
                                        disabled={this.state.edit !== true}
                                        value={this.state.billCountAmount}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Bill count</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        onChange={this.handleChangeBillCount}
                                        type='number'
                                        id='billCount'
                                        disabled={this.state.edit !== true}
                                        value={this.state.billCount}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Version</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        onChange={this.handleChangeBillCount}
                                        type='text'
                                        id='version'
                                        disabled={true}
                                        value={this.state.version}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Validator</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        onChange={this.handleChangeBillValidator}
                                        type='text'
                                        id='billValidator'
                                        disabled={this.state.edit !== true}
                                        value={this.state.billValidator}
                                    />
                                </div>
                            </div>

                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">Login</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.edit === false ?*/}
                            {/*            (<p className="dima_form_p">{this.state.login}</p>) :*/}

                            {/*            (<div className="input_form_single">*/}
                            {/*                <input type="text"*/}
                            {/*                       value={this.state.login}*/}
                            {/*                       className="form-control dima_form_control"*/}
                            {/*                       placeholder={''}*/}
                            {/*                       id="login"*/}
                            {/*                       onChange={this.handleChangeLogin}*/}
                            {/*                />*/}
                            {/*            </div>)*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">Password</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.edit === false ?*/}
                            {/*            (<p className="dima_form_p">{this.state.password}</p>) :*/}

                            {/*            (<div className="input_form_single">*/}
                            {/*                <input type="text"*/}
                            {/*                       value={this.state.password}*/}
                            {/*                       className="form-control dima_form_control"*/}
                            {/*                       placeholder={''}*/}
                            {/*                       id="password"*/}
                            {/*                       onChange={this.handleChangePassword}*/}
                            {/*                />*/}
                            {/*            </div>)*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Reject %</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        type='text'
                                        id='rejectPercent'
                                        disabled={true}
                                        value={this.state.rejectPercent}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single_currency">
                                <div className="label_inline">
                                    <label className="label_form">Rejected notes</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        onChange={this.handleChangeRejectedCount}
                                        type='number'
                                        id='rejectedCount'
                                        disabled={this.state.edit !== true}
                                        value={this.state.rejectedCount}
                                    />
                                </div>
                            </div>

                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">IP address</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        <p className="dima_form_p ">{this.state.ipAddress}</p>*/}
                            {/*        /!*<Select*!/*/}
                            {/*        /!*    className={'input_form_single_select'}*!/*/}
                            {/*        /!*    disabled*!/*/}
                            {/*        /!*    multiple={'false'}*!/*/}
                            {/*        /!*    id={'placeSelect'}*!/*/}
                            {/*        /!*    placeholder={''}*!/*/}
                            {/*        /!*    dict={this.state.placeDict}*!/*/}
                            {/*        /!*    value={this.state.place}*!/*/}
                            {/*        /!*    // onChange={this.handleChangeOperator}*!/*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="dima_form_single_currency">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">Receiver amount</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.edit === false ?*/}
                            {/*            (<p className="dima_form_p">{this.state.receiverAmount}</p>) :*/}

                            {/*            (<div className="input_form_single">*/}
                            {/*                <input type="number"*/}
                            {/*                       value={this.state.receiverAmount}*/}
                            {/*                       className="form-control dima_form_control"*/}
                            {/*                       placeholder={''}*/}
                            {/*                       id="receiverAmount"*/}
                            {/*                       onChange={this.handleChangeReceiverAmount}*/}
                            {/*                />*/}
                            {/*            </div>)*/}
                            {/*        }*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                            <div className="dima_form_single_currency">
                                <div className="label_inline">
                                    <label className="label_form">Status</label>
                                </div>

                                <div className="input_form_single">

                                    <Select2
                                        className="mitra-select"
                                        onChange={this.handleChangeStatus}
                                        disabled={this.state.edit !== true}
                                        id="status"
                                        data={this.state.statusDict}
                                        value={this.state.status}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Last online</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        type="text"
                                        id="lastOnline"
                                        disabled={true}
                                        value={this.state.lastOnline ? moment(this.state.lastOnline).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : 'N/A'}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">SIM</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        type="text"
                                        id="simCard"
                                        disabled={true}
                                        value={this.state.sim ? this.state.sim : 'N/A'}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Team</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        type="text"
                                        id="team"
                                        disabled={true}
                                        value={this.state.team ? this.state.team : 'N/A'}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Last incass</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        type="text"
                                        id="lastIncass"
                                        disabled={true}
                                        value={this.state.lastIncass ? moment(this.state.lastIncass).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : 'N/A'}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Last transaction</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        type="text"
                                        id="lastTransaction"
                                        disabled={true}
                                        value={this.state.lastTransaction ? moment(this.state.lastTransaction).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : 'N/A'}
                                    />
                                </div>
                            </div>

                            {/*<div className="dima_form_single">*/}
                            {/*    <div className="label_inline">*/}
                            {/*        <label className="label_form">Type</label>*/}
                            {/*    </div>*/}

                            {/*    <div className="input_form_single">*/}
                            {/*        {this.state.edit === false ?*/}
                            {/*            <p className="dima_form_p">{this.valueForDict(this.state.typeDict, this.state.type)}</p> :*/}
                            {/*            < Select*/}
                            {/*                className={'input_form_single_select'}*/}
                            {/*                multiple={'false'}*/}
                            {/*                id={'type'}*/}
                            {/*                placeholder={''}*/}
                            {/*                dict={this.state.typeDict}*/}
                            {/*                value={this.state.type}*/}
                            {/*                onChange={this.handleChangeType}*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*{this.state.extendedForm === false ? "" :*/}
                            {/*    <div className="extended_form_dima">*/}

                            <div className="dima_form_single_switch" style={{marginTop: "3em"}}>
                                <div className="switch_block_dima">
                                    <Switch
                                        disabled={this.state.edit !== true}
                                        checked={this.state.active}
                                        onChange={this.handleChangeActive}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        className="kt-switch"
                                        id="kt-switch"
                                    />

                                    <div className="label_inline_switch">
                                        <label className="label_form">Active</label>
                                    </div>
                                </div>
                            </div>
                            <div className="dima_form_single_switch">
                                <div className="switch_block_dima">
                                    <Switch
                                        disabled={this.state.edit !== true}
                                        checked={this.state.hold}
                                        onChange={this.handleChangeHold}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        className="kt-switch"
                                        id="kt-switch"
                                    />

                                    <div className="label_inline_switch">
                                        <label className="label_form">Hold</label>
                                    </div>
                                </div>
                            </div>
                            <div className="dima_form_single_switch">

                                <div className="switch_block_dima">
                                    <Switch
                                        disabled={this.state.edit !== true}
                                        checked={this.state.hide}
                                        onChange={this.handleChangeHide}
                                        onColor="#acb3ee"
                                        onHandleColor="#5867dd"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        boxShadow="true"
                                        activeBoxShadow="true"
                                        offHandleColor="#e1e2e4"
                                        height={20}
                                        width={48}
                                        className="kt-switch"
                                        id="kt-switch"
                                    />

                                    <div className="label_inline_switch">
                                        <label className="label_form">Hide</label>
                                    </div>
                                </div>
                            </div>
                            <div className="btn_form-dima">
                                {/*<Button*/}
                                {/*    onClick={this.handleChangeCalibrate}*/}
                                {/*disabled={this.state.edit !== false}*/}
                                {/*    type="button"*/}
                                {/*    name='Calibrate'*/}
                                {/*/> */}
                                <Button
                                    onClick={this.handleChangeReboot}
                                    disabled={this.state.edit !== false}
                                    type="button"
                                    name='Reboot'
                                />
                                <Button
                                    onClick={this.handleChangeShutdown}
                                    type="button"
                                    disabled={this.state.edit !== false}
                                    name='Shutdown'
                                />
                                <Button
                                    onClick={this.handleChangeTestPrint}
                                    type="button"
                                    disabled={this.state.edit !== false}
                                    name='Print'
                                />
                                <Button
                                    onClick={this.handleChangeIncass}
                                    type="button"
                                    disabled={this.state.edit !== false}
                                    name='Incass'
                                />
                                <Button
                                    onClick={this.handleEdit}
                                    type="button"
                                    disabled={this.state.edit !== false}
                                    name='Edit'
                                />
                                <Button
                                    onClick={this.handleClear}
                                    type="button"
                                    disabled={this.state.edit !== true}
                                    name='Clear'
                                />
                                <Button
                                    onClick={this.handleCancel}
                                    className="mitra-button-cancel"
                                    type="button"
                                    disabled={this.state.edit !== true}
                                    name='Cancel'
                                />
                                <Button
                                    onClick={this.handleSubmit}
                                    className="mitra-button-submit"
                                    type="button"
                                    disabled={this.state.edit !== true}
                                    name='Save'
                                />

                            </div>
                        </form>

                    </div>
                }
                <ScrollTop/>

            </div>
        );
    }
}

export default TerminalForm;
