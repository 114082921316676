import React from "react";
import Aside from "../aside/Aside";
import {Switch} from "react-router-dom";
import RoleRoute from "../../routers/RoleRoute";
import List from "./List";
import ListNew from "./ListNew";

export default function PaymentRoute( {match} ) {
    return (
        <>
            <Aside/>
            <Switch>

                <RoleRoute
                    path={`${match.path}/new`}
                    component={ListNew}
                />
                <RoleRoute
                    path={`${match.path}/`}
                    component={List}
                />

            </Switch>
        </>
    )
}