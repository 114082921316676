import React from 'react';
import './Loding.css'

const LoadingPage = () => (
    <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

export default LoadingPage;
