import React from 'react'
import '../paymentForm/Form.css'
import moment from "moment-timezone";
import LoadingPage from "../LoadingPage";
import {config} from "../util/constants";
import axios from "axios";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Button from "../Button";
import Input from "../Input";
import Select2 from "react-select2-wrapper";

class PaymentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bearer: localStorage.getItem("authToken"),

            id: null,
            ticket: '',
            service: null, //Dictionary from /dictionary/serviceDict
            chanel: null,//Dictionary from /dictionary/chanelDict
            place: null, //Dictionary from /dictionary/placeDict
            terminal: null, //Dictionary from /dictionary/terminalDict
            status: null, //Dictionary from /dictionary/statusDict
            phone: '',
            sum: 0, //minimum: 0
            currencyIn: null, //Dictionary from /dictionary/currencyDict
            amount: 0, //minimum: 0
            commission: 0, //minimum: 0
            currencyOut: null, //Dictionary from /dictionary/currencyDict
            receive: 0,
            receiveCurrency: null, //Dictionary from /dictionary/currencyDict
            timeStart: '', //string($date-time)
            timeProcessing: '', //string($date-time)
            timeFinal: '', //	string($date-time)
            operator: null, //Dictionary from /dictionary/operatorDict
            note: '',
            extended1: '',
            extended2: '',
            repeats: 0, //minimum: 0
            operatorResponse: '',
            transactionId: null,
            country: null, //Dictionary from /dictionary/countryDict
            operatorName: '',

            pin: {},

            operatorDict: [],
            statusDict: [],
            placeDict: [],
            terminalDict: [],
            currencyDict: [],
            countryDict: [],
            serviceDict: [],
            chanelDict: [],


            edit: false,
            extendedForm: false,
            disabled: true,
            modalShow: false,
            errorStatus: '',
            statusText: '',
            errorMessage: '',
            error: null,
            isLoaded: null,
        };
        this.handleChangeOperator = this.handleChangeOperator.bind(this);
        this.handleChangeAccount = this.handleChangeAccount.bind(this);
        this.handleOperatorResponse = this.handleOperatorResponse.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleChangeReceiveCurrency = this.handleChangeReceiveCurrency.bind(this);
        this.handleChangeCurrencyOut = this.handleChangeCurrencyOut.bind(this);
        this.handleChangeCost = this.handleChangeCost.bind(this);
        this.handleChangeReceive = this.handleChangeReceive.bind(this);
        this.handleChangeService = this.handleChangeService.bind(this);
        this.handleChangeChanel = this.handleChangeChanel.bind(this);
        this.handleChangeNote = this.handleChangeNote.bind(this);
        this.handleChangeNote1 = this.handleChangeNote1.bind(this);
        this.handleChangeNote2 = this.handleChangeNote2.bind(this);
        this.handleExtended = this.handleExtended.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getPin = this.getPin.bind(this);
    };

    componentDidMount() {

        let request = config.url.API_URL + window.location.pathname;

        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                id: result.id,
                ticket: result.ticket ? result.ticket : 'N/A',
                service: result.service,
                chanel: result.chanel,
                place: result.place,
                terminal: result.terminal,
                status: result.status,
                phone: result.phone ? result.phone : 'N/A',
                sum: result.sum ? result.sum : 0,
                currencyIn: result.currencyIn,
                amount: result.amount ? result.amount : 0,
                commission: result.commission ? result.commission : 0,
                currencyOut: result.currencyOut,
                receive: result.receive ? result.receive : 0,
                receiveCurrency: result.receiveCurrency,
                timeStart: result.timeStart ? result.timeStart : 'N/A',
                timeProcessing: result.timeProcessing ? result.timeProcessing : 'N/A',
                timeFinal: result.timeFinal ? result.timeFinal : 'N/A',
                operator: result.operator,
                note: result.note ? result.note : 'N/A',
                extended1: result.extended1 ? result.extended1 : 'N/A',
                extended2: result.extended2 ? result.extended2 : 'N/A',
                repeats: result.repeats ? result.repeats : 0,
                operatorResponse: result.operatorResponse ? result.operatorResponse : 'N/A',
                transactionId: result.transactionId,
                country: result.country,
                operatorName: result.operatorName ? result.operatorName : 'N/A',

                isLoaded: this.state.isLoaded + 1,
            });
            document.title = 'Payment: ' + this.state.id + ' - mitra processing';
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;
                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    let errorStatus = response.status;

                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

        axios.get(request + '/getPIN', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            console.log('pin', result)
            this.setState({
                pin: result.msg,
                isLoaded: this.state.isLoaded + 1,
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;
                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    let errorStatus = response.status;

                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

        axios.get(config.url.API_URL + '/dictionary/terminalDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;

            this.setState({
                terminalDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;

                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

        axios.get(config.url.API_URL + '/dictionary/serviceFlatDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;

            this.setState({
                serviceDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;

                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

        axios.get(config.url.API_URL + '/dictionary/operatorDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;

            this.setState({
                operatorDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;

                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

        axios.get(config.url.API_URL + '/dictionary/currencyDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;

            this.setState({
                currencyDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;

                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

        axios.get(config.url.API_URL + '/dictionary/chanelDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;

            this.setState({
                chanelDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;

                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

        axios.get(config.url.API_URL + '/dictionary/statusDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                statusDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;

                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

        axios.get(config.url.API_URL + '/dictionary/placeDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;

            this.setState({
                placeDict: result,
                isLoaded: this.state.isLoaded + 1,
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;

                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    };

    valueForDict(array, index) {
        if ((array === null || array === undefined) && (index === null || index === undefined)) {
            return 'N/A'
        } else {
            let value = array.find(item => item.id === index);
            if (value === undefined) {
                return 'N/A'
            } else {
                return value.text;
            }
        }
    };

    handleChangeOperator(e) {
        const value = e.target.value;
        this.setState({
            operator: value,
        })
    };


    handleChangeChanel(e) {
        const value = e.target.value;
        this.setState({
            chanel: value,
        })
    };

    handleOperatorResponse(e) {
        const value = e.target.value;
        this.setState({
            operatorResponse: value,
        })
    };

    handleChangeReceiveCurrency(e) {
        const value = e.target.value;
        this.setState({
            receiveCurrency: value,
        })
    };

    handleChangeCurrencyOut(e) {
        const value = e.target.value;
        this.setState({
            currencyOut: value,
        })
    };

    handleChangeStatus(e) {
        const value = e.target.value;
        this.setState({
            status: value,
        })
    };

    handleChangeService(e) {
        const value = e.target.value;
        this.setState({
            service: value,
        })
    };

    handleChangeAccount(e) {
        const value = e.target.value;
        this.setState({
            phone: value,
        })
    };

    handleChangeNote(e) {
        const value = e.target.value;
        this.setState({
            note: value,
        })
    };

    handleChangeNote1(e) {
        const value = e.target.value;
        this.setState({
            extended1: value,
        })
    };

    handleChangeNote2(e) {
        const value = e.target.value;
        this.setState({
            extended2: value,
        })
    };

    handleChangeCost(e) {
        const value = e.target.value;
        this.setState({
            amount: value,
        })
    };

    handleChangeReceive(e) {
        const value = e.target.value;
        this.setState({
            receive: value,
        })
    };

    handleExtended() {
        if (this.state.extendedForm === false) {
            this.setState({
                extendedForm: true,
            })
        } else {
            this.setState({
                extendedForm: false,
            })
        }
    };

    handleEdit() {
        if (this.state.edit === false) {
            this.setState({
                edit: true,
                disabled: false

            })
        } else {
            this.setState({
                edit: false,
                disabled: true
            })
        }
    };

    handleClear() {
        this.setState({
            id: null,
            ticket: '',
            service: null,
            chanel: null,
            place: null,
            terminal: null,
            status: null,
            phone: '',
            sum: 0,
            currencyIn: null,
            amount: 0,
            commission: 0,
            currencyOut: null,
            receive: 0,
            receiveCurrency: null,
            timeStart: '',
            timeProcessing: '',
            timeFinal: '',
            operator: null,
            note: '',
            extended1: '',
            extended2: '',
            repeats: 0,
            operatorResponse: '',
            transactionId: null,
            country: null,
            operatorName: '',
        })
    };

    handleCancel() {
        this.componentDidMount();
        this.setState({
            edit: false,
            disabled: true
        })
    };

    handleSubmit(event) {
        event.preventDefault();
        this.sendPaymentRequest();
    };

    getPin() {
        navigator.clipboard.writeText(this.state.pin)
        console.log('pin', this.state.pin)
        console.log('pin')
    };

    sendPaymentRequest() {
        const data = {
            id: parseInt(this.state.id),
            ticket: this.state.ticket,
            sum: parseFloat(this.state.sum),
            amount: parseFloat(this.state.amount),
            commission: parseInt(this.state.commission),
            repeats: parseInt(this.state.repeats),
            timeStart: this.state.timeStart,
            timeProcessing: this.state.timeProcessing,
            timeFinal: this.state.timeFinal,
            note: this.state.note,
            extended1: this.state.extended1,
            extended2: this.state.extended2,
            operatorResponse: this.state.operatorResponse,
            transactionId: parseInt(this.state.transactionId),
            operatorName: this.state.operatorName,
            phone: this.state.phone,
            operator: parseInt(this.state.operator),
            status: parseInt(this.state.status),
            place: parseInt(this.state.place),
            terminal: parseInt(this.state.terminal),
            currencyIn: parseInt(this.state.currencyIn),
            currencyOut: parseInt(this.state.currencyOut),
            receive: parseFloat(this.state.receive),
            receiveCurrency: parseInt(this.state.receiveCurrency),
            service: parseInt(this.state.service),
            chanel: parseInt(this.state.chanel),
        };

        axios.post(config.url.API_URL + "/payment/", data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                id: result.id,
                ticket: result.ticket ? result.ticket : 'N/A',
                service: result.service,
                chanel: result.chanel,
                place: result.place,
                terminal: result.terminal,
                status: result.status,
                phone: result.phone ? result.phone : 'N/A',
                sum: result.sum ? result.sum : 0,
                currencyIn: result.currencyIn,
                amount: result.amount ? result.amount : 0,
                commission: result.commission ? result.commission : 0,
                currencyOut: result.currencyOut,
                receive: result.receive ? result.receive : 0,
                receiveCurrency: result.receiveCurrency,
                timeStart: result.timeStart ? result.timeStart : 'N/A',
                timeProcessing: result.timeProcessing ? result.timeProcessing : 'N/A',
                timeFinal: result.timeFinal ? result.timeFinal : 'N/A',
                operator: result.operator,
                note: result.note ? result.note : 'N/A',
                extended1: result.extended1 ? result.extended1 : 'N/A',
                extended2: result.extended2 ? result.extended2 : 'N/A',
                repeats: result.repeats ? result.repeats : 0,
                operatorResponse: result.operatorResponse ? result.operatorResponse : 'N/A',
                transactionId: result.transactionId,
                country: result.country,
                operatorName: result.operatorName ? result.operatorName : 'N/A',

                edit: false,
                disabled: true
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;

                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    };

    render() {
        let modalClose = () => this.setState({modalShow: false});
        return (
            <div>
                {this.state.isLoaded < 9 ? <LoadingPage/> :
                    <div className="kt-portlet kt-portlet--mobile">
                        <MyVerticallyCenteredModal
                            heder={this.state.errorStatus}
                            h4={this.state.statusText}
                            text={this.state.errorMessage}
                            show={this.state.modalShow}
                            onHide={modalClose}
                        />

                        <form className="mitra-form">
                            <div className="dima_heder">Payment: {this.state.id ? this.state.id : 'N/A'}</div>
                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">ID</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={true}
                                        id="id"
                                        type="number"
                                        value={this.state.id}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Transaction</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={true}
                                        id="ticket"
                                        type="text"
                                        value={this.state.ticket}
                                    />
                                </div>

                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Start</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={true}
                                        id="timeStart"
                                        type="text"
                                        value={this.state.timeStart ? moment(this.state.timeStart).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : 'N/A'}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Processing </label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={true}
                                        id="timeProcessing"
                                        type="text"
                                        value={this.state.timeProcessing ? moment(this.state.timeProcessing).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : 'N/A'}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Complete </label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={true}
                                        id="timeFinal"
                                        type="text"
                                        value={this.state.timeFinal ? moment(this.state.timeFinal).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : 'N/A'}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Try</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={true}
                                        id="repeats"
                                        type="number"
                                        value={this.state.repeats}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Status</label>
                                </div>

                                <div className="input_form_single">
                                    <Select2
                                        disabled={this.state.disabled}
                                        id={'status'}
                                        value={this.state.status}
                                        data={this.state.statusDict}
                                        onChange={this.handleChangeStatus}
                                        className="mitra-select"
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Terminal </label>
                                </div>

                                <div className="input_form_single">
                                    <Select2
                                        disabled={true}
                                        id={'terminal'}
                                        value={this.state.terminal}
                                        data={this.state.terminalDict}
                                        className="mitra-select"
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Agent</label>
                                </div>

                                <div className="input_form_single">
                                    <Select2
                                        disabled={true}
                                        id={'place'}
                                        value={this.state.place}
                                        data={this.state.placeDict}
                                        className="mitra-select"
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Supplier</label>
                                </div>

                                <div className="input_form_single">
                                    <Select2
                                        disabled={this.state.disabled}
                                        id={'operator'}
                                        value={this.state.operator}
                                        data={this.state.operatorDict}
                                        onChange={this.handleChangeOperator}
                                        className="mitra-select"
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Service</label>
                                </div>

                                <div className="input_form_single">
                                    <Select2
                                        disabled={this.state.disabled}
                                        className="mitra-select"
                                        id="service"
                                        value={this.state.service}
                                        data={this.state.serviceDict}
                                        onChange={this.handleChangeService}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Product</label>
                                </div>
                                <Input
                                    disabled={true}
                                    type="text"
                                    value={this.state.operatorName}
                                    id="operatorName"
                                />
                            </div>

                            <div className="dima_form_single">
                                <div className="label_inline">
                                    <label className="label_form">Account</label>
                                </div>

                                <div className="input_form_single">
                                    <Input
                                        disabled={this.state.disabled}
                                        id="phone"
                                        type="text"
                                        value={this.state.phone}
                                        onChange={this.handleChangeAccount}
                                    />
                                </div>
                            </div>

                            <div className="dima_form_single_currency">
                                <div className="label_inline">
                                    <label className="label_form">Got</label>
                                </div>

                                <div className="input_form_single_currency_p">
                                    <p className="dima_form_p">{this.state.sum === 0 ? 0 : this.state.sum + " " + this.valueForDict(this.state.currencyDict, this.state.currencyIn)}</p>
                                </div>
                            </div>

                            <div className="dima_form_single_currency">
                                <div className="label_inline">
                                    <label className="label_form">Paid</label>
                                </div>

                                {this.state.edit === false ?
                                    <div className="input_form_single_currency_p">
                                        <p className="dima_form_p">{this.state.receive === 0 ? 0 : this.state.receive + " " + this.valueForDict(this.state.currencyDict, this.state.receiveCurrency)}</p>
                                    </div> :

                                    <div className="got_edit_block">
                                        <div className="got_edit">
                                            <Input
                                                step="0,01"
                                                id="paid"
                                                type="number"
                                                value={this.state.receive}
                                                onChange={this.handleChangeReceive}
                                            />
                                        </div>
                                        <Select2
                                            disabled={this.state.disabled}
                                            id={'receiveCurrency'}
                                            value={this.state.receiveCurrency}
                                            data={this.state.currencyDict}
                                            onChange={this.handleChangeReceiveCurrency}
                                            className="input_form_single_select_currency"
                                        />
                                    </div>
                                }
                            </div>
                            <div className="dima_form_single_currency">
                                <div className="label_inline">
                                    <label className="label_form">Cost</label>
                                </div>

                                {this.state.edit === false ?
                                    <div className="input_form_single_currency_p">
                                        <p className="dima_form_p">{this.state.amount === 0 ? 0 : this.state.amount + " " + this.valueForDict(this.state.currencyDict, this.state.currencyOut)}</p>
                                    </div> :

                                    <div className="got_edit_block">
                                        <div className="got_edit">
                                            <Input
                                                step="0,01"
                                                id="cost"
                                                type="number"
                                                value={this.state.amount}
                                                onChange={this.handleChangeCost}
                                            />
                                        </div>
                                        <Select2
                                            disabled={this.state.disabled}
                                            id={'currencyOut'}
                                            value={this.state.currencyOut}
                                            data={this.state.currencyDict}
                                            onChange={this.handleChangeCurrencyOut}
                                            className="input_form_single_select_currency"
                                        />
                                    </div>
                                }
                            </div>

                            <div className="dima_form_single_textarea">
                                <div className="label_inline">
                                    <label className="label_form">Response</label>
                                </div>
                                {this.state.edit === false ?
                                    <textarea
                                        rows="30"
                                        className="form-control response_textarea"
                                        id="operatorResponse"
                                        readOnly
                                        style={{color: "#646c9a"}}
                                    >{this.state.operatorResponse}
                                    </textarea> :
                                    <textarea
                                        rows="40"
                                        className="form-control response_textarea"
                                        id="operatorResponse"
                                        onChange={this.handleOperatorResponse}
                                    >
                                        {this.state.operatorResponse}
                                        </textarea>
                                }
                            </div>

                            {this.state.extendedForm === false ? "" :
                                <div className="extended_form_dima">
                                    <div className="dima_form_single">
                                        <div className="label_inline">
                                            <label className="label_form">Note 1</label>
                                        </div>

                                        <div className="input_form_single">
                                            <Input
                                                disabled={this.state.disabled}
                                                type="text"
                                                value={this.state.note}
                                                id="note"
                                                onChange={this.handleChangeNote}
                                            />
                                        </div>
                                    </div>

                                    <div className="dima_form_single">
                                        <div className="label_inline">
                                            <label className="label_form">Note 2</label>
                                        </div>

                                        <div className="input_form_single">
                                            <Input
                                                disabled={this.state.disabled}
                                                type="text"
                                                value={this.state.extended1}
                                                id="extended1"
                                                onChange={this.handleChangeNote1}
                                            />
                                        </div>
                                    </div>

                                    <div className="dima_form_single">
                                        <div className="label_inline">
                                            <label className="label_form">Note 3</label>
                                        </div>

                                        <div className="input_form_single">
                                            <Input
                                                disabled={this.state.disabled}
                                                type="text"
                                                value={this.state.extended2}
                                                id="extended2"
                                                onChange={this.handleChangeNote2}
                                            />
                                        </div>
                                    </div>

                                    <div className="dima_form_single">
                                        <div className="label_inline">
                                            <label className="label_form">Channel</label>
                                        </div>

                                        <div className="input_form_single">
                                            <Select2
                                                disabled={this.state.disabled}
                                                className="mitra-select"
                                                id="chanel"
                                                value={this.state.chanel}
                                                data={this.state.chanelDict}
                                                onChange={this.handleChangeChanel}
                                            />
                                        </div>
                                    </div>

                                </div>
                            }

                            <div className="dima_form_single_extended">
                                <div className="label_inline">
                                    <button className="extendedForm"
                                            type="button"
                                            onClick={this.handleExtended}>
                                    <span
                                        className="extended_span">{this.state.extendedForm === false ? "+ Extended" : "- Extended"}
                                    </span>
                                    </button>
                                </div>
                            </div>
                            <div className="btn_form-dima">
                                <Button
                                    disabled={this.state.edit}
                                    type="button"
                                    id="PIN"
                                    name="PIN"
                                    onClick={this.getPin}
                                />

                                <Button
                                    disabled={this.state.edit}
                                    type="button"
                                    id="edit"
                                    name="Edit"
                                    onClick={this.handleEdit}
                                />

                                <Button
                                    disabled={this.state.disabled}
                                    type="button"
                                    id="clear"
                                    name="Clear"
                                    onClick={this.handleClear}
                                />

                                <Button
                                    disabled={this.state.disabled}
                                    type="button"
                                    id="cancel"
                                    name="Cancel"
                                    onClick={this.handleCancel}
                                    className="mitra-button-cancel"
                                />

                                <Button
                                    disabled={this.state.disabled}
                                    type="submit"
                                    id="save"
                                    name="Save"
                                    onClick={this.handleSubmit}
                                    className="mitra-button-submit"
                                />
                            </div>
                        </form>
                    </div>
                }
            </div>
        );
    }
}

export default PaymentForm;
