import React, {useEffect, useState} from "react";
import Select2 from "react-select2-wrapper";
import Button from "../Button";
import {sendGET} from "../util/sendRequest";
import "./dictionaries.css";
import {useHistory} from "react-router-dom";
import "../mitraDimaRole.css";
import TableRow from "../MitraTable/TableRow";

export default function Dictionaries() {

    document.title = 'Dictionaries - mitra processing';

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    // const [newRow, setNewRow] = useState(false);
    const [th, setTh] = useState();
    const [td, setTd] = useState();
    const dicts = [
        {
            id: 'city', text: 'City',
        },
        {
            id: 'operatorDenomination', text: 'Denomination',
        },
        {
            id: 'operatorName', text: 'Operator name',
        },
        {
            id: 'country', text: 'Country',
        },

    ]
    const [entity, setEntity] = useState(null);

    const [dict, setDict] = useState(null);


    const Send = () => {
        if (dict !== null) {
            sendGET('/d/' + dict, null, setEntity, history, setLoading);
        }
    };

    useEffect(() => {
        if (entity !== null) {
            setTd(entity)
            setTh(entity.map(item => (Object.getOwnPropertyNames(item)))[0])
        }
    }, [entity, history]);

    const selectDict = (event) => {
        setDict(event.target.value);
        setLoading(true);
    };
    // const NewRow = () => {
    //     setNewRow(true)
    // };

    return (
        <>
            <div className="kt-portlet kt-portlet--mobile mita_row" style = {dict === "country" ? {width: "2948px"} : null}>
                {/*Form*/}
                <form className="kt-form kt-form--label-right">
                    <div className="kt-portlet__body dima_form" style={{backgroundColor: "#9899ac"}}>
                        <div className="form-group row form-group-marginless" style = {dict === "country" ? {maxWidth: "121.4em",manWidth: "121.4em"} : {maxWidth: "192em",manWidth: "192em"}}>

                            <div className="col-lg-4 col-md-9 col-sm-12 dima_pravka" >
                                <Select2
                                    id="dict"
                                    name="dict"
                                    value={dict}
                                    data={dicts}
                                    onChange={selectDict}
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    options={{placeholder: "Dictionaries"}}
                                />
                            </div>

                            <div className="col-lg-4 kt-align-left">

                                <Button
                                    type="button"
                                    className="mitra-button-submit mitra-dict-button"
                                    name="Send"
                                    id="send"
                                    onClick={Send}
                                />
                                {/*<Button*/}
                                {/*    type="button"*/}
                                {/*    className="mitra-dict-button"*/}
                                {/*    name="New"*/}
                                {/*    id="New"*/}
                                {/*    onClick={NewRow}*/}
                                {/*/>*/}

                            </div>
                        </div>
                    </div>
                </form>
                {/*Table*/}
                <div className="mita_row">
                    {loading === false ?
                        <table className="mitra_table_dima">
                            <thead className="mitra_table_dima_head">
                            <tr>
                                {th ? th.map(item => (

                                    <th className="cell_report">{item}</th>
                                )) : null}
                                <th className="cell_report_edit">Edit</th>
                            </tr>
                            </thead>
                            <tbody className="mitra_table_dima_body">
                            {td ? td.map(item => (
                                  <TableRow
                                    entity={item}
                                    url={dict}
                                  />
                                ))
                                : null}
                            </tbody>
                        </table>
                        :null}
                </div>
            </div>
        </>
    )
}