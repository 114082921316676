import React from 'react';
import Switch from "react-switch";
import {config} from "../util/constants";
import axios from "axios";
import {Link} from "react-router-dom";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Button from "../Button";
import Input from "../Input";
import Select2 from "react-select2-wrapper";
import {saveDict} from "../util/dicts";


class NewTerminalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            idLink: null,
            active: false,
            hold: false,
            name: '',
            login: '',
            password: '',
            description: '',
            status: null,
            terminalStatusDict: [],
            type: null,
            typeDict: [],
            bearer: localStorage.getItem("authToken"),
            response: [],
            error: null,
            isLoaded: false,
            disabled: false,
            redirect: false,

        };
        this.handleChangeActive = this.handleChangeActive.bind(this);
        this.handleChangeHold = this.handleChangeHold.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeId = this.handleChangeId.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeLogin = this.handleChangeLogin.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({disabled : true})
        this.sendTerminalRequest();
    }

    handleChangeActive(checked) {
        this.setState({
            active: checked
        });

    };

    handleChangeHold(checked) {
        this.setState({
            hold: checked,
        })
    };

    sendTerminalRequest() {
        const data = {
            name: this.state.name,
            id: parseInt(this.state.id),
            status: this.state.status,
            type: this.state.type,
            active: this.state.active,
            hold: this.state.hold,
            login: this.state.login,
            password: this.state.password,
            description: this.state.description,
            // terminal: this.state.terminal ,
            // operator: this.state.operator
        };

        axios.put(config.url.API_URL + "/terminal", data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        })
            .then(res => {
                    const result = res.data;
                    saveDict();
                    this.setState({
                        id: result.id,
                        statusText: "OK",
                        errorMessage: 'Terminal id: ' + result.id + ' save',
                        modalShow: true,
                        redirect: true,
                    });
                }
            ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    disabled : false,
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    }

    handleChangeName = (e) => {

        const value = e.target.value;
        this.setState({
            name: value,
        })

    };
    handleChangeId = (e) => {

        const value = e.target.value;
        this.setState({
            id: value,
        })

    };
    handleChangeLogin = (e) => {

        const value = e.target.value;
        this.setState({
            login: value,
        })

    };
    handleChangePassword = (e) => {

        const value = e.target.value;
        this.setState({
            password: value,
        })

    };
    handleChangeDescription = (e) => {

        const value = e.target.value;
        this.setState({
            description: value,
        })

    };
    handleClear = () => {
        this.setState({
            active: false,
            hold: false,
            name: '',
            status: null,
            type: null,
            login: '',
            password: '',
            description: '',
        })
    };

    handleChangeStatus(e) {
        let value = e.target.value;
        this.setState({
            status: value,
        })
    };

    handleChangeType(e) {
        let value = e.target.value;
        this.setState({
            type: value,
        })
    };

    componentDidMount() {
        document.title ='New terminal - mitra processing';

        axios.get(config.url.API_URL + '/dictionary/terminalTypeDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    typeDict: result
                });
            },
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

        axios.get(config.url.API_URL + '/dictionary/terminalStatusDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    terminalStatusDict: result
                });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });

            }
        });
    }


    render() {
        let modalClose = () => this.setState({modalShow: false});
        return (
            <div className="kt-portlet kt-portlet--mobile">
                <MyVerticallyCenteredModal
                    redirect={this.state.redirect}
                    id={this.state.id}
                    path={"/terminal/"}
                    heder={this.state.errorStatus}
                    h4={this.state.statusText}
                    text={this.state.errorMessage}
                    show={this.state.modalShow}
                    onHide={modalClose}
                />
                <form className="mitra-form">
                    {this.state.idLink > 0 ?
                        <div className="dima_heder">New terminal: <Link style={{color: "#646c9a"}}
                                                                        to={"/terminal/" + this.state.id}> {this.state.id}</Link>
                        </div> :
                        <div className="dima_heder">New terminal</div>
                    }
                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">ID</label>
                        </div>

                        <div className="input_form_single">
                            <Input
                                type="text"
                                value={this.state.id}
                                placeholder={''}
                                id="id"
                                onChange={this.handleChangeId}
                            />
                        </div>
                    </div>
                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Login</label>
                        </div>

                        <div className="input_form_single">
                            <Input
                                type="text"
                                value={this.state.login}
                                id="phone"
                                onChange={this.handleChangeLogin}
                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Password</label>
                        </div>

                        <div className="input_form_single">
                            <Input
                                type="text"
                                value={this.state.password}
                                id="password"
                                onChange={this.handleChangePassword}
                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Description</label>
                        </div>

                        <div className="input_form_single">
                            <Input
                                type="text"
                                value={this.state.description}
                                id="description"
                                onChange={this.handleChangeDescription}
                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Status</label>
                        </div>
                        <div className="input_form_single">
                            <Select2
                                className="mitra-select"
                                id="statusSelect"
                                data={this.state.terminalStatusDict}
                                value={this.state.status}
                                onChange={this.handleChangeStatus}
                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Type</label>
                        </div>
                        <div className="input_form_single">
                            <Select2
                                className="mitra-select"
                                id="terminalTypeSelect"
                                data={this.state.typeDict}
                                value={this.state.type}
                                onChange={this.handleChangeType}
                            />
                        </div>
                    </div>
                    <div className="dima_form_single_switch" style={{marginTop : "3em"}}>
                        <div className="switch_block_dima">
                            <Switch
                                checked={this.state.active}
                                onChange={this.handleChangeActive}
                                onColor="#acb3ee"
                                onHandleColor="#5867dd"
                                handleDiameter={30}
                                uncheckedIcon={true}
                                checkedIcon={true}
                                boxShadow="true"
                                activeBoxShadow="true"
                                offHandleColor="#e1e2e4"
                                height={20}
                                width={48}
                                className="kt-switch"
                                id="kt-switch"
                            />

                            <div className="label_inline_switch">
                                <label className="label_form">Active</label>
                            </div>
                        </div>
                    </div>
                    <div className="dima_form_single_switch">
                        <div className="switch_block_dima">
                            <Switch
                                checked={this.state.hold}
                                onChange={this.handleChangeHold}
                                onColor="#acb3ee"
                                onHandleColor="#5867dd"
                                handleDiameter={30}
                                uncheckedIcon={true}
                                checkedIcon={true}
                                boxShadow="true"
                                activeBoxShadow="true"
                                offHandleColor="#e1e2e4"
                                height={20}
                                width={48}
                                className="kt-switch"
                                id="kt-switch"
                            />

                            <div className="label_inline_switch">
                                <label className="label_form">Hold</label>
                            </div>
                        </div>
                    </div>
                    <div className="btn_form-dima">
                        <Button
                            disabled={this.state.disabled}
                            type="button"
                            className="mitra-button-submit"
                            onClick={this.handleSubmit}
                            name="Submit"
                        />
                        <Button
                            type="button"
                            onClick={this.handleClear}
                            name="Clear"
                        />
                    </div>
                </form>
            </div>
        );
    }
}
export default NewTerminalForm;
