import React from "react";
import './Table.css'

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table className="kt-datatable__table">

            <thead className="kt-datatable__head" style={{borderBottom: "solid"}}>
            <tr className="kt-datatable__row">


                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'id')}
                >
                    <span style={{width: "90px"}}>
                       ID
                        {props.field === 'id' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'name')}
                >
                    <span style={{width: "90px"}}>
                       Name
                        {props.field === 'name' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'description')}
                >
                    <span style={{width: "300px"}}>
                       Description
                        {props.field === 'description' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'fixed')}
                >
                    <span style={{width: "90px"}}>
                       Fixed
                        {props.field === 'fixed' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'fixedProfit')}
                >
                    <span style={{width: "100px"}}>
                       Fixed profit
                        {props.field === 'fixedProfit' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    // onClick={props.onSort.bind(null, 'id')}
                >
                    <span style={{width: "110px"}}>
                      1 Operator %
                        {props.field === 'sup1' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    // onClick={props.onSort.bind(null, 'id')}
                >
                    <span style={{width: "110px"}}>
                                             2 Operator %
                        {props.field === 'sup2' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    // onClick={props.onSort.bind(null, 'id')}
                >
                    <span style={{width: "110px"}}>
                                             3 Operator %
                        {props.field === 'sup3' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>

            </tr>
            </thead>
        </table>
    </div>
)

