import React from "react";
import "../Button/Buttun.css";

function Button(props) {
    let className = "mitra-button";
    let disabled = props.disabled ? props.disabled : false;
    if(props.className !== undefined && disabled !== true){
        className+= " " + props.className
    }
return(
    <button
        onClick={props.onClick}
        type={props.type}
        id={props.id}
        disabled={disabled}
        className={className}
    >{props.name? props.name:'Button'}</button>
)
}

export default Button;