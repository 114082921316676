import React from "react";
import "./NavIncass.css"
import {Link, useRouteMatch} from "react-router-dom";

export default function NavIncass() {
    let match = useRouteMatch();

    function getNavLinkClass(path) {
        return match.path === path
            ? "mitra-link-nav"
            : "";
    }
 function getNavLinkTextClass(path) {
        return match.path === path
            ? "mitra-link-nav-text"
            : "mitra-link-incass";
    }


    return (
        <div className="mitra_wrapper">
            <div className="mitra_row">
                <nav className="nav_incass">
                    <ul>
                        <li className={getNavLinkClass("/")}><Link className={getNavLinkTextClass("/")}
                                                                   to={"/"}>Dashboard</Link></li>
                        <li className={getNavLinkClass("/monitoring")}><Link className={getNavLinkTextClass("/monitoring")}
                                                                             to={"/monitoring"}>Monitoring</Link></li>
                        <li className={getNavLinkClass("/terminal")}><Link className={getNavLinkTextClass("/terminal")}
                                                                           to={"/terminal"}>Terminals</Link></li>
                        <li className={getNavLinkClass("/incass")}><Link className={getNavLinkTextClass("/incass")}
                                                                         to={"/incass"}>Incass</Link></li>
                        <li className={getNavLinkClass("/payment")}><Link className={getNavLinkTextClass("/payment")}
                                                                          to={"/payment/check"}>Check</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}