import React, {useEffect, useState} from "react";
import {sendGET} from "../util/sendRequest";
import LoadingPage from "../LoadingPage";
import {useHistory} from "react-router-dom";
import "./monitoringIncassator.css";
import MonitoringTableIncass from "./MonitoringTableIncass";

export default function MonitoringIncassator() {
    const history = useHistory();
    const [entity, setEntity] = useState({});
    const [loading, setLoading] = useState(0);

    useEffect(() => {
        document.title = "Monitoring - mitra processing"
        sendGET("/monitoring", null, setEntity, history, setLoading)
    }, [history])
    if (loading === 0) {
        return <LoadingPage/>
    } else {
        return (
            <div className="mitra_wrapper">
                {/*Legends*/}
                {/*<div className="mitra_row">*/}
                {/*    <div className="mitra-monitoring-legend-incass">*/}
                {/*        <div className="monitoring-legend-bloc">*/}
                {/*            <div className="monitoring-legend-ok"/>*/}
                {/*            <div className="monitoring-legend-text-incass">OK</div>*/}
                {/*        </div>*/}

                {/*        <div className="monitoring-legend-bloc">*/}

                {/*            <div className="block-grid-">*/}
                {/*                <div className="monitoring-legend-noPayments"/>*/}
                {/*            </div>*/}
                {/*            <div className="block-grid-">*/}
                {/*                <div className="monitoring-legend-text-incass">No payments</div>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="monitoring-legend-bloc">*/}

                {/*            <div className="block-grid-">*/}
                {/*                <div className="monitoring-legend-oneFalied"/>*/}
                {/*            </div>*/}
                {/*            <div className="block-grid-">*/}
                {/*                <div className="monitoring-legend-text-incass">One failed</div>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="monitoring-legend-bloc">*/}

                {/*            <div className="block-grid-">*/}
                {/*                <div className="monitoring-legend-allFailed"/>*/}
                {/*            </div>*/}
                {/*            <div className="block-grid-">*/}
                {/*                <div className="monitoring-legend-text-incass">All failed</div>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="monitoring-legend-bloc">*/}
                {/*            <div className="block-grid-">*/}
                {/*                <div className="monitoring-legend-offline"/>*/}
                {/*            </div>*/}
                {/*            <div className="block-grid-">*/}
                {/*                <div className="monitoring-legend-text-incass">Offline</div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="mitra_row">
                    <MonitoringTableIncass
                        font={'2em'}
                        list={entity}
                    />
                </div>
            </div>

        );
    }
}