import React from "react";
import Aside from "../aside/Aside";
import {Route, Switch} from "react-router-dom";
import NewTerminalForm from "./NewTerminalForm";
import TerminalForm from "./TerminalForm";
import TerminalList from "./TerminalList";
import {getRoles} from "../util/auth";
import NavIncass from "../aside/navIncass/NavIncass";
import PrivateRoute from "../../routers/PrivateRoute";
import TerminalListIncass from "./TerminalListIncass";

export default function TerminalRoute({match}) {
    return (
        <>
            {getRoles(["SA"]) || getRoles(["ADMIN"]) || getRoles(["MANAGER"]) ? <Aside/> : <NavIncass/>}
            {/*<Switch>*/}
                {getRoles(["SA"]) || getRoles(["ADMIN"]) || getRoles(["MANAGER"]) ?
                    (
                        <>
                            <Switch>

                                <PrivateRoute
                                    path={`${match.path}/new`}
                                    component={NewTerminalForm}
                                />
                                <PrivateRoute
                                    path={`${match.path}/:id`}
                                    component={TerminalForm}
                                />
                                <PrivateRoute
                                    path={`${match.path}/`}
                                    component={TerminalList}
                                />
                            </Switch>
                        </>
                    )
                    :
                    (
                        <>
                            <Route
                                path={`${match.path}/`}
                                component={TerminalListIncass}
                            />
                        </>
                    )
                }
            {/*</Switch>*/}
        </>
    )
}