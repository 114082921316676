import React, {useEffect, useState} from 'react';
import 'select2';
import IncassTable from "./IncassTable";
import moment from 'moment';
import LoadingPage from "../LoadingPage";
import TableHead from "./IncassTableHead";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Button from "../Button";
import Select2 from "react-select2-wrapper";
import {useHistory} from "react-router-dom";
import {sendGET} from "../util/sendRequest";
import Input from "../Input";

// TODO активировать селекты, сделать сортировки по всем столбцам, активировать кнопку clear

export default function IncassList() {


    document.title = 'Incass - mitra processing';

    const history = useHistory();
    const defaultParams = {
        page: 0,
        perPage: 100,
        sort: "desc",
        field: "id",
        place: [],
        city:[],
        terminal: [],
        startDate: moment().startOf('day').tz("Asia/Qatar"),
        endDate: moment().endOf('day').tz("Asia/Qatar"),
        initStartDate: moment().startOf('day'),
        initEndDate: moment().endOf('day'),
    };

    const [list, setList] = useState([{}]);
    const [params, setParams] = useState(defaultParams);
    const [loading, setLoading] = useState(0);
    const [defaultValue, setDefaultValue] = useState(true);

    useEffect(() => {
        sendGET("/incass?perPage=" + params.perPage +
            "&page=" + params.page +
            "&sort=" + params.sort +
            "&field=" + params.field +
            "&terminal=" + params.terminal +
            "&place=" + params.place +
            "&city=" + params.city +
            "&startDate=" + params.startDate +
            "&endDate=" +  params.endDate,
            null
            , setList, history, setLoading);
        console.log("Send",  params.startDate.format("DD.MM.YYYY HH:mm") + ' to ' +  params.endDate.format("DD.MM.YYYY HH:mm"))
    }, [params, history])

    const handleChangeDate = (event, picker) => {
        setParams(params => ({...params, startDate: picker.startDate}));
        setParams(params => ({...params, endDate: picker.endDate}));
        setDefaultValue(false);
        console.log("handleChangeDate", params.startDate.format("DD.MM.YYYY HH:mm") + ' to ' + params.endDate.format("DD.MM.YYYY HH:mm"))
    }
    const handelChangeSelect = (event) => {
        let arr = getOption(event)
        setParams(params => ({...params, [event.target.name]: arr}));
    }

    const pageHandler = ({selected}) => {
        setParams(params => ({...params, page: selected}));
    };

    function getOption(event) {
        let options = event.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        return value
    }

    const Clear = () => {
        setParams(defaultParams)
        setDefaultValue(true);
    }
    const handleSort = (field) => {
        let sort = null;
        if (params.field === field) {
            if (params.sort === "asc") {
                sort = "desc"
            } else {
                sort = "asc"
            }
        } else {
            sort = "asc"
        }
        setParams({...params, field: field, sort: sort})
    }

    return (
        <div>
            {console.log("params", params)}
            <div className="kt-portlet kt-portlet--mobile">
                <form className="kt-form kt-form--label-right">
                    <div className="kt-portlet__body dima_form" style={{backgroundColor: "#9899ac"}}>
                        {/*<div className="dima_heder_list">Incass</div>*/}
                        <div className="form-group row form-group-marginless">

                            <div className="col-lg-4 kt-align-left">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="place"
                                    name="place"
                                    data={JSON.parse(localStorage.getItem("placeDict"))}
                                    value={params.place}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "Agent"}}
                                />
                            </div>

                            <div className="col-lg-4 kt-align-left">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="terminal"
                                    name="terminal"
                                    data={JSON.parse(localStorage.getItem("terminalDict"))}
                                    value={params.terminal}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "Terminal"}}
                                />
                            </div>

                            <div className="col-lg-4 kt-align-left">
                                <Button
                                    type="button"
                                    id="clear"
                                    onClick={Clear}
                                    name="Clear"
                                />
                            </div>
                        </div>

                        <div className="form-group row form-group-marginless kt-margin-t-20">
                            <div className="col-lg-4 kt-align-left">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="city"
                                    name="city"
                                    data={JSON.parse(localStorage.getItem("cityDict"))}
                                    value={params.city}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "City"}}
                                />
                            </div>

                            <div className="col-lg-4 kt-align-left">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="team"
                                    name="team"
                                    data={JSON.parse(localStorage.getItem("incassatorDict"))}
                                    // value={params.teamValue}
                                    // onChange={this.handleChangeTeam}
                                    options={{placeholder: "Team"}}
                                />
                            </div>
                        </div>

                        <div className="form-group row form-group-marginless kt-margin-t-20">

                            <div className="col-lg-4 kt-align-left">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="area"
                                    // name="area"
                                    // data={JSON.parse(localStorage.getItem("areaDict"))}
                                    // value={areaValue}
                                    // onChange={this.handleChange}
                                    options={{placeholder: "Area"}}
                                />
                            </div>

                            <div className="col-lg-4">
                                <DateRangePicker
                                    startDate={params.initStartDate}
                                    endDate={params.initEndDate}
                                    linkedCalendars={false}
                                    timePicker24Hour={true}
                                    opens={"left"}
                                    applyClass={"btn-primary"}
                                    onApply={handleChangeDate}
                                    timePicker={true}
                                    timePickerIncrement={1}
                                    containerStyles={{
                                        width: "100%",
                                        backgroundColor: "#d6d7ea",
                                        borderRadius: "30px"
                                    }}
                                >
                                    <Input
                                        type="text"
                                        readOnly
                                        className="mitra-placeholder"
                                        placeholder={"Today"}
                                        value={defaultValue ? "" : params.startDate.format("DD.MM.YYYY HH:mm") + ' to ' + params.endDate.format("DD.MM.YYYY HH:mm")}
                                    />
                                </DateRangePicker>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
            <TableHead
                onSort={handleSort}
                field={params.field}
                sort={params.sort}
            />
            {loading === 0 ? <LoadingPage/> :
                <IncassTable
                    incassList={list.data}
                    not={list.meta.pages}
                    forcePage={list.meta.page}
                    onPageChange={pageHandler}
                    pageCount={list.meta.pages}
                />
            }
        </div>
    );
}
