import React from 'react';
import './Monitoring.css'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import PopoverTitle from "react-bootstrap/PopoverTitle";
import PopoverContent from "react-bootstrap/PopoverContent";


function MonitoringTable(props) {

    return (
        <div style={{width: "100%"}}>
            {props.list.map(index => (
                <table className={'mitra-table-monitoring'}>
                    <tr className={'mitra-table-monitoring-header-row'}>
                        <th>{index.incassator ? (index.incassator + " - " + index.description) : 'N/A'}</th>
                    </tr>
                    <tr className={'mitra-table-monitoring-row'}>
                        {index.data.map(item => (
                            <td className={'mitra-table-monitoring-cell'}>
                                <OverlayTrigger
                                    trigger={["click", "hover"]}
                                    placement="top"
                                    delay={{show: 900000, hide: 5000}}
                                    overlay={
                                        <Popover>
                                            <PopoverTitle>
                                                <h3 className={'mitra-popover-title'}>{item.billAmount}</h3>
                                            </PopoverTitle>
                                            <PopoverContent>
                                                <ul className={'mitra-popover-content-list'}>
                                                    <li>{item.place ? item.place : 'N/A'}</li>
                                                    <li>{item.phone ? item.phone : 'N/A'}</li>
                                                </ul>
                                            </PopoverContent>
                                        </Popover>
                                    }
                                >
                                    <button
                                        className={
                                            item.status === 0 ? "mitra-button-monitoring monitoring-ok" :
                                                item.status === 1 ? "mitra-button-monitoring monitoring-noPayments" :
                                                    item.status === 2 ? "mitra-button-monitoring monitoring-oneFalied" :
                                                        item.status === 3 ? "mitra-button-monitoring monitoring-allFailed" :
                                                            item.status === 4 ? "mitra-button-monitoring monitoring-offline" : ""
                                        }>{item.name ? item.name : 'N/A'}</button>
                                </OverlayTrigger>
                            </td>
                        ))}
                    </tr>

                </table>))}

        </div>
    )
}

export default MonitoringTable;