import React from "react";
import Aside from "../aside/Aside";
import {Switch} from "react-router-dom";
import RoleRoute from "../../routers/RoleRoute";
import CommissionList from "./CommissionList";

export default function CommissionRoute( {match} ) {
    return (
        <>
            <Aside/>
            <Switch>

                <RoleRoute
                    path={`${match.path}/`}
                    component={CommissionList}
                />
            </Switch>
        </>
    )
}