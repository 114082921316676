import React from 'react'


export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">

        <table className="kt-datatable__table" style={{width: "100%"}}>
            <thead className="kt-datatable__head" style={{borderBottom: "solid"}}>
            <tr
                className="kt-datatable__row"
                style={{left: "0px"}}>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'id')}
                >
                            <span
                                style={{width: "80px"}}
                            >Terminal
                                {props.field === 'id' ? (props.sort === "asc") ?
                                    <i className="flaticon2-arrow-up"/> :
                                    <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'city')}
                >
                            <span
                                style={{width: "80px"}}
                            >City
                                {props.field === 'city' ? (props.sort === "asc") ?
                                    <i className="flaticon2-arrow-up"/> :
                                    <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                {/*<th className="kt-datatable__cell kt-datatable__cell--sort"*/}
                {/*    onClick={props.onSort.bind(null, 'area')}*/}
                {/*>*/}
                {/*            <span*/}
                {/*                style={{width: "70px"}}*/}
                {/*            >Area*/}
                {/*                {props.field === 'area' ? (props.sort === "asc") ?*/}
                {/*                    <i className="flaticon2-arrow-up"/> :*/}
                {/*                    <i className="flaticon2-arrow-down"/> : null}*/}
                {/*            </span>*/}
                {/*</th>*/}

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'place')}
                >
                            <span
                                style={{width: "200px"}}
                            >Agent
                                {props.field === 'place' ? (props.sort === "asc") ?
                                    <i className="flaticon2-arrow-up"/> :
                                    <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'billCount')}
                >                                    <span
                    style={{width: "320px", textAlign: "center"}}
                >Cash
                    {props.field === 'billCount' ? (props.sort === "asc") ?
                        <i className="flaticon2-arrow-up"/> :
                        <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'version')}
                >                            <span
                    style={{width: "80px"}}
                >Version
                    {props.field === 'version' ? (props.sort === "asc") ?
                        <i className="flaticon2-arrow-up"/> :
                        <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'firmware')}
                >                            <span
                    style={{width: "120px"}}
                >Validator
                    {props.field === 'firmware' ? (props.sort === "asc") ?
                        <i className="flaticon2-arrow-up"/> :
                        <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'status')}
                >                            <span
                    style={{width: "120px"}}
                >Status
                    {props.field === 'status' ? (props.sort === "asc") ?
                        <i className="flaticon2-arrow-up"/> :
                        <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'rejectPercent')}
                >                            <span
                    style={{width: "90px",
                        // textAlign:"right",
                        paddingRight:"25px"}}
                >Reject %
                    {props.field === 'rejectPercent' ? (props.sort === "asc") ?
                        <i className="flaticon2-arrow-up"/> :
                        <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'lastOnline')}
                >                            <span
                    style={{width: "230px"}}
                >Last online
                    {props.field === 'lastOnline' ? (props.sort === "asc") ?
                        <i className="flaticon2-arrow-up"/> :
                        <i className="flaticon2-arrow-down"/> : null}
                            </span>
                </th>
            </tr>
            </thead>
        </table>
    </div>
)

