import React from 'react';
import './Table.css'
import ReactPaginate from "react-paginate";
import moment from "moment-timezone";
import {Link} from "react-router-dom";

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table className="kt-datatable__table">
            {props.not === 0 ? <h1 className='noRecordFound'>No record found</h1> :
                <tbody className="kt-datatable__body" style={{display: "block", fontFamily: "Heebo, sans-serif"}}>
                {props.paymentList.map(item => (
                    <tr className="kt-datatable__row"
                        key={item.id}
                        style={props.errorCss.includes(item.statusId) ? {backgroundColor: "#d21938",} : {}}
                    >
                        <td className="kt-datatable__cell"
                            style={(props.errorCss.includes(item.statusId) && item.statusId !== 5)? {color: "#ffffff"} : {color: "transparent"}}
                        >
                            <i
                                className='kt-menu_link-icon fa fa-sync-alt'
                                onClick={(props.errorCss.includes(item.statusId) && item.statusId !== 5) ? props.retray.bind(null, item.id) : null}
                            />
                        </td>

                        <td className="kt-datatable__cell">

                            <span
                                style={props.errorCss.includes(item.statusId) ? {
                                    width: "4em",
                                    color: "#ffffff"
                                } : {width: "4em"}}
                            >
                                <Link to={"/payment/" + item.id}
                                      target="_blank"
                                      style={props.errorCss.includes(item.statusId)
                                          ? {color: "#ffffff",}
                                          : {color: "#6C7293"}
                                      }
                                >{item.id ? item.id : 'N/A'}</Link>
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={props.errorCss.includes(item.statusId) ?
                                    {
                                        width: "17em",
                                        color: "#ffffff",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap"
                                    } : {
                                        width: "17em",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap"
                                    }}
                            >
                                {(item.terminal ? item.terminal : 'N/A') + ' - ' + (item.place ? item.place : 'N/A')}
                        </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={props.errorCss.includes(item.statusId) ? {
                                    width: "8em",
                                    color: "#ffffff"
                                } : {width: "8em"}}
                            >
                                {item.ticket ? item.ticket : 'N/A'}
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={props.errorCss.includes(item.statusId) ? {
                                    width: "11em",
                                    color: "#ffffff",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                } : {
                                    width: "11em",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                {item.operatorName ? item.operatorName : 'N/A'}
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={props.errorCss.includes(item.statusId) ? {
                                    width: "8em",
                                    color: "#ffffff"
                                } : {width: "8em"}}
                            >
                                {item.phone}
                            </span>
                        </td>

                        {/*<td className="kt-datatable__cell">*/}
                        {/*    <span*/}
                        {/*        style={props.errorCss.includes(item.statusId) ? {*/}
                        {/*            width: "80px",*/}
                        {/*            color: "#ffffff"*/}
                        {/*        } : {width: "80px"}}*/}
                        {/*    >*/}
                        {/*        {item.chanel ? item.chanel[0].toUpperCase() + item.chanel.slice(1) : 'N/A'}*/}
                        {/*    </span>*/}
                        {/*</td>*/}

                        <td className="kt-datatable__cell">
                            <span
                                style={props.errorCss.includes(item.statusId) ? {
                                    width: "8em",
                                    color: "#ffffff",
                                    textAlign: "right",
                                    paddingRight:"19px"
                                } : {width: "8em", textAlign: "right", paddingRight:"19px"}}
                            >
                                {item.sum ? item.sum + ' \u00A0' +  item.currencyIn : 0}
                            </span>
                        </td>
                        <td className="kt-datatable__cell">
                            <span
                                style={props.errorCss.includes(item.statusId) ? {
                                    width: "8em",
                                    color: "#ffffff",
                                    textAlign: "right",
                                    paddingRight:"19px"
                                } : {width: "8em", textAlign: "right", paddingRight:"19px"}}
                            >
                                {item.receive ? item.receive + '\u00A0 ' +  item.receiveCurrency : 0}
                            </span>
                        </td>

                        <td className="kt-datatable__cell"
                            // style={{paddingRight: "50px"}}
                        >
                            <span
                                style={props.errorCss.includes(item.statusId) ? {
                                    width: "8em",
                                    color: "#ffffff",
                                    textAlign: "right",
                                    paddingRight:"19px"
                                } : {width: "8em", textAlign: "right", paddingRight:"19px"}}
                            >
                                {item.amount ? item.amount + '\u00A0 ' + item.currencyOut: 0}
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={props.errorCss.includes(item.statusId) ? {
                                    width: "9.5em",
                                    color: "#ffffff",
                                } : {width: "9.5em"}}
                            >
                                {item.statusId === 0 ? moment(item.timeFinal).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : item.timeStart ? moment(item.timeStart).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : "N/A"}
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={props.errorCss.includes(item.statusId) ? {
                                    width: "5.3em",
                                    color: "#ffffff",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textAlign: "right",
                                    paddingRight: "2.1rem"
                                } : {
                                    width: "5.3em",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textAlign: "right",
                                    paddingRight: "2.1rem"
                                }}
                            >
                                {item.status ? item.status : 'N/A'}
                            </span>
                        </td>

                    </tr>
                ))}


                {props.not <= 1 ? <div style={{paddingTop: "100px"}}/> :
                    <div className="d-flex justify-content-center">
                        <ReactPaginate
                            previousLabel={<i className="fas fa-angle-double-left"/>}
                            nextLabel={<i className=" fas fa-angle-double-right"/>}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={props.pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={props.onPageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            nextClassName={'page-item'}
                            previousLinkClassName={'page-item'}
                            nextLinkClassName={'page-item'}
                            forcePage={props.forcePage}
                        />
                    </div>
                }


                </tbody>}

        </table>

    </div>
)
