import React from "react";
import Aside from "../aside/Aside";
import {Switch} from "react-router-dom";
import RoleRoute from "../../routers/RoleRoute";
import Report from "./Report";

export default function ReportRoute({match}) {

    return (
        <>
            <Aside/>
            <Switch>
                <RoleRoute
                    path={`${match.path}/`}
                    component={Report}
                />
            </Switch>
        </>
    )
}

