import React, {useEffect, useState} from 'react';
import Button from "../Button";
import {useHistory} from "react-router-dom"
// import Select2 from "react-select2-wrapper";
import "../incassFormRole.css";
import "../tableIncassRole.css";
import "./terminalTableCell.css";
import {sendGET} from "../util/sendRequest";
import moment from "moment-timezone";
import "../mitraIncassRole.css";
import Select from "../Selects/Select";

export default function TerminalListIncass() {
    const history = useHistory();
    const [clear, setClear] = useState('no')
    const defaultParams = {
        page: 0,
        perPage: 100,
        sort: 'asc',
        field: 'id',
        agent: [],
        city: [],
        area: [],
        terminal: [],
    };
    const [list, setList] = useState([{}]);
    // const badPaymentStatus = JSON.parse(localStorage.getItem("badPaymentStatus"));
    const [params, setParams] = useState(defaultParams);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sendGET("/terminal?page=" + params.page +
            "&perPage=" + params.perPage +
            "&sort=" + params.sort +
            "&field=" + params.field +
            "&terminal=" + params.terminal +
            "&agent=" + params.agent +
            "&city=" + params.city +
            "&area=" + params.area,
            null
            , setList, history, setLoading);
    }, [params, history])

    // const handelChangeSelect = (event) => {
    //     setParams(params => ({...params, [event.target.name]: getOption(event)}));
    // }

    // function getOption(event) {
    //     let options = event.target.options;
    //     let value = [];
    //     for (let i = 0, l = options.length; i < l; i++) {
    //         if (options[i].selected) {
    //             value.push(options[i].value);
    //         }
    //     }
    //     return value
    // }

    const handleSort = (field) => {
        let sort = null;
        if (params.field === field) {
            if (params.sort === "asc") {
                sort = "desc"
            } else {
                sort = "asc"
            }
        } else {
            sort = "asc"
        }
        setParams({...params, field: field, sort: sort})
        // setParams({...params, sort: sort})
    }
    const handleSelect = (select, name) => {
        setClear('no')
        setParams(({...params, [name]: select}));
    }
    const Clear = () => {
        setParams(defaultParams)
        setClear('clear')
    }
    if (loading) {
        return (<></>)
    } else {
        return (
            <div className="mitra_wrapper">

                {/*FORM SEARCH*/}
                <div className="mitra_row">
                    <form className="mitra_form">
                        {/*<div className="mitra-form-row">*/}
                            <div className="mitra-form-item">
                                <Select
                                    name="agent"
                                    dict={JSON.parse(localStorage.getItem("placeDict"))}
                                    handleSelect={handleSelect}
                                    placeholder="Agent"
                                    value={params.agent}
                                    clear={clear}
                                />
                            </div>

                            <div className="mitra-form-item">
                                <Select
                                    name="city"
                                    dict={JSON.parse(localStorage.getItem("cityDict"))}
                                    handleSelect={handleSelect}
                                    placeholder="City"
                                    value={params.city}
                                    clear={clear}
                                />
                            </div>
                            <div className="mitra-form-item">
                                <Select
                                    name="terminal"
                                    dict={JSON.parse(localStorage.getItem("terminalDict"))}
                                    handleSelect={handleSelect}
                                    placeholder="Terminal"
                                    value={params.terminal}
                                    clear={clear}
                                />
                            </div>
                            {/*<div className="mitra-form-item">*/}
                                <Button
                                    className="mitra-button-incass"
                                    type="button"
                                    name="Clear"
                                    id="clear"
                                    onClick={Clear}
                                />
                            {/*</div>*/}
                        {/*</div>*/}

                        {/*    <div className="mitra-form-item">*/}

                        {/*        <Select2*/}
                        {/*            multiple*/}
                        {/*            className="form-control kt-select2"*/}
                        {/*            style={{width: "100%", height: "calc(2.5em + 2em + 1px)"}}*/}
                        {/*            id="terminal"*/}
                        {/*            name="terminal"*/}
                        {/*            data={JSON.parse(localStorage.getItem("terminalDict"))}*/}
                        {/*            value={params.terminal}*/}
                        {/*            onChange={handelChangeSelect}*/}
                        {/*            options={{placeholder: "Terminal"}}*/}
                        {/*        />*/}
                        {/*    </div>*/}

                        {/*</div>*/}

                        {/*<div className="mitra-form-row">*/}
                        {/*    <div className="mitra-form-item">*/}
                        {/*        <Select2*/}
                        {/*            multiple*/}
                        {/*            className="form-control kt-select2 select-incass"*/}
                        {/*            style={{width: "100%", height: "25em"}}*/}
                        {/*            id="city"*/}
                        {/*            name="city"*/}
                        {/*            data={JSON.parse(localStorage.getItem("cityDict"))}*/}
                        {/*            value={params.city}*/}
                        {/*            onChange={handelChangeSelect}*/}
                        {/*            options={{placeholder: "City"}}*/}
                        {/*        />*/}

                        {/*    </div>*/}

                        {/*    <div className="mitra-form-item">*/}
                        {/*        <Select2*/}
                        {/*            multiple*/}
                        {/*            className="form-control kt-select2"*/}
                        {/*            style={{width: "100%", height: "calc(2.5em + 2em + 1px)"}}*/}
                        {/*            id="area"*/}
                        {/*            name="area"*/}
                        {/*            // data={JSON.parse(localStorage.getItem("areaDict"))}*/}
                        {/*            // value={areaValue}*/}
                        {/*            // onChange={this.handleChange}*/}
                        {/*            options={{placeholder: "Area"}}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </form>
                </div>
                {/*TABLE*/}
                <div className="mitra_row">
                    <table className="mitra_table">
                        <thead className="mitra_table_head">
                        <tr>
                            {/*<th onClick={handleSort.bind(null, "id")}*/}
                            {/*    className="terminal_cell_1">Terminal {params.field === 'id' ? (params.sort === "asc" ?*/}
                            {/*    <small><i className="flaticon2-arrow-up"/></small> :*/}
                            {/*    <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>*/}

                            <th onClick={handleSort.bind(null, "city")}
                                className="terminal_cell_2">City {params.field === 'city' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                            <th onClick={handleSort.bind(null, "id")}
                                className="terminal_cell_3">Terminal &
                                Agent {params.field === 'id' ? (params.sort === "asc" ?
                                    <small><i className="flaticon2-arrow-up"/></small> :
                                    <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                            <th onClick={handleSort.bind(null, "billAmount")}
                                className="terminal_cell_4">Cash {params.field === 'billAmount' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>


                            {/*<th onClick={handleSort.bind(null, "version")}*/}
                            {/*    className="terminal_cell_5">Version {params.field === 'version' ? (params.sort === "asc" ?*/}
                            {/*    <small><i className="flaticon2-arrow-up"/></small> :*/}
                            {/*    <small> <i className="flaticon2-arrow-down"/></small>) : ""}</th>*/}

                            {/*<th onClick={handleSort.bind(null, "firmware")}*/}
                            {/*    className="terminal_cell_6">Validator {params.field === 'firmware' ? (params.sort === "asc" ?*/}
                            {/*    <small><i className="flaticon2-arrow-up"/></small> :*/}
                            {/*    <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>*/}


                            <th onClick={handleSort.bind(null, "status")}
                                className="terminal_cell_7">Status {params.field === 'status' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                            <th onClick={handleSort.bind(null, "rejectPercent")}
                                className="terminal_cell_8">Reject
                                % {params.field === 'rejectPercent' ? (params.sort === "asc" ?
                                    <small><i className="flaticon2-arrow-up"/></small> :
                                    <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                            <th onClick={handleSort.bind(null, "lastOnline")}
                                className="terminal_cell_9">Last
                                online {params.field === 'lastOnline' ? (params.sort === "asc" ?
                                    <small><i className="flaticon2-arrow-up"/></small> :
                                    <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                        </tr>
                        </thead>
                        <tbody className="mitra_table_body">
                        {list.data.length === 0 ?
                            (
                                <tr className="mitra_table_tr_noFound">
                                    <td className="mitra_table_td_noFound">No record found</td>
                                </tr>
                            )
                            :
                            (
                                list.data.map(item => (
                                        <tr key={item.id}
                                            className={item.monitoringStatus > 1 && (item.id > 1000002) ? "mitra_table_body_tr-error" : ""}>
                                            {/*<td className="terminal_cell_1">{item.name ? item.name : 'N/A'}</td>*/}
                                            <td className="terminal_cell_2">{item.city ? item.city : 'N/A'}</td>
                                            <td className="incass_cell_3">{(item.name ? item.name : 'N/A') + ' - ' + (item.place ? item.place : 'N/A')}</td>
                                            <td className="terminal_cell_4">
                                                <div className="progress incass-bar" style={{width: "100%"}}>
                                                    <div className="progress-bar left"
                                                         role="progressbar"
                                                         aria-valuenow={item.billCount ? item.billCount : 0}
                                                         aria-valuemin="0"
                                                         aria-valuemax="600"
                                                         style={{width: item.billCount ? item.billCount : 0}}
                                                        // style={{width: 600}}
                                                    >
                                                    </div>
                                                    <div
                                                        className="progress-bar-title incassRole">{item.billAmount ? item.billAmount : 0}</div>
                                                </div>
                                                {/*<div className="mitra-progress-bar">{item.billAmount ? item.billAmount : 0}</div>*/}
                                            </td>
                                            {/*<td className="terminal_cell_5">{item.version ? item.version : 'N/A'}</td>*/}
                                            {/*<td className="terminal_cell_6">{item.firmware ? item.firmware : 'N/A'}</td>*/}
                                            <td className="terminal_cell_7" >{item.status ? item.status : 'N/A'}</td>
                                            <td className="terminal_cell_8">{item.rejectPercent ? item.rejectPercent : 0}</td>
                                            <td className="terminal_cell_9">{item.lastOnline ? moment(item.lastOnline).tz("Asia/Qatar").format("DD.MM.YYYY\u00A0\u00A0\u00A0HH:mm") : "N/A"}</td>
                                        </tr>
                                    )
                                ))}
                        </tbody>
                    </table>
                </div>

            </div>
        );
    }
}



