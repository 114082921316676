import React from "react";
import './Table.css'

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table className="kt-datatable__table">

            <thead className="kt-datatable__head" style={{borderBottom: "solid"}}>
            <tr className="kt-datatable__row">

                <th className="kt-datatable__cell kt-datatable__cell--sort">
                    <span style={{width: "1em"}}/>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, "id")}
                >
                    <span style={{width: "4em"}}>
                        #
                        {props.field === 'id' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                    </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'terminal')}
                >
                        <span style={{width: "17em"}}>
                            Terminal & Agent
                            {props.field === 'terminal' ? (props.sort === "asc") ?
                                <i className="flaticon2-arrow-up"/> :
                                <i className="flaticon2-arrow-down"/> : null}
                        </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'ticket')}
                >
                        <span style={{width: "8em"}}>Transaction
                            {props.field === 'ticket' ? (props.sort === "asc") ?
                                <i className="flaticon2-arrow-up"/> :
                                <i className="flaticon2-arrow-down"/> : null}
                        </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'operatorName')}
                >
                        <span style={{width: "11em"}}>
                            Product
                            {props.field === 'operatorName' ? (props.sort === "asc") ?
                                <i className="flaticon2-arrow-up"/> :
                                <i className="flaticon2-arrow-down"/> : null}
                        </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'phone')}
                >
                        <span style={{width: "8em"}}>Account
                            {props.field === 'phone' ? (props.sort === "asc") ?
                                <i className="flaticon2-arrow-up"/> :
                                <i className="flaticon2-arrow-down"/> : null}
                        </span>
                </th>

                {/*<th className="kt-datatable__cell kt-datatable__cell--sort"*/}
                {/*    // onClick={props.onSort.bind(null, 'chanel')}*/}
                {/*>*/}
                {/*        <span style={{width: "80px"}}>Channel*/}
                {/*            {props.field === 'chanel' ? (props.sort === "asc") ?*/}
                {/*            <i className="flaticon2-arrow-up"/> :*/}
                {/*            <i className="flaticon2-arrow-down"/> : null}*/}
                {/*        </span>*/}
                {/*</th>*/}

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'sum')}
                >
                        <span style={{
                            width: "8em",
                            // textAlign: "right",
                            paddingLeft:"62px"
                        }}>Got
                            {props.field === 'sum' ? (props.sort === "asc") ?
                                <i className="flaticon2-arrow-up"/> :
                                <i className="flaticon2-arrow-down"/> : null}
                        </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'receive')}
                >
                        <span style={{
                            width: "8em",
                            // textAlign: "right",
                            paddingLeft:"56px"
                        }}>Paid
                            {props.field === 'receive' ? (props.sort === "asc") ?
                                <i className="flaticon2-arrow-up"/> :
                                <i className="flaticon2-arrow-down"/> : null}
                        </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'amount')}

                >
                        <span style={{
                            width: "8em",
                            // textAlign: "right",
                            paddingLeft:"56px"
                        }}>Cost
                            {props.field === 'amount' ? (props.sort === "asc") ?
                                <i className="flaticon2-arrow-up"/> :
                                <i className="flaticon2-arrow-down"/> : null}
                        </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'timeStart')}

                >
                        <span style={{
                            width: "9.5em",
                        }}>Date & Time
                            {props.field === 'timeStart' ? (props.sort === "asc") ?
                                <i className="flaticon2-arrow-up"/> :
                                <i className="flaticon2-arrow-down"/> : null}
                        </span>
                </th>

                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'status')}
                >
                        <span style={{
                            width: "5.3em",
                            // textAlign: "right"
                        }}>Status{props.field === 'status' ? (props.sort === "asc") ?
                            <i className="flaticon2-arrow-up"/> :
                            <i className="flaticon2-arrow-down"/> : null}
                        </span>
                </th>
            </tr>
            </thead>
        </table>
    </div>
)

