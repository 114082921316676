import React from "react";
import Aside from "../aside/Aside";
import {Route, Switch} from "react-router-dom";
import RoleRoute from "../../routers/RoleRoute";
import MonitoringList from "./MonitoringList";
import MonitoringIncassator from "./MonitoringIncassator";
import {getRoles} from "../util/auth";
import NavIncass from "../aside/navIncass/NavIncass";

export default function MonitoringRoute({match}) {
    return (
        <>
            {getRoles(["SA"]) || getRoles(["ADMIN"]) || getRoles(["MANAGER"]) ? <Aside/> : <NavIncass/>}
            <Switch>
                {getRoles(["SA"]) || getRoles(["ADMIN"]) || getRoles(["MANAGER"]) ?
                    (
                        <RoleRoute
                            path={`${match.path}/`}
                            component={MonitoringList}
                        />
                    )
                    :
                    (
                        <Route
                            path={`${match.path}/`}
                            component={MonitoringIncassator}
                        />
                    )
                }
            </Switch>
        </>
    )
}