import React from "react";
import axios from "axios";
import {config} from "../util/constants";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Switch from "react-switch";
import Button from "../Button";
import Input from "../Input";
import Select2 from "react-select2-wrapper";

class CommandNew extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bearer: localStorage.getItem("authToken"),

            command: [],

            terminalDict: [],
            commandTypeDict: [],

            parameter: '',
            commandType: 0,
            toAllTerminals: false,
            terminal: [],

            errorStatus: '',
            statusText: '',
            errorMessage: '',
            modalShow: false,
            isLoaded: null,
        };
        this.handleChangeParameter = this.handleChangeParameter.bind(this);
        this.handleCommandType = this.handleCommandType.bind(this);
        this.handleChangeTerminal = this.handleChangeTerminal.bind(this);
        this.handleChangeToAllTerminals = this.handleChangeToAllTerminals.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title ='New command - mitra processing';
        axios.get(config.url.API_URL + "/dictionary/commandTypeDict", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    commandTypeDict: result,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        });

        axios.get(config.url.API_URL + "/dictionary/terminalDict", {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    terminalDict: result,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        });

    };

    handleChangeParameter(event) {
        let parameter = event.target.value;
        this.setState({
            parameter
        })
    };

    handleCommandType(event) {
        let commandType = event.target.value;
        this.setState({
            commandType
        })
    };

    handleChangeTerminal(event) {
        this.setState({
            terminal: this.getOption(event),
        });
    };

    getOption(event) {
        let options = event.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        return value
    };

    handleChangeToAllTerminals(checked) {
        this.setState({
            toAllTerminals: checked,
            terminal: []
        })
    };

    handleClear = () => {
        this.setState({
            parameter: '',
            commandType: null,
            toAllTerminals: false,
            terminal: [],
        })
    };

    handleSubmit(event) {
        event.preventDefault();
        this.sendNewCommand();
    }

    sendNewCommand() {
        const data = {
            parameter: this.state.parameter,
            typeId: this.state.commandType > 0 ? parseInt(this.state.commandType) : 0,
            toAllTerminals: this.state.toAllTerminals,
            terminalList: this.state.terminal,
        };

        console.log('data', data);
        axios.post(config.url.API_URL + "/command/newCommand", data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res;

                this.setState({
                    modalShow: true,
                    errorStatus: result.status,
                    statusText: result.data.desc,
                    errorMessage: result.data.msg
                })
            }
        ).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {
                let response = e.response;
                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                    isLoaded: this.state.isLoaded + 1,
                });
            }
        });
    };


    render() {
        let modalClose = () => this.setState({modalShow: false});
        return (
            <div className="kt-portlet kt-portlet--mobile">

                <MyVerticallyCenteredModal
                    heder={this.state.errorStatus}
                    h4={this.state.statusText}
                    text={this.state.errorMessage}
                    show={this.state.modalShow}
                    onHide={modalClose}
                />

                <form className="mitra-form">
                    <p className="dima_heder">New command</p>
                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Parameter</label>
                        </div>

                        <div className="input_form_single">
                            <Input type="text"
                                   value={this.state.parameter}
                                   className="form-control dima_form_control"
                                   id="Parameter"
                                   onChange={this.handleChangeParameter}
                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Type</label>
                        </div>
                        <div className="input_form_single">
                            <Select2
                                id='type'
                                value={this.state.commandType}
                                data={this.state.commandTypeDict}
                                onChange={this.handleCommandType}
                                className={'mitra-select'}
                            />
                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="label_inline">
                            <label className="label_form">Terminal</label>
                        </div>
                        <div className="input_form_single">
                            <Select2
                                multiple
                                disabled={this.state.toAllTerminals}
                                id='terminal'
                                value={this.state.terminal}
                                data={this.state.terminalDict}
                                onChange={this.handleChangeTerminal}
                                className={'mitra-select'}
                                options={{
                                    placeholder: this.state.toAllTerminals ? "All terminals" : ""
                                }}
                            />


                        </div>
                    </div>

                    <div className="dima_form_single">
                        <div className="switch_block_dima">

                            <Switch
                                checked={this.state.toAllTerminals}
                                onChange={this.handleChangeToAllTerminals}
                                onColor="#acb3ee"
                                onHandleColor="#5867dd"
                                handleDiameter={30}
                                uncheckedIcon={true}
                                checkedIcon={true}
                                boxShadow="true"
                                activeBoxShadow="true"
                                offHandleColor="#e1e2e4"
                                height={20}
                                width={48}
                                className="kt-switch"
                                id="toAllTerminals"
                            />
                            <div className="label_inline_switch">
                                <label className="label_form">All terminals</label>
                            </div>
                        </div>
                    </div>
                    <div className="btn_form-dima">
                        <Button
                            className={'mitra-button-submit'}
                            name={'Submit'}
                            onClick={this.handleSubmit}
                        />
                        <Button
                            name={'Clear'}
                            onClick={this.handleClear}
                        />

                    </div>
                </form>
            </div>
        );
    }
}

export default CommandNew;