import React, {useEffect, useState} from "react";
import {config} from "../../util/constants";
import axios from "axios";
import moment from "moment-timezone";
import {Link} from "react-router-dom";
import Input from "../../Input";
import Button from "../../Button";
import "../../tableIncassRole.css";
import "../../incassFormRole.css";
import "./paymentCheckingCell.css";
import "../../mitraIncassRole.css";

function PaymentChecking() {
    const defaultParams = {search:""}
    const [params, setParams] = useState(defaultParams);
    const [list, setList] = useState([{}]);
    const [loaded, setLoaded] = useState(true);
    useEffect(
        () => {
            document.title = 'Payment checking - mitra processing';
        }
    );

    const sendCheckRequest = () => {
        axios.get("/check", {
            baseURL: config.url.API_URL,
            params: params }).then(res => {
            const result = res.data;
            setList(result);
            if (result.length > 0) {
                setLoaded(false)
            } else {
                setLoaded(true)
            }
        });
    };

    const handleInputChange = (event) => {
        setParams({...params, search : event.target.value});
    };

    const handleClear = (event) => {
        event.persist();
        setParams(defaultParams);
    }

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
            sendCheckRequest();
        }
    };

    return (

        <div className="mitra_wrapper">
            <div className="mitra_row">
                <form className="mitra_form-check" onSubmit={handleSubmit}>
                    <div className="mitra-form-item-check">
                        <Input
                            className="mitra-input-check"
                            type="text"
                            id="search"
                            name="search"
                            placeholder="Enter phone or transaction number"
                            value={params.search}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mitra-form-item-check">
                        <Button
                            className="mitra-button-submit mitra-button-check-submit mitra-button-incass"
                            type="submit"
                            name="Check"
                        />
                    </div>
                    <div className="mitra-form-item-check">
                        <Button
                            className="mitra-button-incass"
                            onClick={handleClear}
                            type="submit"
                            name="Clear"
                        />
                    </div>
                </form>
            </div>
            <div className="mitra_row">
                {loaded ? null
                    :
                    <table className="mitra_table">
                        <thead className="mitra_table_head">
                        <tr>
                            {/*<th>ID</th>*/}
                            <th className="check_cell_1">Terminal & Agent</th>
                            <th className="check_cell_2">Transaction</th>
                            <th className="check_cell_3">Product</th>
                            <th className="check_cell_4">Account</th>
                            <th className="check_cell_5">Got</th>
                            <th className="check_cell_6">Paid</th>
                            <th className="check_cell_7">Date & Time</th>
                            <th className="check_cell_8">Status</th>
                        </tr>
                        </thead>

                        <tbody className="mitra_table_body">

                        {list.map(item => (
                            <tr key={item.id}>
                                {/*<td>{item.id}</td>*/}
                                <td className="check_cell_1">{(item.terminal ? item.terminal : 'N/A') + ' - ' + (item.place ? item.place : 'N/A')}</td>
                                <td className="check_cell_2"> <Link className="mitra-link" to={"checking/" + item.id} target="_blank">{item.ticket}</Link></td>
                                <td className="check_cell_3">{item.operatorName ? item.operatorName : 'N/A'}</td>
                                <td className="check_cell_4">{item.phone}</td>
                                <td className="check_cell_5">{item.sum + '\u00A0' + item.currencyIn}</td>
                                <td className="check_cell_6">{item.receive ? item.receive + '\u00A0' + item.receiveCurrency : 0}</td>
                                <td className="check_cell_7">{item.timeFinal ? moment(item.timeFinal).tz("Asia/Qatar").format("DD.MM.YYYY\u00A0\u00A0\u00A0HH:mm") : item.timeStart ? moment(item.timeStart).tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : "N/A"}</td>
                                <td className="check_cell_8">{item.status}</td>
                            </tr>))}
                        </tbody>
                    </table>
                    }
            </div>
        </div>
    );
}

export default PaymentChecking;