import React from 'react';
import './List.css'
import ReactPaginate from "react-paginate";
// import moment from "moment-timezone";

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">

        <table className="kt-datatable__table">

            {props.not === 0 ? <h1 className='noRecordFound'>No record found</h1> :
                <tbody className="kt-datatable__body" style={{display: "block", fontFamily: "Heebo, sans-serif"}}>
                {props.List.map(item => (
                    <tr className="kt-datatable__row"
                        key={item.id}

                    >

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "40px"}}
                         >
                             {item.id ? item.id : 'N/A'}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "300px"}}
                         >
                             {item.account ? item.account : 'N/A'}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "80px"}}
                         >
                             {item.typeName ? item.typeName : 'N/A'}
                         </span>
                        </td>
                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "100px", textAlign:"center"}}
                         ><i
                             className="fas fa-exchange-alt"
                             onClick={props.change.bind(null,item.id, item.account, item.typeName)}
                         />
                         </span>
                        </td>
                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "50px", textAlign:"center"}}
                         ><i
                             className="flaticon2-delete"
                             onClick={props.delete.bind(null,item.id, item.account)}
                         />
                         </span>
                        </td>
                    </tr>
                ))}


                {props.not === 1 ? <div style={{paddingTop: "100px"}}/> :
                    <div className="d-flex justify-content-center">
                        <ReactPaginate
                            previousLabel={<i className="fas fa-angle-double-left"/>}
                            nextLabel={<i className=" fas fa-angle-double-right"/>}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={props.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={props.onPageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            nextClassName={'page-item'}
                            previousLinkClassName={'page-item'}
                            nextLinkClassName={'page-item'}
                            forcePage={props.forcePage}
                        />
                    </div>}


                </tbody>}

        </table>

    </div>
)
