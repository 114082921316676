import React from "react";
import './List.css'

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table className="kt-datatable__table">

            <thead className="kt-datatable__head" style={{borderBottom: "solid"}}>
            <tr className="kt-datatable__row">


                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'id')}
                >
                    <span style={{width: "40px"}}>
                       ID
                        {props.field === 'id' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'account')}
                >
                    <span style={{width: "300px"}}>
                       Account
                        {props.field === 'account' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                            <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    onClick={props.onSort.bind(null, 'typeName')}
                >
                    <span style={{width: "80px"}}>
                       Type
                        {props.field === 'typeName' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :
                        <i className="flaticon2-arrow-down"></i> : null}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    // onClick={props.onSort.bind(null, 'autoRefill')}
                >
                    <span style={{width: "100px", textAlign: "center"}}>
                       Change Type
                        {/*{props.field === 'autoRefill' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :*/}
                        {/*<i className="flaticon2-arrow-down"></i> : null}*/}
                    </span>
                </th>
                <th className="kt-datatable__cell kt-datatable__cell--sort"
                    // onClick={props.onSort.bind(null, 'terminal')}
                >
                    <span style={{width: "50px", textAlign: "center"}}>
                       Delete
                        {/*{props.field === 'terminal' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :*/}
                        {/*<i className="flaticon2-arrow-down"></i> : null}*/}
                    </span>
                </th>

                {/*<th className="kt-datatable__cell kt-datatable__cell--sort"*/}
                {/*    // onClick={props.onSort.bind(null, 'autoRefill')}*/}
                {/*>*/}
                {/*    <span style={{width: "100px", textAlign: "center"}}>*/}
                {/*       Change Type*/}
                {/*        /!*{props.field === 'autoRefill' ? (props.sort === "asc") ? <i className="flaticon2-arrow-up"></i> :*!/*/}
                {/*        /!*<i className="flaticon2-arrow-down"></i> : null}*!/*/}
                {/*    </span>*/}
                {/*</th>*/}
            </tr>
            </thead>
        </table>
    </div>
)

