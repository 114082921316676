import React, {useEffect, useState} from 'react';
import Button from "../Button";
import {useHistory} from "react-router-dom"
// import Select2 from "react-select2-wrapper";
import {sendGET} from "../util/sendRequest";
import moment from "moment-timezone";
import Input from "../Input";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from "../Selects/Select";

export default function IncassatorRouteList() {

    document.title = 'Payment Incass - mitra processing';
    const [clear, setClear] = useState('no')

    const history = useHistory();
    const defaultParams = {
        page: 0,
        perPage: 100,
        sort: "desc",
        field: "id",
        city: [],
        place: [],
        terminal: [],
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
    };

    const [list, setList] = useState([{}]);
    const [params, setParams] = useState(defaultParams);
    const [loading, setLoading] = useState(true);
    const [defaultValue, setDefaultValue] = useState(true);

    useEffect(() => {
        sendGET("/incass?perPage=" + params.perPage +
            "&page=" + params.page +
            "&sort=" + params.sort +
            "&field=" + params.field +
            "&terminal=" + params.terminal +
            "&city=" + params.city +
            "&place=" + params.place +
            "&startDate=" + params.startDate +
            "&endDate=" + params.endDate,
            null
            , setList, history, setLoading);
    }, [params, history])

    const handleChangeDate = (event, picker) => {
        setParams(params => ({...params, startDate: picker.startDate}));
        setParams(params => ({...params, endDate: picker.endDate}));
        setDefaultValue(false);
    }
    // const handelChangeSelect = (event) => {
    //     let arr = getOption(event)
    //     setParams(params => ({...params, [event.target.name]: arr}));
    // }
    const handleSelect = (select, name) => {
        setClear('no')
        setParams(({...params, [name]: select}));
    }
    const Clear = () => {
        setParams(defaultParams)
        setClear('clear')
        setDefaultValue(true);
    }

    // function getOption(event) {
    //     let options = event.target.options;
    //     let value = [];
    //     for (let i = 0, l = options.length; i < l; i++) {
    //         if (options[i].selected) {
    //             value.push(options[i].value);
    //         }
    //     }
    //     return value
    // }


    const handleSort = (field) => {
        let sort = null;
        if (params.field === field) {
            if (params.sort === "asc") {
                sort = "desc"
            } else {
                sort = "asc"
            }
        } else {
            sort = "asc"
        }
        setParams({...params, field: field, sort: sort})
    }

    if (loading) {
        return (<></>)
    } else {
        return (
            <div className="mitra_wrapper">

                {/*FORM SEARCH*/}
                <div className="mitra_row">
                    <form className="mitra_form">
                        {/*<div className="mitra-form-row">*/}
                        <div className="mitra-form-item">
                            <Select
                                name="place"
                                dict={JSON.parse(localStorage.getItem("placeDict"))}
                                handleSelect={handleSelect}
                                placeholder="Agent"
                                value={params.place}
                                clear={clear}
                            />
                        </div>
                        {/*<div className="mitra-form-item">*/}

                        {/*    <Select*/}
                        {/*        name="city"*/}
                        {/*        dict={JSON.parse(localStorage.getItem("cityDict"))}*/}
                        {/*        value={params.city}*/}
                        {/*        handleSelect={handleSelect}*/}
                        {/*        placeholder={"City"}*/}
                        {/*        clear={clear}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className="mitra-form-item">
                            <Select
                                name="terminal"
                                dict={JSON.parse(localStorage.getItem("terminalDict"))}
                                value={params.terminal}
                                handleSelect={handleSelect}
                                placeholder={"Terminal"}
                                clear={clear}
                            />
                        </div>
                        {/*<div className="mitra-form-item">*/}
                        {/*    <Select2*/}
                        {/*        multiple*/}
                        {/*        className="form-control kt-select2"*/}
                        {/*        style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}*/}
                        {/*        id="area"*/}
                        {/*        name="area"*/}
                        {/*         data={JSON.parse(localStorage.getItem("areaDict"))}*/}
                        {/*         value={areaValue}*/}
                        {/*        onChange={this.handleChange}*/}
                        {/*        options={{placeholder: "Area"}}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className="mitra-form-item">
                            <DateRangePicker
                                startDate={params.startDate}
                                endDate={params.endDate}
                                linkedCalendars={false}
                                timePicker24Hour={true}
                                opens={"left"}
                                applyClass={"btn-primary"}
                                onApply={handleChangeDate}
                                timePicker={true}
                                timePickerIncrement={30}
                                containerStyles={{
                                    width: "100%",
                                    backgroundColor: "#d6d7ea",
                                    borderRadius: "30px"
                                }}
                            >
                                <Input
                                    type="text"
                                    readOnly
                                    style={{fontSize:"30px", height:"2.7em", fontWeight:"500"}}
                                    className="mitra-placeholder mitra-input-check-date"
                                    placeholder={"Today"}
                                    value={defaultValue ? "" : params.startDate.format("DD.MM.YYYY HH:mm") + ' to ' + params.endDate.format("DD.MM.YYYY HH:mm")}
                                />
                            </DateRangePicker>
                        </div>

                        <Button
                            className="mitra-button-incass"
                            type="button"
                            name="Clear"
                            id="clear"
                            onClick={Clear}
                        />
                    </form>
                </div>
                {/*TABLE*/}
                <div className="mitra_row">
                    <table className="mitra_table">
                        <thead className="mitra_table_head">
                        <tr>
                            <th onClick={handleSort.bind(null, "date")}
                                className="incass_cell_1">Date &
                                Time {params.field === 'date' ? (params.sort === "asc" ?
                                    <small><i className="flaticon2-arrow-up"/></small> :
                                    <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                            <th onClick={handleSort.bind(null, "city")}
                                className="incass_cell_2">City {params.field === 'city' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                            <th onClick={handleSort.bind(null, "terminal")}
                                className="incass_cell_3">Terminal &
                                Agent {params.field === 'terminal' ? (params.sort === "asc" ?
                                    <small><i className="flaticon2-arrow-up"/></small> :
                                    <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                            <th onClick={handleSort.bind(null, "sumPayment")}
                                className="incass_cell_4">Cash {params.field === 'sumPayment' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : ""}</th>

                            <th onClick={handleSort.bind(null, "b1")}
                                className="incass_cell_5">1 {params.field === 'b1' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                            <th onClick={handleSort.bind(null, "b5")}
                                className="incass_cell_6">5 {params.field === 'b5' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                            <th onClick={handleSort.bind(null, "b10")}
                                className="incass_cell_7">10 {params.field === 'b10' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                            <th onClick={handleSort.bind(null, "b50")}
                                className="incass_cell_8">50 {params.field === 'b50' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>
                            <th onClick={handleSort.bind(null, "b100")}
                                className="incass_cell_9">100 {params.field === 'b100' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>
                            <th onClick={handleSort.bind(null, "b200")}
                                className="incass_cell_10">200 {params.field === 'b200' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>
                            <th onClick={handleSort.bind(null, "b500")}
                                className="incass_cell_11">500 {params.field === 'b500' ? (params.sort === "asc" ?
                                <small><i className="flaticon2-arrow-up"/></small> :
                                <small> <i className="flaticon2-arrow-down"/></small>) : " "}</th>

                        </tr>
                        </thead>
                        <tbody className="mitra_table_body">
                        {list.data.length === 0 ?
                            (
                                <tr className="mitra_table_tr_noFound">
                                    <td className="mitra_table_td_noFound">No record found</td>
                                </tr>
                            )
                            :
                            (
                                list.data.map(item => (
                                        <tr>
                                            <td className="incass_cell_1">{item.date ? moment(item.date).tz("Asia/Qatar").format("DD.MM.YYYY\u00A0\u00A0\u00A0HH:mm") : "N/A"}</td>
                                            <td className="incass_cell_2">{item.city ? item.city : 'N/A'}</td>
                                            <td className="incass_cell_3">{(item.terminal ? item.terminal : 'N/A') + ' - ' + (item.place ? item.place : 'N/A')}</td>
                                            <td className="incass_cell_4">{item.sumPayment ? item.sumPayment : 0}</td>
                                            <td className="incass_cell_5">{item.b1 ? item.b1 : 0}</td>
                                            <td className="incass_cell_6">{item.b5 ? item.b5 : 0}</td>
                                            <td className="incass_cell_7">{item.b10 ? item.b10 : 0}</td>
                                            <td className="incass_cell_8">{item.b50 ? item.b50 : 0}</td>
                                            <td className="incass_cell_9">{item.b100 ? item.b100 : 0}</td>
                                            <td className="incass_cell_10">{item.b200 ? item.b200 : 0}</td>
                                            <td className="incass_cell_11">{item.b500 ? item.b500 : 0}</td>
                                        </tr>
                                    )
                                ))}
                        </tbody>
                    </table>
                </div>

            </div>
        );
    }

}



