import React, {useEffect, useState} from 'react';
import Button from "../Button";
import {Link, useHistory} from "react-router-dom"
import Select2 from "react-select2-wrapper";
import "../incassFormRole.css";
import "../tableIncassRole.css";
import "./terminalTableCell.css";
import {sendGET} from "../util/sendRequest";
import LoadingPage from "../LoadingPage";
import TerminalTable from "./TerminalTable";
import TerminalHeadTable from "./TerminalHeadTable";

export default function TerminalListIncass() {
    document.title = 'Terminals - mitra processing';

    const history = useHistory();

    const defaultParams = {
        page: 0,
        perPage: 100,
        sort: 'asc',
        field: 'id',
        status: [],
        agent: [],
        city: [],
        area: [],
        terminal: []
    };

    const [list, setList] = useState([{}]);
    // const badPaymentStatus = JSON.parse(localStorage.getItem("badPaymentStatus"));
    const [params, setParams] = useState(defaultParams);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sendGET("/terminal?page=" + params.page +
            "&perPage=" + params.perPage +
            "&terminal=" + params.terminal +
            "&sort=" + params.sort +
            "&field=" + params.field +
            "&status=" + params.status +
            "&agent=" + params.agent +
            "&city=" + params.city +
            "&area=" + params.area,
            null
            , setList, history, setLoading);
    }, [params, history])

    const handelSort = (id) => {
        let sortDefault = "desc"
        if (params.sort === sortDefault) {
            sortDefault = "asc"
        } else {
            sortDefault = "desc"
        }
        setParams({...params, sort: sortDefault, field: id})
    }
    const handelChangeSelect = (event) => {
        let arr = getOption(event)
        setParams(params => ({...params, [event.target.name]: arr, page: 0}));
    }
    function getOption(event) {
        let options = event.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        return value
    }

    const Clear = () => {
        setParams(defaultParams)
    }

    const pageHandler = ({selected}) => {
        setParams(params => ({...params, page: selected}));
    };
console.log("Terminals", list)
    return (
        <>
            <div className="kt-portlet kt-portlet--mobile">
                <form className="kt-form kt-form--label-right">
                    <div className="kt-portlet__body dima_form" style={{backgroundColor: "#9899ac"}}>
                        {/*<div className="dima_heder_list">Terminal</div>*/}
                        <div className="form-group row form-group-marginless">
                            <div className="col-lg-4 col-md-9 col-sm-12">
                                <Select2
                                    multiple
                                    name="agent"
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="agent"
                                    data={JSON.parse(localStorage.getItem("placeDict"))}
                                    value={params.agent}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "Agent"}}
                                />
                            </div>

                            <div className="col-lg-4 col-md-9 col-sm-12">
                                <Select2
                                    multiple
                                    name="terminal"
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="terminal"
                                    data={JSON.parse(localStorage.getItem("terminalDict"))}
                                    value={params.terminal}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "Terminal"}}
                                />
                            </div>

                            <div className="col-lg-4 kt-align-left">
                                <Button
                                    type="button"
                                    name="Clear"
                                    id="clear"
                                    onClick={Clear}
                                />
                            </div>
                        </div>

                        <div className="form-group row form-group-marginless kt-margin-t-20">
                            <div className="col-lg-4 col-md-9 col-sm-12">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="city"
                                    name="city"
                                    data={JSON.parse(localStorage.getItem("cityDict"))}
                                    value={params.city}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "City"}}
                                />
                            </div>

                            <div className="col-lg-4 col-md-9 col-sm-12">
                                <Select2
                                    multiple
                                    className="form-control kt-select2"
                                    style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                    id="team"
                                    name="team"
                                    data={JSON.parse(localStorage.getItem("incassatorDict"))}
                                    value={params.team}
                                    onChange={handelChangeSelect}
                                    options={{placeholder: "Team"}}
                                />
                            </div>

                            <div className="col-lg-4 kt-align-left">
                                <Link to="/terminal/new"
                                      target="_blank"
                                >
                                    <Button
                                        type="button"
                                        className="mitra-button-submit"
                                        name="New"
                                        id="new"
                                    />
                                </Link>
                            </div>
                        </div>

                        {/*<div className="form-group row form-group-marginless kt-margin-t-20">*/}
                        {/*    <div className="col-lg-4 col-md-9 col-sm-12">*/}
                        {/*        <Select2*/}
                        {/*            multiple*/}
                        {/*            className="form-control kt-select2"*/}
                        {/*            style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}*/}
                        {/*            id="area"*/}
                        {/*            name="area"*/}
                        {/*            // data={JSON.parse(localStorage.getItem("areaDict"))}*/}
                        {/*            // value={params.area}*/}
                        {/*            // onChange={handelChangeSelect}*/}
                        {/*            options={{placeholder: "Area"}}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </form>
            </div>

            <TerminalHeadTable
                onSort={handelSort}
                field={params.field}
                sort={params.sort}
            />

            {loading ? <LoadingPage/> :
                <TerminalTable
                    // testTerminal={this.state.testTerminal}
                    // terminalStatusDict={this.state.terminalStatusDict}
                    terminalList={list.data}
                    not={list.meta.pages}
                    forcePage={list.meta.page - 1}
                    onPageChange={pageHandler}
                    pageCount={list.meta.pages}
                />
            }
        </>
    );
}

