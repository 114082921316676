import React from 'react';
import 'select2';
import PlaceTable from "./PlaceTable";
import PlaceTableHeder from "./PlaceTableHeder";
import LoadingPage from "../LoadingPage";
import {config} from "../util/constants";
import axios from "axios";
import {Link} from "react-router-dom";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Select2 from "react-select2-wrapper";
import Button from "../Button";

// TODO активировать селекты, сделать сортировки по всем столбцам, активировать кнопку clear

class PlaceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorStatus: [],
            bearer: localStorage.getItem("authToken"),

            error: null,
            isLoaded: false,
            modalShow: false,

            field: 'name',
            sort: 'asc',


            placeList: [],

            permissionValue: [],
            permissionDict: [],

            teamValue: [],
            teamDict: [],

            placeDict: [],
            placeValue: [],

            areaDict: [],
            areaValue: [],

            commissionDict: [],
            commissionValue: [],

            cityDict: [],
            cityValue: [],

            pages: 0,
            page: 0
        };
        this.timer = null;
        this.handleClick = this.handleClick.bind(this);
        this.handleChangePlace = this.handleChangePlace.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleChangeCommission = this.handleChangeCommission.bind(this);
        this.handleChangeTeam = this.handleChangeTeam.bind(this);
    }

    handleClick = sortField => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            console.log('SortFild', sortField)
            const sortDefaul = this.state.sort === 'asc' ? 'desc' : 'asc';
            this.setState({
                sort: sortDefaul,
                field: sortField,
                page: 0
            })
            this.sendPlaceRequest()
        }, 1);
    };

    pageHandler = ({selected}) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({page: selected})
            this.sendPlaceRequest()
        }, 1)
    };


    handleChangePlace(e) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                placeValue: this.getOption(e),
                page: 0,
                isLoadedTable: false,
            })
            this.sendPlaceRequest()
        }, 1);
    };

    handleChangeCity(e) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                cityValue: this.getOption(e),
                page: 0,
                isLoadedTable: false,
            })
            this.sendPlaceRequest()
        }, 1);
    };

    handleChangeCommission(e) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                commissionValue: this.getOption(e),
                page: 0,
                isLoadedTable: false,
            })
            this.sendPlaceRequest()
        }, 1);
    };

    handleChangeTeam(e) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                teamValue: this.getOption(e),
                page: 0,
                isLoadedTable: false,
            })
            this.sendPlaceRequest()
        }, 1);
    };


    sendPlaceRequest() {
        let request = config.url.API_URL + "/place" +
            "?field=" + this.state.field +
            "&sort=" + this.state.sort +
            "&page=" + this.state.page +
            "&city=" + this.state.cityValue +
            "&incassator=" + this.state.teamValue +
            "&name=" + this.state.placeValue;
        console.log('request',request)
        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                isLoaded: true,
                placeList: result.data,
                pages: result.meta.pages
            });
            console.log('PlaceList', this.state.placeList)
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                console.log(errorMessage);
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });
    }

    getOption(event) {
        let options = event.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        return value
    }

    componentDidMount() {
        document.title ='Agent - mitra processing';

        axios.get(config.url.API_URL + '/dictionary/cityDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                cityDict: result
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

        ////TODO Commission add dict
        //
        // axios.get(config.url.API_URL + '/dictionary/terminalDict', {
        //     headers: {
        //         'Authorization': 'Bearer ' + this.state.bearer
        //     }
        // }).then(res => {
        //     const result = res.data;
        //     this.setState({
        //         commissionDict: result
        //     });
        // })
        //     .catch(e => {
        //                 localStorage.setItem("auth", false);
        //                 this.props.setAuth(false);
        //
        //             });
        //
        ////TODO Area add dict
        // axios.get(config.url.API_URL + '/dictionary/areaDict', {
        //     headers: {
        //         'Authorization': 'Bearer ' + this.state.bearer
        //     }
        // }).then(res => {
        //     const result = res.data;
        //     this.setState({
        //         areaDict: result
        //     });
        // })
        //     .catch(e => {
        //                 localStorage.setItem("auth", false);
        //                 this.props.setAuth(false);
        //
        //             });
        //

        axios.get(config.url.API_URL + '/dictionary/placeDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                placeDict: result
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });

        ////TODO permission add dict
        // axios.get(config.url.API_URL + '/dictionary/permissionStatus', {
        //     headers: {
        //         'Authorization': 'Bearer ' + this.state.bearer
        //     }
        // }).then(res => {
        //     const result = res.data;
        //     this.setState({
        //         permissionStatus: result
        //     });
        // })
        //    .catch(e => {
        //                 localStorage.setItem("auth", false);
        //                 this.props.setAuth(false);
        //
        //             });
        //
        //
        axios.get(config.url.API_URL + '/dictionary/incassatorDict', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                teamDict: result
            });
        }).catch(e => {
            if (e.request.status === 401) {
                localStorage.setItem("auth", this.props.setAuth);
                this.props.setAuth(false);
            } else {

                let response = e.response;

                let statusText = response.data.status;
                let errorMessage = response.data.message;
                let errorStatus = response.status;
                this.setState({
                    modalShow: true,
                    errorStatus,
                    statusText,
                    errorMessage,
                });
            }
        });

    }


    render() {
        let modalClose = () => this.setState({modalShow: false});
        const {
            placeList, placeDict, placeValue, commissionDict, commissionValue, cityDict, cityValue, permissionDict, permissionValue, areaDict, areaValue,
            teamDict, teamValue
        } = this.state;
        return (
            <div>
                <div className="kt-portlet kt-portlet--mobile">
                    <MyVerticallyCenteredModal
                        heder={this.state.errorStatus}
                        h4={this.state.statusText}
                        text={this.state.errorMessage}
                        show={this.state.modalShow}
                        onHide={modalClose}
                    />
                    <form className="kt-form kt-form--label-right">
                        <div className="kt-portlet__body dima_form" style={{backgroundColor: "#9899ac"}}>
                            {/*<div className="dima_heder_list">Place</div>*/}
                            <div className="form-group row form-group-marginless">
                                <div className="col-lg-4 kt-align-left">
                                    <Select2
                                        multiple
                                        className="form-control kt-select2"
                                        style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                        id="placesSelect"
                                        data={placeDict}
                                        value={placeValue}
                                        onChange={this.handleChangePlace}
                                        options={{placeholder: "Agent"}}
                                    />
                                </div>

                                <div className="col-lg-4 kt-align-left">
                                    <Select2
                                        multiple
                                        className="form-control kt-select2"
                                        style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                        id="commissionSelect"
                                        data={commissionDict}
                                        value={commissionValue}
                                        onChange={this.handleChangeCommission}
                                        options={{placeholder: "Commission"}}
                                    />
                                </div>

                                <div className="col-lg-4 kt-align-left">
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            this.setState({
                                                teamValue: [],
                                                placeValue: [],
                                                permissionValue: [],
                                                commissionValue: [],
                                                areaValue: [],
                                                cityValue: [],
                                            })
                                        }}
                                        name="Clear"
                                    />
                                </div>
                            </div>

                            <div className="form-group row form-group-marginless kt-margin-t-20">
                                <div className="col-lg-4 kt-align-left">
                                    <Select2
                                        multiple
                                        className="form-control kt-select2"
                                        style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                        id="citySelect"
                                        data={cityDict}
                                        value={cityValue}
                                        onChange={this.handleChangeCity}
                                        options={{placeholder: "City"}}
                                    />
                                </div>

                                <div className="col-lg-4 kt-align-left">
                                    <Select2
                                        multiple
                                        className="form-control kt-select2"
                                        style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                        id="permissionSelect"
                                        data={permissionDict}
                                        value={permissionValue}
                                        onChange={this.handleChange}
                                        options={{placeholder: "Permission"}}
                                    />
                                </div>

                                <div className="col-lg-4 kt-align-left">
                                    <Link to="/place/new"
                                          target="_blank"
                                    >
                                        <Button
                                            type="button"
                                            className="mitra-button-submit"
                                            name="New"
                                        />
                                    </Link>
                                </div>
                            </div>

                            <div className="form-group row form-group-marginless kt-margin-t-20">
                                <div className="col-lg-4 kt-align-left">
                                    <Select2
                                        multiple
                                        className="form-control kt-select2"
                                        style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                        id="areaSelect"
                                        data={areaDict}
                                        value={areaValue}
                                        onChange={this.handleChange}
                                        options={{placeholder: "Area"}}
                                    />
                                </div>

                                <div className="col-lg-4 kt-align-left">
                                    <Select2
                                        multiple
                                        className="form-control kt-select2"
                                        style={{width: "100%", height: "calc(1.5em + 1em + 1px)"}}
                                        id="teamSelect"
                                        data={teamDict}
                                        value={teamValue}
                                        onChange={this.handleChange}
                                        options={{placeholder: "Team"}}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <PlaceTableHeder
                    onSort={this.handleClick}
                    field={this.state.field}
                    sort={this.state.sort}
                />

                {this.state.isLoaded === false ? <LoadingPage/> :
                    <PlaceTable
                        placeList={placeList}
                        not={this.state.pages}
                        forcePage={this.state.page - 1}
                        onPageChange={this.pageHandler}
                        pageCount={this.state.pages}
                    />
                }
            </div>
        );
    }
}

export default PlaceList;
