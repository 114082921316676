import React from 'react';
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";
// import {Link} from "react-router-dom";

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">

        <table className="kt-datatable__table">
            {props.not === 0 ? <h1 style={{textAlign: "center", paddingTop: "20%"}}>No record found</h1> :
                <tbody
                    className="kt-datatable__body"
                    style={{display: "block", fontFamily: "Heebo, sans-serif"}}>
                {props.placeList.map(item => (
                    <tr className="kt-datatable__row" style={{left: "0px"}}
                        key={item.id}
                    >

                        <td className="kt-datatable__cell">
                            <span
                                style={{width: "200px"}}
                            >
                            <Link to={"/place/" + item.id}
                                  target="_blank"
                                  style={
                                      {color: "#6C7293"}
                                  }
                            >
                            {item.name ? item.name : 'N/A'}
                            </Link>
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={{
                                    width: "80px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                {item.terminal ? item.terminal : 'N/A'}
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={{width: "60px"}}
                            >
                                {item.averageTurnoverWeek ? item.averageTurnoverWeek : 'N/A'}
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={{width: "60px"}}
                            >
                                {item.averageTurnoverTwoWeek ? item.averageTurnoverTwoWeek : 'N/A'}
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={{width: "60px"}}
                            >
                                {item.averageTurnoverMonth ? item.averageTurnoverMonth : 'N/A'}
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={{width: "60px"}}
                            >
                                {item.averageTurnoverYear ? item.averageTurnoverYear : 'N/A'}
                            </span>
                        </td>

                        <td className="kt-datatable__cell">
                            <span
                                style={{
                                    width: "90px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                {item.city ? item.city : 'N/A'}
                            </span>
                        </td>

                        {/*<td className="kt-datatable__cell">*/}
                        {/*    <span*/}
                        {/*        style={{*/}
                        {/*            width: "80px",*/}
                        {/*            overflow: "hidden",*/}
                        {/*            whiteSpace: "nowrap"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        {item.area ? item.area : 'N/A'}*/}
                        {/*    </span>*/}
                        {/*</td>*/}

                        <td className="kt-datatable__cell">
                            <span
                                style={{
                                    width: "120px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                {item.commissionPlan ? item.commissionPlan : 'N/A'}
                            </span>
                        </td>

                        {/*<td className="kt-datatable__cell">*/}
                        {/*    <span*/}
                        {/*        style={{*/}
                        {/*            width: "120px",*/}
                        {/*            overflow: "hidden",*/}
                        {/*            whiteSpace: "nowrap"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        {item.permission ? item.permission : 'N/A'}*/}
                        {/*    </span>*/}
                        {/*</td>*/}

                        {/*<td className="kt-datatable__cell"><span*/}
                        {/*    style={{width: "180px"}}*/}
                        {/*>*/}
                        {/*    {item.validTill}*/}
                        {/*</span>*/}
                        {/*</td>*/}
                    </tr>
                ))}


                {props.not === 1 ? <div style={{paddingTop: "100px"}}/> :
                    <div className="d-flex justify-content-center">
                        <ReactPaginate
                            previousLabel={<i className="fas fa-angle-double-left"/>}
                            nextLabel={<i className=" fas fa-angle-double-right"/>}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={props.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={props.onPageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            nextClassName={'page-item'}
                            previousLinkClassName={'page-item'}
                            nextLinkClassName={'page-item'}
                            forcePage={props.forcePage}
                        />
                    </div>}

                </tbody>}

        </table>

    </div>
)
