import React from 'react';
import 'select2';
import UserTable from "./UserTable";
import TableHead from "./UserTableHead";
import {Link} from "react-router-dom";
import {config} from "../util/constants";
import axios from "axios";
import LoadingPage from "../LoadingPage";
import ModalChangePassword from "../Modal/ModalChangePassword";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import Button from "../Button";


class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bearer: localStorage.getItem("authToken"),
            error: null,
            isLoaded: false,
            isLoadedTable: false,
            modalShow: false,
            userList: [],

            id: null,
            name: '',
            password: '',
            field: '',
            sort: 'asc',
            modalShowError: false,
            errorStatus: '',
            statusText: '',
            errorMessage: '',

        };
        this.handelChangePassword = this.handelChangePassword.bind(this);
        this.sendChangePassword = this.sendChangePassword.bind(this);
        this.openModal = this.openModal.bind(this);
        this.handleClick = this.handleClick.bind(this);


    }

    componentDidMount() {
        document.title ='User - mitra processing';

        const request = config.url.API_URL + "/user";
        axios.get(request, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
                const result = res.data;
                this.setState({
                    userList: result,
                    isLoadedTable: true

                });

            },
        ).catch(e => {
            localStorage.setItem("auth", false);
            this.props.setAuth(false);

        });
    }

    pageHandler = ({selected}) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({page: selected});
            this.sendPaymentRequest()
        }, 1)
    };

    handelChangePassword(event) {
        let password = event.target.value;
        this.setState({password});
        console.log(this.state.password)
    }

    openModal(value, userId, userName) {
        let modalShow = value;
        let name = userName;
        let id = userId;

        this.setState({
            modalShow,
            name,
            id
        });
        console.log(id)
        console.log(name)
    };
    handleClick = sortField => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            console.log('SortFild', sortField)
            const sortDefaul = this.state.sort === 'asc' ? 'desc' : 'asc';
            this.setState({
                sort: sortDefaul,
                field: sortField,
                page: 0
            })
            this.sendUserRequest()
        }, 1);
    };

    sendUserRequest() {
        let requestUser = config.url.API_URL + "/user" +
            "?field=" + this.state.field +
            "&sort=" + this.state.sort
        axios.get(requestUser, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            console.log('result',result)
            this.setState({
                // isLoaded: true,
                userList: result.data ? result.data : result,
            });
        });
    }
    sendChangePassword() {
        const data = {
            password: this.state.password,
        };
        axios.get(config.url.API_URL + "/user/" + this.state.id, data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        })
            .then(res => {

                const result = res.data;
                console.log(result);

                this.setState({
                    modalShow: false,

                });
            })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;
                    console.log(response)
                    // let statusText = response.data.status;
                    // let errorMessage = response.data.message;
                    // let errorStatus = response.status;
                    // this.setState({
                    //     modalShow:false,
                    //     modalShowError: true,
                    //     errorStatus,
                    //     statusText,
                    //     errorMessage,
                    // });
                }
            });
    }

    render() {
        let modalCloseError = () => this.setState({modalShowError: false});
        let modalClose = () => this.setState({modalShow: false});

        return (
            <div>
                <div className="kt-portlet kt-portlet--mobile">
                    <MyVerticallyCenteredModal
                        heder={this.state.errorStatus}
                        h4={this.state.statusText}
                        text={this.state.errorMessage}
                        show={this.state.modalShowError}
                        onHide={modalCloseError}
                    />
                    <ModalChangePassword
                        value={this.state.password}
                        title={this.state.name}
                        show={this.state.modalShow}
                        changePassword={this.handelChangePassword}
                        sendChangePassword={this.sendChangePassword}
                        onHide={modalClose}
                    />
                    <form className="kt-form kt-form--label-right">
                        <div className="kt-portlet__body dima_form" style={{backgroundColor: "#9899ac"}}>
                            <div className="form-group row form-group-marginless">
                                <div className="col-lg-4 kt-align-left">
                                    <Link
                                        target = "_blank"
                                        to="/user/new"
                                    >
                                        <Button
                                            type="button"
                                            className="mitra-button-submit"
                                            name="Add"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <TableHead
                    onSort={this.handleClick}
                    field={this.state.field}
                    sort={this.state.sort}

                />
                {this.state.isLoadedTable === false ? <LoadingPage/> :
                    <UserTable
                        userList={this.state.userList}
                        onPageChange={this.pageHandler}
                        openModal={this.openModal}
                    />
                }
            </div>
        );
    }
}

export default UserList;
