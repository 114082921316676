import React from "react";
import Switch from "react-switch";
import MyVerticallyCenteredModal from "../Modal/MyVerticallyCenteredModal";
import axios from "axios";
import {config} from "../util/constants";
import Button from "../Button";


class SettingsSuppliers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bearer: localStorage.getItem("authToken"),
            enable: props.enable,
            minBalance: props.minBalance,
            id: props.id,
            name: props.name,
            disabled: true,
            update: false,

            desk: '',
            errorStatus: '',
            statusText: '',
            errorMessage: '',
            modalShow: false,
        };
        this.handelSuppliers = this.handelSuppliers.bind(this)
        this.handelEdit = this.handelEdit.bind(this)
        this.handelSuppliersLimit = this.handelSuppliersLimit.bind(this)
        this.saveLimits = this.saveLimits.bind(this)
    }

    handelSuppliers(checked) {
        this.setState({enable: checked})
        this.sendSuppliers(this.state.id, checked, this.state.minBalance)
    }

    handelSuppliersLimit(event) {
        let minBalance = event.target.value
        this.setState({minBalance})
    }

    saveLimits() {
        this.sendSuppliers(this.state.id, this.state.enable, this.state.minBalance)
    }

    handelEdit() {
        this.setState({disabled: !this.state.disabled, minBalance: this.props.minBalance})
    }

    sendSuppliers(id, enable, minBalance) {
        const data = {}
        axios.post(config.url.API_URL + "/settings/updateSupplier", data, {
            params: {
                enable: enable,
                id: parseInt(id),
                minBalance: parseInt(minBalance)
            },
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            let result = res.data;
            let statusText = result.desc;
            let errorStatus = res.status;
            this.setState({
                minBalance,
                disabled: true,
                statusText,
                errorStatus,
                modalShow: true,
            })
            this.props.update();
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {
                    let response = e.response;
                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    let errorStatus = response.status;
                    this.setState({
                        enable: this.props.enable,
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });
    }

    render() {
        let modalClose = () => this.setState({modalShow: false});
        return (
            <div className="switch_block_dima">
                <MyVerticallyCenteredModal
                    heder={this.state.errorStatus}
                    h4={this.state.statusText}
                    text={this.state.errorMessage}
                    show={this.state.modalShow}
                    onHide={modalClose}
                />
                <div className="limit_operator">
                    <input type="number"
                           disabled={this.state.disabled}
                           value={this.state.minBalance}
                           className="dima_form_control_settings"
                           id="limitRechargeQatar"
                           onChange={this.handelSuppliersLimit}
                    />
                    <div className="edit_icon_container">
                        <i
                            className="fa fa-edit"
                            onClick={this.handelEdit}
                        />
                    </div>
                </div>
                <Switch
                    checked={this.state.enable}
                    onChange={this.handelSuppliers}
                    onColor="#acb3ee"
                    onHandleColor="#5867dd"
                    handleDiameter={30}
                    uncheckedIcon={true}
                    checkedIcon={true}
                    boxShadow="true"
                    activeBoxShadow="true"
                    offHandleColor="#e1e2e4"
                    height={20}
                    width={48}
                    className="kt-switch"
                    id="kt-switch"
                />
                <div className="label_inline_switch_settings label_inline_switch_country">
                    <label className="label_form">{this.state.name}</label>
                </div>
                {this.state.disabled ? "" :
                    <Button
                        onClick={this.saveLimits}
                        type="button"
                        className=" mitra-button-submit"
                        name="Save"
                    />}
            </div>
        )
    }
}

export default SettingsSuppliers;