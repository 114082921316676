import React from 'react';
import 'select2';
import './Monitoring.css'
import {config} from "../util/constants";
import axios from "axios";
import MonitoringTable from "./MonitoringTable";


class MonitoringList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorStatus: [],
            error: null,
            isLoaded: false,
            isLoadedTable: false,
            bearer: localStorage.getItem("authToken"),

            monitoringList: [],
            //
            // cityDict: [],
            // cityValue: [],
            //
            // terminalDict: [],
            // terminalValue: [],


        };


    }


    componentDidMount() {
        document.title ='Monitoring - mitra processing';


        axios.get(config.url.API_URL + '/monitoring', {
            headers: {
                'Authorization': 'Bearer ' + this.state.bearer
            }
        }).then(res => {
            const result = res.data;
            this.setState({
                monitoringList: result,
                isLoaded: this.state.isLoaded + 1,
            });
        })
            .catch(e => {
                if (e.request.status === 401) {
                    localStorage.setItem("auth", this.props.setAuth);
                    this.props.setAuth(false);
                } else {

                    let response = e.response;

                    let statusText = response.data.status;
                    let errorMessage = response.data.message;
                    console.log(errorMessage);
                    let errorStatus = response.status;
                    this.setState({
                        modalShow: true,
                        errorStatus,
                        statusText,
                        errorMessage,
                    });
                }
            });
    }


    render() {
        return (
            <div>
                <div className="kt-portlet kt-portlet--mobile">
                    <form className="kt-form kt-form--label-right">
                        <div className="dima-monitoring-body kt-portlet__body " style={{backgroundColor: "#9899ac"}}>
                            <div className="form-group row form-group-marginless">
                                <div className="monitoring-legend-bloc">
                                    <div className="block-grid-">
                                        <div className="monitoring-legend-ok"/>

                                    </div>
                                    <div className="block-grid-">
                                        <div className="monitoring-legend-text">OK</div>
                                    </div>
                                </div>

                                <div className="monitoring-legend-bloc">

                                    <div className="block-grid-">
                                        <div className="monitoring-legend-noPayments"/>
                                    </div>
                                    <div className="block-grid-">
                                        <div className="monitoring-legend-text">No payments</div>

                                    </div>
                                </div>
                                <div className="monitoring-legend-bloc">

                                    <div className="block-grid-">
                                        <div className="monitoring-legend-oneFalied"/>
                                    </div>
                                    <div className="block-grid-">
                                        <div className="monitoring-legend-text">One failed</div>

                                    </div>
                                </div>
                                <div className="monitoring-legend-bloc">

                                    <div className="block-grid-">
                                        <div className="monitoring-legend-allFailed"/>
                                    </div>
                                    <div className="block-grid-">
                                        <div className="monitoring-legend-text">All failed</div>

                                    </div>
                                </div>
                                <div className="monitoring-legend-bloc">

                                    <div className="block-grid-">
                                        <div className="monitoring-legend-offline"/>
                                    </div>
                                    <div className="block-grid-">
                                        <div className="monitoring-legend-text">Offline</div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </form>
                </div>
                <MonitoringTable
                    list={this.state.monitoringList}
                />
            </div>
        );
    }
}

export default MonitoringList;
