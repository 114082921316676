import React from "react";
import Modal from "react-bootstrap/Modal";
import "./Modal.css"
import Button from "../Button";
import {Link} from "react-router-dom";

class MyVerticallyCenteredModal extends React.Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/*<Modal.Header>*/}
                {/*    /!*<Modal.Title id="contained-modal-title-vcenter">*!/*/}
                {/*    /!*    {this.props.heder}*!/*/}
                {/*    /!*</Modal.Title>*!/*/}
                {/*</Modal.Header>*/}
                <Modal.Body>
                    <h4 className="mitra-h4-modal">{this.props.h4}</h4>
                    <p className="mitra-paragraph-modal">
                        {this.props.text}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.redirect ?
                        <Link
                            to={this.props.path+this.props.id}>
                            <Button
                                name="OK"
                                className="mitra-button-submit"
                                onClick={this.props.onHide}
                            />
                        </Link>
                        :
                        <Button
                            name="OK"
                            className="mitra-button-submit"
                            onClick={this.props.onHide}
                        />
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}
export default MyVerticallyCenteredModal;