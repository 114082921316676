import React from 'react';
import './User.css'
import {Link} from "react-router-dom";

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">

        <table className="kt-datatable__table">

                <tbody className="kt-datatable__body" style={{display: "block", fontFamily: "Heebo, sans-serif"}}>
                {props.userList.map(item => (
                    <tr className="kt-datatable__row"
                        key={item.id}

                    >

                        <td className="kt-datatable__cell">
                         <span
                             style={{width:"200px"}}
                         >
                             <Link to={"/user/" + item.id}
                                   target = "_blank"
                                   style={{color: "#6C7293"}}
                             >
                             {item.name}
                             </Link>
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width:"200px"}}
                         >
                             {item.description}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width:"200px"}}
                         >
                             {item.isAdmin === true ? "Admin"
                                 : item.isIncassator === true ? "Incassator"
                                : item.isManager === true ? "Manager": "N/A"}
                         </span>
                        </td>
                        {/*<td className="kt-datatable__cell">*/}
                        {/* <span*/}
                        {/*     style={{width:"200px", paddingLeft:"50px"}}*/}
                        {/* >*/}
                        {/*   <i*/}
                        {/*       onClick={props.openModal.bind(null, true, item.id, item.name)}*/}

                        {/*       className="flaticon2-edit"></i>*/}
                        {/* </span>*/}
                        {/*</td>*/}
                    </tr>
                ))}
                </tbody>
        </table>

    </div>
)
