import React from 'react';
import './Table.css'
import ReactPaginate from "react-paginate";
import moment from "moment-timezone";

export default props => (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">

        <table className="kt-datatable__table">

            {props.not === 0 ? <h1 className='noRecordFound'>No record found</h1> :
                <tbody className="kt-datatable__body" style={{display: "block", fontFamily: "Heebo, sans-serif"}}>
                {props.commmandsList.map(item => (
                    <tr className="kt-datatable__row"
                        key={item.id}

                    >

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "100px"}}
                         >
                             {item.id ? item.id : 'N/A'}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "200px"}}
                         >
                             {item.terminal ? item.terminal : 'N/A'}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "200px"}}
                         >
                             {props.valueForDict(props.commandTypeDict ,item.type)}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "200px"}}
                         >
                             {item.done === false ? (<i className="fa fa-ban"/>) : (<i className="fa fa-check"/>)}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "300px"}}
                         >
                             {item.parameter ? item.parameter : 'N/A'}
                         </span>
                        </td>

                        <td className="kt-datatable__cell">
                         <span
                             style={{width: "300px"}}
                         >
                             {item.date ? moment(item.date).tz("Asia/Qatar").tz("Asia/Qatar").format("DD.MM.YYYY \u00A0\u00A0\u00A0HH:mm") : 'N/A'}
                         </span>
                        </td>

                    </tr>
                ))}
                {props.not === 1 ? <div style={{paddingTop: "100px"}}/> :
                    <div className="d-flex justify-content-center">
                        <ReactPaginate
                            previousLabel={<i className="fas fa-angle-double-left"/>}
                            nextLabel={<i className=" fas fa-angle-double-right"/>}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={props.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={props.onPageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            nextClassName={'page-item'}
                            previousLinkClassName={'page-item'}
                            nextLinkClassName={'page-item'}
                            forcePage={props.forcePage}
                        />
                    </div>}


                </tbody>}

        </table>

    </div>
)
