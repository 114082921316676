import React, {useState} from 'react';
import "./login.css"
import {useHistory} from "react-router-dom";
import Button from "../Button";
import {sendSimplePOST} from "../util/sendRequest";
import {saveAuth} from "../util/auth";
import {saveDict} from "../util/dicts";


export default function Login() {
    const history = useHistory();

    const [request, setRequest] = useState({
        username: '',
        password: ''
    });
    const [message, setMessage] = useState(null);


    const handelChange = (event) => {
        event.persist();
        setRequest(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

     const  handelSubmit = (event) => {
        event.preventDefault();
        sendSimplePOST('/auth', null, request).then(x => {
            if (x === 'wrong username or password') {
                setMessage('Login error')
            } else {
                saveAuth(x);
                saveDict();
                history.push("/");
            }
        })
    }

    return (
        <div className="mitra-body">
            <div className="login" style={{width: "260px"}}>
                <img alt="Mitra processing"
                     src="/images/logo_01.png"
                     style={{marginLeft: "40%", paddingBottom: "15px"}}
                />
                <form onSubmit={handelSubmit}>

                    <div className="block_input_login">
                        <input
                            className="form-control input_login"
                            type="text"
                            name={"username"}
                            value={request.username}
                            placeholder="User name"
                            onChange={handelChange}
                            required
                        />
                    </div>
                    <div className="block_input_login">

                        <input
                            className="form-control input_login"
                            type="password"
                            name={"password"}
                            value={request.password}
                            placeholder="Password"
                            onChange={handelChange}
                            required
                        />
                    </div>
                    <div className="btn_login">
                        <Button
                            className="mitra-button-login"
                            name="Login"
                        />
                    </div>
                </form>

            </div>
            <div className="massage_login"><p>{message}</p></div>
        </div>
    );
}


