import React from 'react';


class ScrollTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false
        };
    }


    render() {
        return (
            <div id="kt_scrolltop" className="kt-scrolltop">
                <i className="fa fa-arrow-up"/>
            </div>
        );
    }
}

export default ScrollTop